import React from 'react';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import { useHistory } from 'react-router-dom';

export default function KommissionColumn(props) {
	const history = useHistory();

	const onKommissionChange = (e) => {
		props.setSelectedKommission(e.target.value);
		props.datatableref.current.filter(e.target.value, "Kommission", "in");
	}

	return (
		<Column frozen alignFrozen="left" header="Kommission" field="Kommission" style={{width: "200px"}} filter sortable filterMatchMode="contains"
		filterElement={
		<MultiSelect value={props.selectedKommission} options={props.Kommission} optionLabel="Kommission" optionValue="Kommission"
		onChange={onKommissionChange} filter inputStyle={{width: '100%'}} style={{width: '100%'}} placeholder={"Alle"}/>
		}
		showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
		filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
		body = {props.body}
		/>
	);
}