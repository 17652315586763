import React, { useRef, useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GlobalState } from './GlobalState';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { AngebotService } from '../service/Angebotsservice';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { MitarbeiterService } from '../service/MitarbeiterService';
import { PopupMenu } from './PopupMenu';
import { MultiSelect } from 'primereact/multiselect';
import { KundenService } from '../service/KundenService';
import { AngebotTypService } from '../service/AngebotTypService';
import { InputText } from 'primereact/inputtext';
import AngeboteBildTemplate from './AngebotVerkäuferBild';
import { Calendar } from 'primereact/calendar';
import { flagTemplate } from './Laenderdropdown';
import KundennummerColumn from './Kunden/Kundennummer';

export default function Angebote(props) {
	const angebotService = new AngebotService();
	const mitarbeiterService = new MitarbeiterService();
	const kundenService = new KundenService();
	const angebotTypService = new AngebotTypService();
	const history = useHistory();
	const { Kundennummer } = useParams();
	const { Status } = useParams(); 

	const [globalState, setGlobalState] = useContext(GlobalState);
	const [angebote, setAngebote] = useState([]);
	const [einstellungenVisible, setEinstellungenVisble] = useState(false);
	const columns = [
		{field: "ID", visible: true },
		{field: "Status", visible: true },
		{field: "Verkäufer", visible: true },
		{field: "Kommission", visible: true },
		{field: "PLZ", visible: true},
		{field: "Ort", visible: true},
		{field: "Baufirma", visible: true},
		{field: "Telefon", visible: true},
		{field: "E-Mail", visible: true},
		{field: "letzter Kontakt", visible: true},
		{field: "VK Netto", visible: true},
		{field: "nächster Kontakt", visible: true},
		{field: "Gültigkeit", visible: true},
		{field: "Kundennummer", visible: true}
	]

	const [datumsbereichNächsterKontakt, setDatumsbereichNächsterKontakt] = useState();
	const [datumsbereichLetzterKontakt, setDatumsbereichLetzterKontakt] = useState();
	const [datumsbereichGültigkeit, setDatumsbereichGültigkeit] = useState();
	const [selectedKundennummer, setSelectedKundennummer] = useState([]);
	const [selectedColumns, setSelectedColumns] = useState(columns);
	const [derzeitigerBenutzer, setDerzeitigerBenutzer] = useState();
	const [verkäufer, setVerkäufer] = useState([]);
	const [selectedVerkäufer, setSelectedVerkäufer] = useState([]);
	const [kunden, setKunden] = useState([]);
	const [selectedKunden, setSelectedKunden] = useState([]);
	const [selectedAngebote, setSelectedAngebote] = useState([]);
	const [plzOrte, setPlzOrte] = useState([]);
	const [selectedPLZ, setSelectedPLZ] = useState([]); 
	const [selectedOrt, setSelectedOrt] = useState([]); 
	const [selectedTelefon, setSelectedTelefon] = useState([]); 
	const [selectedEmail, setSelectedEmail] = useState([]);
	const [selectedVKnetto, setSelectedVKnetto] = useState([]);
	const [selectedLetzterKontakt, setSelectedLetzterKontakt] = useState([]);
	const [selectedNächsterKontakt, setSelectedNächsterKontakt] = useState([]);
	const [selectedGültigkeit, setSelectedGültigkeit] = useState([]);

	const [baufirmen, setBaufirmen] = useState([]);
	const [selectedBaufirmen, setSelectedBaufirmen] = useState([]);
	const [angebotTypen, setAngebotTypen] = useState([]);
	const [selectedAngebotTypen, setSelectedAngebotTypen] = useState([]);
	
	const [Kommission, setKommission] = useState([]);
	const [Telefon, setTelefon] = useState([]);
	const [Email, setEmail] = useState([]);
	const [VKnetto, setVKnetto] = useState([]);
	const [PLZ, setPLZ] = useState([]); 
	const [Ort, setOrt] = useState([]); 
	const [_Kundennummer, setKundennummer] = useState([]);

	const [selectedStatus, setSelectedStatus] = useState([]);
	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [groupByVerkäufer, setGroupByVerkäufer] = useState(false);
	const [expandedRows, setExpandedRows] = useState([]);

	const dt = useRef(null);

	const statuse = [{Status: "Offen"},
					 {Status: "Angenommen"}, 
					 {Status: "Verkauft"}, 
					 {Status: "Abgelehnt"}, 
					 {Status: "Warteschlange"}]
	
	useEffect(() => {
		setGlobalState({...globalState, page: 'Angebote'});
		console.log(Kundennummer);
		let __Kundennummer = [];
		angebotService.getAll().then(data => {
			console.log(data);
			let _data = []; 
			let Kundennummer = [];
			
			data.forEach(element => {
				let PLZ;
				let Ort; 
				let PLZOrt = element.PlzOrt.split(/(\s+)/);
				PLZ = PLZOrt[0];
				element.PLZ = PLZ
				Ort = PLZOrt[2];
				element.Ort = Ort; 

				if (element.Kundennummer !== null){
					Kundennummer.push(element.Kundennummer);
				}

				_data.push(element); 
			})   

			Kundennummer.filter(onlyUnique).forEach(e => {
				__Kundennummer.push({Kundennummer: e})
			});
			console.log(__Kundennummer);
			setKundennummer(__Kundennummer);

			setAngebote(_data);

			let VKnetto = [];

			data.forEach(element => {
				 
				if (element.VKnetto !== null){
					VKnetto.push(element.VKnetto);
				}
			})

			let _VKnetto = []; 
			VKnetto.filter(onlyUnique).forEach(e => {
				_VKnetto.push({VKnetto: e});
			});
			setVKnetto(_VKnetto); 

		});
		angebotService.getPlzOrte().then(data => {
			let _PLZ = [];
			let _Ort = [];

			data.forEach(element => {
				let PLZ = element.split(/(\s+)/);
				_PLZ.push({PLZ: PLZ[0]});
				_Ort.push({Ort: PLZ[2]});  
			})
			setPLZ(_PLZ);
			setOrt(_Ort); 
			
			setPlzOrte(data);});
		angebotService.getBaufirmen().then(data => setBaufirmen(data));
		angebotTypService.getAll().then(data => setAngebotTypen(data));
		
		kundenService.getAll().then(data => {
			setKunden(data); 
			let Kommission = [];
			let Telefon = [];
			let Email = [];
			let Kundennummer = [];

			data.forEach(element => { 
				if (element.Kommission?.length > 0) {
					Kommission.push(element.Kommission);
				}
				if (element.ErstePersonMobil?.length > 0) {
					Telefon.push(element.ErstePersonMobil);
				}
				else if (element.ErstePersonTelefon?.length > 0) {
					Telefon.push(element.ErstePersonTelefon)
				}
				if (element.ErstePersonEmail?.length > 0){
					Email.push(element.ErstePersonEmail);
				}
			}); 
			

			let _Kommission = [];
			Kommission.filter(onlyUnique).forEach(e => {
				_Kommission.push({Kommission: e});
			});
			setKommission(_Kommission); 

			let _Telefon = [];
			Telefon.filter(onlyUnique).forEach(e => {
				_Telefon.push({Telefon: e});
			});
			setTelefon(_Telefon); 

			let _Email = [];
			Email.filter(onlyUnique).forEach(e => {
				_Email.push({Email: e});
			});
			setEmail(_Email); 
		}).then(() => {
			if (Kundennummer){
				let array = []
				array.push(Kundennummer);
				console.log(array);  
				setSelectedKundennummer(array); 
				dt.current.filter(Kundennummer, "Kundennummer", "equals");
			}
			if (Status){
				let array = []
				array.push(Status);
				setSelectedStatus(array); 
				dt.current.filter(array, "Status", "in"); 
				console.log(Status); 
			}
		});

		mitarbeiterService.getVerkaeufer().then(data => setVerkäufer(data));
		let _derzeitigerBenutzer;
		if (sessionStorage.mitarbeiter !== undefined && sessionStorage.mitarbeiter !== null) {
			_derzeitigerBenutzer = JSON.parse(sessionStorage.mitarbeiter);
			setDerzeitigerBenutzer(_derzeitigerBenutzer);
		}
		else
		mitarbeiterService.getMitarbeiterMitBenutzername(sessionStorage.benutername).then(data => {
			setDerzeitigerBenutzer(data);
			_derzeitigerBenutzer = data;
		});
		
		
	}, []);

	useEffect(() => {
		if (derzeitigerBenutzer === undefined || derzeitigerBenutzer === null)
			return;

		try {
			console.log(derzeitigerBenutzer);
			if (derzeitigerBenutzer?.AngebotsListEinstellungen.length === 0)
				setSelectedColumns(columns);
			let _selectedColumns = JSON.parse(derzeitigerBenutzer.AngebotsListEinstellungen);

			setSelectedColumns(_selectedColumns);
		} catch (e) {
			console.log(e);
		}
	}, [derzeitigerBenutzer])


	function onlyUnique(value, index, self){
		return self.indexOf(value) === index;
	}

	const createAngebot = () => history.push('/angebot/neu');

	const editAngebot = rowData => history.push("/angebot/bearbeiten/" + rowData.ID);

	const deleteAngebot = rowData => {
		angebotService.delete(rowData.ID).then(() => {
			angebotService.getAll().then(data => setAngebote(data));
		});
	};
	const onStatusChange = (e) => {
		setSelectedStatus(e.target.value);
		console.log(e.target.value); 
		dt.current.filter(e.target.value, "Status", "in");
	}

	const verkaufAngebot = rowData => {
		history.push("/verkauf/neu/" + rowData.ID);
	}

	const vknettoTemplate = (row) => {
		<React.Fragment>&euro;<span style={{textAlign: "right"}}>{row.VKnetto}</span></React.Fragment>
	}

	const columns1 = [
		{field: 'Was', textAlign: "left", header: 'Typ', filterElement:
			<MultiSelect className="column-filter" value={selectedAngebotTypen} options={angebotTypen}
					onChange={e => { setSelectedAngebotTypen(e.target.value); dt.current.filter(e.target.value, 'Was', 'in'); }}
					placeholder="Alle" maxSelectedLabels={3} optionLabel="Bezeichnung" optionValue="Bezeichnung" />,
					style: {width: "200px"}
				},
		{field: 'Bemerkung', header: 'Bemerkung', textAlign: "left", style: {width: "200px"}},
		{field: 'VKnetto', header: 'VK netto', textAlign: "right", 	style: {width: "200px"}}
	]

	const columnComponents =
		columns?.map(col=> {
			var result = selectedColumns.find(obj => {return obj.field === col.field});
			if (result === undefined || result === null)
				return;
			return <Column key={col.key} field={col.field} header={col.header} filter sortable filterMatchMode="contains"
				headerStyle={{textAlign: 'left'}} filterElement={col.filterElement}
				style={col.style}
				/>;
    	});

	function saveKundenEinstellungen() {
		try {
			let _mitarbeiter = derzeitigerBenutzer;
			_mitarbeiter.AngebotsListEinstellungen = JSON.stringify(selectedColumns, function(key, val) {
				if (key !== "filterElement")
					return val;
			});
			setDerzeitigerBenutzer(_mitarbeiter);
			sessionStorage.setItem("mitarbeiter", JSON.stringify(_mitarbeiter));
			mitarbeiterService.updateEinstellungen(_mitarbeiter);
		} catch (e) {
			console.log("Fehler beim Speichern der Einstellungen" + e);
		}
	}
	
	const onFilterResetClick = () => {
		let filters = dt.current.getFilters()
		for (const filter in filters){
			dt.current.filters = filters[filter].value = '';
		}
		setSelectedStatus([]);
		setSelectedKunden([]);
		setSelectedVerkäufer([]);
		setSelectedPLZ([]); 
		setPlzOrte([]);
		setSelectedOrt([]); 
		setSelectedBaufirmen([]);
		setSelectedTelefon([]); 
		setSelectedEmail([]);
		setSelectedLetzterKontakt([]);
		setSelectedVKnetto([]);
		setSelectedNächsterKontakt([]);
		setSelectedKundennummer([]);
		setSelectedGültigkeit([]);
		setDatumsbereichGültigkeit("");
		setDatumsbereichLetzterKontakt("");
		setDatumsbereichNächsterKontakt("");
		angebotService.getAll().then(data => {

			let _data = []; 
			data.forEach(element => {
				let PLZ;
				let Ort; 
				let PLZOrt = element.PlzOrt.split(/(\s+)/);
				PLZ = PLZOrt[0];
				element.PLZ = PLZ
				Ort = PLZOrt[2];
				element.Ort = Ort; 

				_data.push(element); 
			})   

			setAngebote(_data);	
		}); 
		dt.current.reset();
	}

	useEffect(() => {
		console.log(selectedStatus);
		dt.current.filter(selectedStatus, "Status", "in");
	}, [selectedStatus])

	useEffect(() => {
		console.log(selectedVerkäufer);
		dt.current.filter(selectedVerkäufer, "Verkäufer", "in");
	}, [selectedVerkäufer])

	const renderHeader = () => {
        return (
            <div className="flex justify-content-between align-items-center">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText autoFocus type="search" onInput={(e) => setGlobalFilterValue(e.target.value)} placeholder="Globale Suche" />
                </span>
                <span className='m-0' style={{float: "right"}}>
					<Button icon= "pi pi-filter-slash" label="Filter zurücksetzen" onClick={onFilterResetClick}></Button>
				</span>
            </div>
        )
    }

	const statusBodyTemplate = (rowData) => {
        return <span style={{borderRadius: "50%", backgroundColor: conditioncolor(rowData), alignItems: "center"}} 
		className={rowData.Status === "Offen" ? "pi pi-circle-off" : 
				   rowData.Status === "Angenommen" ? "pi pi-check-circle" : 
				   rowData.Status === "Abgelehnt" ? "pi pi-times-circle" : 
				   rowData.Status === "Warteschlange" ? "pi pi-history" : 
				   rowData.Status === "Verkauft" ? "pi pi-euro": ""}></span>;
    }

	const StatusFilterTemplate = (rowData) => {
		if(rowData){
			return(
				<div><span style={{borderRadius: "50%", backgroundColor: conditioncolor(rowData)}} 
				className={rowData.Status === "Offen" ? "pi pi-circle-off" : 
				rowData.Status === "Angenommen" ? "pi pi-check-circle" : 
				rowData.Status === "Abgelehnt" ? "pi pi-times-circle" : 
				rowData.Status === "Warteschlange" ? "pi pi-history" : 
				rowData.Status === "Verkauft" ? "pi pi-euro": ""}></span><span> {rowData.Status}</span></div>
			)
		}
		else{
			return
		}
	}

	const selectedStatusFilterTemplate = (rowData) => {
		if(rowData == "Abgelehnt"){
			return(
				<div style={{borderRadius: "50%", backgroundColor: "LightPink"}} className={"pi pi-times-circle"}></div>
			)
		}
		else if(rowData == "Angenommen"){
			return(
				<div style={{borderRadius: "50%", backgroundColor: "LightGreen"}} className={"pi pi-check-circle"}></div>
			)
		}
		else if(rowData == "Offen"){
			return(
				<div style={{borderRadius: "50%", backgroundColor: "Azure"}} className={"pi pi-circle-off"}></div>
			)
		}
		else if(rowData == "Verkauft"){
			return(
				<div className={"pi pi-euro"}></div>
			)
		}
		else if(rowData == "Warteschlange"){
			return(
				<div className={"pi pi-history"}></div>
			)
		}
		else if(selectedStatus.length > 1){
			
			return;
		}
		else{
			return <div>Alle</div>;
		}
		
	}

	const conditioncolor = (rowData) => {
		if(rowData.Status === "Offen"){
			return "Azure";
		} 
		else if(rowData.Status === "Angenommen"){
			return "LightGreen"; 
		}
		else if(rowData.Status === "Abgelehnt"){
			return "LightPink"; 
		}
		else{
			return; 
		}
	}

	const onDatumNächsterKontaktChange = (e) => {
		console.log(e.target); 
		setDatumsbereichNächsterKontakt(e.target.value);
		if(e.target.value !== undefined && e.target.value !== null && e.target.value.length === 2 && e.target.value[0] !== undefined && e.target.value[0] !== null && e.target.value[1] !== undefined && e.target.value[1] !== null && !isNaN(e.target.value[0]) && !isNaN(e.target.value[1])){
			let filteredAngebote = []; 
			angebote.forEach(element => { 
				let datumOhneZeit = new Date(element.nächsterKontakt);
				datumOhneZeit.setHours(0,0,0,0); 
				if((datumOhneZeit >= e.target.value[0] && datumOhneZeit <= e.target.value[1]) || datumOhneZeit === e.target.value[0] || datumOhneZeit === e.target.value[1]){
 
					filteredAngebote.push(element);
				}
			});
			setAngebote(filteredAngebote);  
		}
		else{
			angebotService.getAll().then(data => {
				setAngebote(data);	
			}); 
		}
	}

	const onDatumLetzterKontaktChange = (e) => {
		console.log(e.target); 
		setDatumsbereichLetzterKontakt(e.target.value);
		if(e.target.value !== undefined && e.target.value !== null && e.target.value.length === 2 && e.target.value[0] !== undefined && e.target.value[0] !== null && e.target.value[1] !== undefined && e.target.value[1] !== null && !isNaN(e.target.value[0]) && !isNaN(e.target.value[1])){
			let filteredAngebote = []; 
			angebote.forEach(element => { 
				let datumOhneZeit = new Date(element.letzterKontakt);
				datumOhneZeit.setHours(0,0,0,0); 
				if((datumOhneZeit >= e.target.value[0] && datumOhneZeit <= e.target.value[1]) || datumOhneZeit === e.target.value[0] || datumOhneZeit === e.target.value[1]){
 
					filteredAngebote.push(element);
				}
			});
			setAngebote(filteredAngebote);  
		}
		else{
			angebotService.getAll().then(data => {
				setAngebote(data);	
			}); 
		}
	}

	const onDatumGültigkeitChange = (e) => {
		console.log(e.target); 
		setDatumsbereichGültigkeit(e.target.value);
		if(e.target.value !== undefined && e.target.value !== null && e.target.value.length === 2 && e.target.value[0] !== undefined && e.target.value[0] !== null && e.target.value[1] !== undefined && e.target.value[1] !== null && !isNaN(e.target.value[0]) && !isNaN(e.target.value[1])){
			let filteredAngebote = []; 
			angebote.forEach(element => { 
				let datumOhneZeit = new Date(element.Gültigkeit);
				datumOhneZeit.setHours(0,0,0,0); 
				if((datumOhneZeit >= e.target.value[0] && datumOhneZeit <= e.target.value[1]) || datumOhneZeit === e.target.value[0] || datumOhneZeit === e.target.value[1]){
 
					filteredAngebote.push(element);
				}
			});
			setAngebote(filteredAngebote);  
		}
		else{
			angebotService.getAll().then(data => {
				setAngebote(data);	
			}); 
		}
	}


	const dateFilterNächsterKontakt = () => {
		return <Calendar value={datumsbereichNächsterKontakt} className="column-filter" selectionMode="range" onChange={onDatumNächsterKontaktChange} dateFormat="dd.mm.yy" showIcon monthNavigator={true} yearNavigator={true} yearRange={"1900:2050"}/>
	}

	const dateFilterLetzterKontakt = () => {
		return <Calendar value={datumsbereichLetzterKontakt} className="column-filter" selectionMode="range" onChange={onDatumLetzterKontaktChange} dateFormat="dd.mm.yy" showIcon monthNavigator={true} yearNavigator={true} yearRange={"1900:2050"}/>
	}

	const dateFilterGültigkeit = () => {
		return <Calendar value={datumsbereichGültigkeit} className="column-filter" selectionMode="range" onChange={onDatumGültigkeitChange} dateFormat="dd.mm.yy" showIcon monthNavigator={true} yearNavigator={true} yearRange={"1900:2050"}/>
	}

	const Kommissionbody = (row) => <a style={selectedAngebote === row ? {textDecoration: "underline", color: "white"} : {textDecoration: "underline", color: "blue"}} onClick={() => history.push("/angebot/bearbeiten/" + row.ID)}>{row.Kommission}</a>

	return (
		<React.Fragment>
			<div className="p-component grid">
				<div className="col-6">
					<Button label="Neues Angebot" icon="pi pi-plus-circle" onClick={createAngebot}/>
				</div>
				<div className="col-6" style={{textAlign: 'right'}}>
					<Button label="Einstellungen" icon="pi pi-cog" onClick={e => setEinstellungenVisble(true)}/>
				</div>
				<div className='col-12'>
					<Checkbox checked={groupByVerkäufer} onChange={(e) => setGroupByVerkäufer(e.checked)} /> Nach Verkäufer gruppieren
				</div>
				<div className="col-12">
					<DataTable header={renderHeader} value={angebote} className="p-datatable-striped"
					rowHover paginator rows={20} rowsPerPageOptions={[20,30,50,100]} scrollable scrollHeight="60vh" ref={dt}
					scrollDirection="both" currentPageReportTemplate="({first} - {last} von {totalRecords})"
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
					globalFilter={globalFilterValue} filterDisplay="row"
					sortField={"Verkäufer"}
					groupRowsBy="Verkäufer" expandableRowGroups={groupByVerkäufer} expandedRows={expandedRows}
					rowGroupHeaderTemplate={(rowData) => <AngeboteBildTemplate Verkäufer={[rowData.MitarbeiterNr, rowData.Verkäufer]} ></AngeboteBildTemplate>}
					onRowToggle={(e) => {console.log(angebote); setExpandedRows(e.data);}} rowGroupMode={(groupByVerkäufer) ? "subheader" : ""}
					selectionMode="single"  selection={selectedAngebote} onSelectionChange={e => { setSelectedAngebote(e.value)}}
					onRowClick={(e) => { setSelectedAngebote(e.data) }}
					>
					
						
						{selectedColumns.find((item) => item.field === "ID")?.visible === true &&
							<Column frozen alignFrozen="left" align="center" header="#" field="ID" style={{width: "40px"}} alignHeader="center"
								/>
						}

						{selectedColumns.find((item) => item.field === "Status")?.visible === true &&
							<Column frozen alignFrozen="left" align="center" header="Status" field="Status" style={{width: "120px"}} filter sortable filterMatchMode="contains"
							body={statusBodyTemplate}
							filterElement= {
								<MultiSelect className="column-filter" value={selectedStatus} filter options={statuse} 
								placeholder="Alle"
								optionLabel="Status" optionValue='Status'
								onChange={onStatusChange}
								itemTemplate={StatusFilterTemplate} selectedItemTemplate={selectedStatusFilterTemplate}
								/>}
								showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
								filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
								/>
						}

						{selectedColumns.find((item) => item.field === "Kommission")?.visible === true &&
						<Column header="Kommission" field="Kommission" style={{width: "200px"}} filter sortable filterMatchMode="contains"
						frozen alignFrozen='left'
							filterElement={
								<MultiSelect className="column-filter" value={selectedKunden} options={Kommission}
								onChange={e => { setSelectedKunden(e.target.value); dt.current.filter(e.target.value, 'Kommission', 'in'); }}
								placeholder="Alle" optionLabel="Kommission" optionValue="Kommission" filter/>
							}
							body={Kommissionbody}
							showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
							filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
							/>
						}
						{selectedColumns.find((item) => item.field === "Verkäufer").visible === true &&
							<Column header="Verkäufer" field="Verkäufer" style={{width: "200px"}} filter sortable filterMatchMode="contains"
							filterElement={
								<MultiSelect className="column-filter" value={selectedVerkäufer} filter options={verkäufer}
								onChange={e => { setSelectedVerkäufer(e.target.value); dt.current.filter(e.target.value, 'Verkäufer', 'in'); }}
								placeholder="Alle" optionLabel="Name" optionValue="Name"
								/>
							}
							body={(rowData) => <AngeboteBildTemplate Verkäufer={[rowData.MitarbeiterNr, rowData.Verkäufer]} ></AngeboteBildTemplate>}
							showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
							filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
							/>
						}

						{selectedColumns.find((item) => item.field === "PLZ")?.visible === true &&
						<Column header="PLZ" field="PLZ" style={{width: "200px"}} filter sortable filterMatchMode="contains"
							filterElement={
								<MultiSelect className="column-filter" value={selectedPLZ} options={PLZ}
								onChange={e => { setSelectedPLZ(e.target.value); dt.current.filter(e.target.value, 'PLZ', 'in'); }}
								placeholder="Alle" optionLabel="PLZ" optionValue="PLZ" filter/>
							}
							body={e => {
								return <React.Fragment>{flagTemplate(e.Land)}<div className="ml-2">{e.PLZ}</div></React.Fragment>
							}}
							showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
							filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
							/>
						}
						{selectedColumns.find((item) => item.field === "Ort")?.visible === true &&
						<Column header="Ort" field="Ort" style={{width: "200px"}} filter sortable filterMatchMode="contains"
							filterElement={
								<MultiSelect className="column-filter" value={selectedOrt} options={Ort}
								onChange={e => { setSelectedOrt(e.target.value); dt.current.filter(e.target.value, 'Ort', 'in'); }}
								placeholder="Alle" optionLabel="Ort" optionValue="Ort" filter/>
							}
							showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
							filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
							/>
						}
						{selectedColumns.find((item) => item.field === "Baufirma")?.visible === true &&
						<Column header="Baufirma" field="Baufirma" style={{width: "200px"}} filter sortable filterMatchMode="contains"
							filterElement={
								<MultiSelect className="column-filter" value={selectedBaufirmen} options={baufirmen}
									onChange={e => { setSelectedBaufirmen(e.target.value); dt.current.filter(e.target.value, 'Baufirma', 'in'); }}
									placeholder="Alle" filter/>
								}
							showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
							filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
							/>
						}

						{selectedColumns.find((item) => item.field === "Telefon")?.visible === true &&
						<Column header="Telefon" field="Telefon" style={{width: "200px"}} filter sortable filterMatchMode="contains"
							filterElement={
								<MultiSelect className="column-filter" value={selectedTelefon} options={Telefon} optionValue="Telefon" optionLabel='Telefon'
								onChange={e => { setSelectedTelefon(e.target.value); dt.current.filter(e.target.value, 'Telefon', 'in'); }}
								placeholder="Alle" filter/>
							}
							showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
							filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
							body={(row) => {{
								let tellcall = "tel:"+row.Telefon;
								return <a style={selectedAngebote === row ? {textDecoration: "underline", color: "white"} : {textDecoration: "underline", color: "blue"}} href={tellcall}>{row.Telefon}</a>}}}
							/>
						}

						{selectedColumns.find((item) => item.field === "E-Mail")?.visible === true &&
						<Column header="E-Mail" field="Email" style={{width: "200px"}} filter sortable filterMatchMode="contains"
							filterElement={
								<MultiSelect className="column-filter" value={selectedEmail} options={Email} optionValue="Email" optionLabel='Email'
								onChange={e => { setSelectedEmail(e.target.value); dt.current.filter(e.target.value, 'Email', 'in'); }}
								placeholder="Alle" filter/>
							}
							showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
							filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
							body={(row) => {{
								let href = "mailto:"+row.Email;
								return <a style={selectedAngebote === row ? {textDecoration: "underline", color: "white"} : {textDecoration: "underline", color: "blue"}} href={href}>{row.Email}</a>}}}
							/>
						}

						{selectedColumns.find((item) => item.field === "letzter Kontakt")?.visible === true &&
						<Column header="letzter Kontakt" field="letzterKontakt" style={{width: "200px"}} filter sortable filterField='date' dataType='date' filterMatchMode="contains"
							filterElement={dateFilterLetzterKontakt}
							showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
							filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
							body={(e) => {{
								if (e.letzterKontakt){
									let datum = new Date(e.letzterKontakt); 
									const year = datum.getFullYear();
									let month = datum.getMonth() + 1;
									if (month < 10){
										month = "0"+month;
									}
									let day = datum.getDate();
									if (day < 10){
										day = "0"+day;
									}
									const datestring = `${day}.${month}.${year}`
									return <a>{datestring}</a>
								}
								else{
									return;
								}
							}}}
							/>
						}
						{selectedColumns.find((item) => item.field === "VK Netto")?.visible === true &&
						<Column header="VK Netto" field="VKnetto" style={{width: "100px", marginRight: "20px", marginLeft: "20px"}} filter sortable filterMatchMode='contains' align="right"
							filterElement={
								<MultiSelect className="column-filter" value={selectedVKnetto} options={VKnetto} optionValue="VKnetto" optionLabel='VKnetto'
								onChange={e => { setSelectedVKnetto(e.target.value); dt.current.filter(e.target.value, 'VKnetto', 'in'); }}
								placeholder="Alle" filter/>
							}
							showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
							filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
							/>
						}
						{selectedColumns.find((item) => item.field === "nächster Kontakt")?.visible === true &&
						<Column header="nächster Kontakt" field="nächsterKontakt" style={{width: "200px"}} filter sortable filterMatchMode="contains"
							filterElement={dateFilterNächsterKontakt}
							showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
							filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
							body={(e) => {{
								if (e.nächsterKontakt){
									let datum = new Date(e.nächsterKontakt);
									const year = datum.getFullYear();
									let month = datum.getMonth() + 1;
									if (month < 10){
										month = "0"+month;
									}
									let day = datum.getDate();
									if (day < 10){
										day = "0"+day;
									}
									const datestring = `${day}.${month}.${year}`
									return <a>{datestring}</a>}
								else{
									return; 
								}
							}}}
							/>
						}
						{selectedColumns.find((item) => item.field === "Gültigkeit")?.visible === true &&
						<Column header="Gültigkeit" field="Gültigkeit" style={{width: "200px"}} filter sortable filterMatchMode="contains"
							filterElement={dateFilterGültigkeit}
							showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
							filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
							body={(e) => {{
								if(e.Gültigkeit){
									let datum = new Date(e.Gültigkeit);
									const year = datum.getFullYear();
									let month = datum.getMonth() + 1;
									if (month < 10){
										month = "0"+month;
									}
									let day = datum.getDate();
									if (day < 10){
										day = "0"+day;
									}
									const datestring = `${day}.${month}.${year}`
									return <a>{datestring}</a>
								}
								else{
									return;
								}
								
							}}}
							/>
						}
						{selectedColumns.find((item) => item.field === "Kundennummer")?.visible === true &&
							KundennummerColumn({
								selectedKundennummer: selectedKundennummer,
								Kundennummer: _Kundennummer,
								setSelectedKundennummer: setSelectedKundennummer,
								datatableref: dt
							})
						}
						<Column frozen alignFrozen='right' header="Aktionen" body={rowData => <PopupMenu buttonClassName="p-button-secondary" label="Aktionen" icon="pi pi-chevron-down" model={[
							{ label: 'Bearbeiten', icon: 'pi pi-pencil', command: () => editAngebot(rowData) },
							{ label: 'Löschen', icon: 'pi pi-trash', command: () => deleteAngebot(rowData) },
							{ label: 'Verkaufen', icon: 'pi pi-euro', command: () => verkaufAngebot(rowData) }
						]}/>} style={{width: '10em', textAlign: 'center'}}/>
					</DataTable>
				</div>
			</div>
			<Dialog header="Einstellungen" visible={einstellungenVisible} style={{ width: '15vw' }}
				footer={
					<div>
						<Button label="Schließen" icon="pi pi-check" onClick={e => {
							setEinstellungenVisble(false);
							saveKundenEinstellungen();
						}} />
					</div>
				}
				onHide={e => {
					setEinstellungenVisble(false);
					saveKundenEinstellungen();
				}}>
					<div className="grid">
						{columns?.map((col) => (
							<div className="col-12">
								<Checkbox key={col.field} value={col} checked={selectedColumns.find((item) => item.field === col.field)?.visible} className="mr-3"
								onChange={e => {
									let _columns = [...columns];
									_columns.forEach(x => {
										x.visible = selectedColumns.find((_x) => _x.field === x.field)?.visible
									});
									let column = _columns.find((item) => item.field === col.field);
									if (column !== undefined && column !== null)
										column.visible = e.checked;
									setSelectedColumns(_columns);
								}}/>
							{col.field}
							</div>
						))}
					</div>
			</Dialog>
		</React.Fragment>
	);
}