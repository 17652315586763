import React, { useRef, useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GlobalState } from './GlobalState';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { MitarbeiterService } from '../service/MitarbeiterService';
import { PopupMenu } from './PopupMenu';
import { MultiSelect } from 'primereact/multiselect';
import { KundenService } from '../service/KundenService';
import { VerkäufeService } from '../service/VerkäufeService';

import KommissionColumn from './Kunden/Kommission';
import VerkäuferColumn from './Kunden/Verkäufer';
import PLZColumn from './Kunden/PLZ';
import OrtColumn from './Kunden/Ort';
import BaufirmaColumn from './Kunden/Baufirma';
import TelefonColumn from './Kunden/Telefon';

export default function Verkäufe(props) {
    const verkäufeSerice = new VerkäufeService();
    const kundenService = new KundenService();
    const mitarbeiterService = new MitarbeiterService();
    const history = useHistory();
	const { Kundennummer } = useParams();
	const { id } = useParams();

	const [globalState, setGlobalState] = useContext(GlobalState);
    const [verkäufe, setVerkäufe] = useState([]);
    const [einstellungenVisible, setEinstellungenVisble] = useState(false);
	const [selectedColumns, setSelectedColumns] = useState([]);
    const [derzeitigerBenutzer, setDerzeitigerBenutzer] = useState();
    const [verkäufer, setVerkäufer] = useState([]);
	const [selectedVerkäufer, setSelectedVerkäufer] = useState([]);
	const [selectedKommission, setSelectedKommission] = useState([]);
	const [selectedPLZ, setSelectedPLZ] = useState([]);
	const [selectedOrt, setSelectedOrt] = useState([]);

    const [kunden, setKunden] = useState([]);
	const [selectedKunden, setSelectedKunden] = useState([]);
	const [plzOrte, setPlzOrte] = useState([]);
	const [PLZ, setPLZ] = useState([]); 
	const [Ort, setOrt] = useState([]);
	const [Kommission, setKommission] = useState([]);

	const [selectedPlzOrte, setSelectedPlzOrte] = useState([]);
	const [angebotTypen, setAngebottypen] = useState([]);
	const [selectedAngebotTypen, setSelectedAngebotTypen] = useState([]);
	
	const [selectedBaufirma, setSelectedBaufirma] = useState([]);
	const [selectedTelefon, setSelectedTelefon] = useState([]);
	const [selectedEmail, setSelectedEmail] = useState([]);
	const [selectedWas, setSelectedWas] = useState([]);
	const [selectedBemerkung, setSelectedBemerkung] = useState([]);
	const [selectedVKnetto, setSelectedVKnetto] = useState([]);
	const [selectedKundennummer, setSelectedKundennummer] = useState([]);
	const [Email, setEmail] = useState([]); 
	const [Baufirma, setBaufirma] = useState([]);
	const [Telefon, setTelefon] = useState([]);
	const [_Kundennummer, set_Kundennummer] = useState([]);

    const dt = useRef(null);
     
    useEffect(() => {
		setGlobalState({...globalState, page: 'Verkäufe'});
		verkäufeSerice.getAll().then(data => {
			
			let _data = []; 
			data.forEach(element => {
				let PLZ;
				let Ort; 
				let PLZOrt = element.PlzOrt.split(/(\s+)/);
				PLZ = PLZOrt[0];
				element.PLZ = PLZ
				Ort = PLZOrt[2];
				element.Ort = Ort; 
				_data.push(element); 
			})   
			console.log(_data);
			//setAngebote(_data); 
			
			setVerkäufe(data)
			console.log(data); 
			let Baufirma = [];
			let Email = [];  
			let Verkäufer = [];
			let Kommission = [];
			let durch = [];
			let Was = [];
			let Bemerkung = []; 
			let VKnetto = []; 
			let Telefon = []; 
			let Kundennummer = [];

			data.forEach(element => { 
				console.log(element);
				if (element.Baufirma?.length > 0){
					Baufirma.push(element.Baufirma);
				}
				if (element.Email?.length > 0){
					Email.push(element.Email);
				}
				if (element.Verkäufer?.length > 0){
					Verkäufer.push(element.Verkäufer); 
				}
				if (element.Kommission?.length > 0){
					Kommission.push(element.Kommission);
				}
				if (element.durch?.length > 0){
					durch.push(element.durch);
				}
				if (element.Was?.length > 0){
					Was.push(element.Was);
				}
				if (element.Bemerkung?.length > 0){
					Bemerkung.push(element.Bemerkung); 
				}
				if (element.VKnetto?.length > 0){
					VKnetto.push(element.VKnetto); 
				}
				if (element.Telefon?.length > 0){
					Telefon.push(element.Telefon);
				}
				if (element.Kundennummer !== null){
					Kundennummer.push(element.Kundennummer);
				}
			});

			let _Telefon = []; 
			Telefon.filter(onlyUnique).forEach(e => {
				_Telefon.push({Telefon: e});
			})
			setTelefon(_Telefon);
			
			let _Kommission = [];
			Kommission.filter(onlyUnique).forEach(e => {
				_Kommission.push({Kommission: e});
			});
			setKommission(_Kommission);

			let _Email = [];
			Email.filter(onlyUnique).forEach(e => {
				_Email.push({Email: e});
			});
			console.log(_Email); 
			setEmail(_Email); 

			let _Baufirma = []; 
			Baufirma.filter(onlyUnique).forEach(e => {
				_Baufirma.push({Baufirma: e});
			});
			setBaufirma(_Baufirma); 
			console.log(data); 

			let __Kundennummer = [];
			Kundennummer.filter(onlyUnique).forEach(e => {
				__Kundennummer.push(e);
			}) 
			set_Kundennummer(__Kundennummer); 
		
		});

		mitarbeiterService.getVerkaeufer().then(data => setVerkäufer(data));
		let _derzeitigerBenutzer;
		if (sessionStorage.mitarbeiter !== undefined && sessionStorage.mitarbeiter !== null) {
			_derzeitigerBenutzer = JSON.parse(sessionStorage.mitarbeiter);
			setDerzeitigerBenutzer(_derzeitigerBenutzer);
			onDerzeitigerBenutzerChange(_derzeitigerBenutzer);
		}
		else
			mitarbeiterService.getMitarbeiterMitBenutzername(sessionStorage.benutername).then(data => {
				setDerzeitigerBenutzer(data);
				onDerzeitigerBenutzerChange(_derzeitigerBenutzer);
			});
		setSelectedKundennummer(id);

		console.log(Kundennummer);
		if (Kundennummer){
			dt.current.filter(Kundennummer, "Kundennummer", "equals");
		}
	}, []);

	const onDerzeitigerBenutzerChange = (_derzeitigerBenutzer) => {
		if (_derzeitigerBenutzer === undefined || _derzeitigerBenutzer === null)
			return;

		try {
			let _selectedColumns = JSON.parse(_derzeitigerBenutzer.VerkäufeListEinstellungen);
			let _neu = new Array();
			columns.forEach(x => {
				x.visible = _selectedColumns.find((y) => y.field === x.field)?.visible;
				_neu.push(x);
			});
			setSelectedColumns(_neu);
			console.log(_neu);
		} catch (e) {
			console.log(e);
		}
	}

	const createVerkauf = () => history.push('/verkauf/neu');

	const editVerkauf = rowData => history.push("/verkauf/bearbeiten/" + rowData.Id);

	const deleteVerkauf = rowData => {
		verkäufeSerice.delete(rowData.Id).then(() => {
			verkäufeSerice.getAll().then(data => setVerkäufe(data));
		});
	};

	function onlyUnique(value, index, self){
		return self.indexOf(value) === index;
	}

	function saveVerkäufeEinstellungen() {
		try {
			let _mitarbeiter = derzeitigerBenutzer;
			_mitarbeiter.VerkäufeListEinstellungen = JSON.stringify(selectedColumns, function(key, val) {
				if (key !== "filterElement")
					return val;
			});
			setDerzeitigerBenutzer(_mitarbeiter);
			sessionStorage.setItem("mitarbeiter", JSON.stringify(_mitarbeiter));
			mitarbeiterService.updateEinstellungen(_mitarbeiter);
		} catch (e) {
			console.log(e);
		}
	}

	const columns = [
		{field: 'Verkäufer', visible: true},
        {field: 'Kommission', visible: true},
		{field: "PLZ", visible: true},
		{field: "Ort", visible: true}, 
		{field: 'Baufirma', visible: true},
		{field: "Telefon", visible: true},
		{field: "Email", visible: true},
		{field: "durch", visible: true},
		{field: "Was", visible: true},
		{field: "Bemerkung", visible: true},
		{field: "VKnetto", visible: true},
		{field: "Kundennummer", visible: true}
	]
    
	/*
    const columns = [,
        {field: 'Kommission', header: 'Kommission', filterElement:
			},
        {field: '', header: 'PLZ Ort', filterElement:
			},
		{field: '', header: 'Baufirma', filterElement:
			},
		{field: '', header: 'Telefon'},
		{field: '', header: 'E-Mail'},
		{field: '', header: 'Durch', filterElement:
			<MultiSelect className="column-filter" value={selectedDurch} options={verkäufer}
				onChange={e => { setSelectedDurch(e.target.value); dt.current.filter(e.target.value, 'durch', 'in'); }}
				placeholder="Alle" optionLabel="Name" optionValue="Name" maxSelectedLabels={3}/>},
		{field: 'Was', header: 'Was', filterElement:
			 },
		{field: 'Bemerkung', header: 'Bemerkung'},
		{field: 'VKnetto', header: 'VK netto'},
		{field: "Kundennummer", header: "Kundennummer"}
	]
    */
	const onFilterResetClick = () => {
		let filters = dt.current.getFilters()
		for (const filter in filters){
			dt.current.filters = filters[filter].value = '';
		}
		setSelectedVerkäufer([]);
		setSelectedKommission([]);
		setSelectedPLZ([]);
		setSelectedOrt([]);
		setSelectedBaufirma([]);
		setSelectedTelefon([]);
		setSelectedEmail([]);
		setSelectedAngebotTypen([]);
		setSelectedWas([]);
		setSelectedBemerkung([]);
		setSelectedVKnetto([]);
		setSelectedKundennummer([]);
		dt.current.reset();
	}

	const renderHeader = () => {
        return (
            <div>
				<span>
					<Button icon= "pi pi-filter-slash" label="Filter zurücksetzen" onClick={onFilterResetClick}></Button>
				</span>
            </div>
        )
    }

    return (
		<React.Fragment>
			<div className='grid'>
				<div className="col-6">
					<Button label="Neuer Verkauf" icon="pi pi-plus-circle" onClick={createVerkauf}/>
				</div>
				<div className="col-6" style={{textAlign: 'right'}}>
					<Button label="Einstellungen" icon="pi pi-cog" onClick={e => setEinstellungenVisble(true)}/>
				</div>
			</div>
            <div className="p-component grid">
                <div className="col-12">
					<DataTable value={verkäufe} header={renderHeader} className="p-datatable-striped" rowHover paginator rows={20} rowsPerPageOptions={[20,30,40]} scrollable scrollHeight="60vh" ref={dt} filterDisplay="row" scrollDirection="both" currentPageReportTemplate="({first} - {last} von {totalRecords})" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown">
						{selectedColumns.find((item) => item.field === "Kommission")?.visible === true &&
							KommissionColumn({
								selectedKommission: selectedKommission,
								Kommission: Kommission,
								setSelectedKommission: setSelectedKommission,
								datatableref: dt,
							})
						}
						{selectedColumns.find((item) => item.field === "Verkäufer")?.visible === true &&
							VerkäuferColumn({
								selectedVerkäufer: selectedVerkäufer,
								Verkäufer: verkäufer,
								setSelectedVerkäufer: setSelectedVerkäufer,
								datatableref: dt
							}) 
						}
						{selectedColumns.find((item) => item.field === "PLZ")?.visible === true &&
							PLZColumn({
								selectedPLZ: selectedPLZ,
								PLZ: PLZ,
								setSelectedPLZ: setSelectedPLZ,
								datatableref: dt
							})
						}
						{selectedColumns.find((item) => item.field === "Ort")?.visible === true &&
							OrtColumn({
								selectedOrt: selectedOrt,
								Ort: Ort,
								setSelectedOrt: setSelectedOrt,
								datatableref: dt
							})
						}
						{selectedColumns.find((item) => item.field === "Baufirma")?.visible === true &&
							BaufirmaColumn({
								selectedBaufirma: selectedBaufirma,
								Baufirma: Baufirma,
								setSelectedBaufirma: setSelectedBaufirma,
								datatableref: dt	
							})
						}
						{selectedColumns.find((item) => item.field === "Telefon")?.visible === true &&
							TelefonColumn({
								selectedTelefon: selectedTelefon,
								Telefon: Telefon,
								setSelectedTelefon: setSelectedTelefon,
								datatableref: dt
							})
						}
						{selectedColumns.find((item) => item.field === "Email")?.visible === true &&
						<Column header="Email" field="Email" style={{width: "200px"}} filter sortable filterMatchMode="contains"
							filterElement={
								<MultiSelect className="column-filter" value={selectedEmail} options={Email} optionLabel="Email" optionValue='Email'
									onChange={e => { setSelectedEmail(e.target.value); dt.current.filter(e.target.value, 'Email', 'in'); }}
									placeholder="Alle" filter/>
								}
								showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
								filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
						/>
						}
						{selectedColumns.find((item) => item.field === "durch")?.visible === true &&
						<Column header="durch" field="durch" style={{width: "200px"}} filter sortable filterMatchMode="contains"
						filterElement={
							<MultiSelect className="column-filter" value={selectedAngebotTypen} options={angebotTypen}
								onChange={e => { setSelectedAngebotTypen(e.target.value); dt.current.filter(e.target.value, 'durch', 'in'); }}
								placeholder="Alle" optionLabel="Bezeichnung" optionValue="Bezeichnung" filter/>
							}
							showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
							filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
							/>
						}
						{selectedColumns.find((item) => item.field === "Was")?.visible === true &&
						<Column header="Was" field="Was" style={{width: "200px"}} filter sortable filterMatchMode="contains"
							filterElement={
								<MultiSelect className="column-filter" value={selectedWas} options={plzOrte}
									onChange={e => { setSelectedWas(e.target.value); dt.current.filter(e.target.value, 'Was', 'in'); }}
									placeholder="Alle" filter/>
								}
								showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
								filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
						/>
						}
						{selectedColumns.find((item) => item.field === "Bemerkung")?.visible === true &&
							<Column header="Bemerkung" field="Bemerkung" style={{width: "200px"}} filter sortable filterMatchMode="contains"
								filterElement={
									<MultiSelect className="column-filter" value={selectedBemerkung} options={plzOrte}
										onChange={e => { setSelectedBemerkung(e.target.value); dt.current.filter(e.target.value, 'Bemerkung', 'in'); }}
										placeholder="Alle" filter/>
									}
									showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
									filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
							/>
						}
						{selectedColumns.find((item) => item.field === "VKnetto")?.visible === true &&
							<Column header="VKnetto" field="VKnetto" style={{width: "200px"}} filter sortable filterMatchMode="contains"
							filterElement={
								<MultiSelect className="column-filter" value={selectedVKnetto} options={plzOrte}
									onChange={e => { setSelectedVKnetto(e.target.value); dt.current.filter(e.target.value, 'VKnetto', 'in'); }}
									placeholder="Alle" filter/>
								}
								showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
								filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
							/>
						}
						{selectedColumns.find((item) => item.field === "Kundennummer")?.visible === true &&
							<Column header="Kundennummer" field="Kundennummer" style={{width: "200px"}} filter sortable filterMatchMode="contains"
							filterElement={
								<MultiSelect className="column-filter" value={selectedKundennummer} options={_Kundennummer}
									onChange={e => { setSelectedKundennummer(e.target.value); dt.current.filter(e.target.value, 'Kundennummer', 'in'); }}
									placeholder="Alle" filter/>
								}
								showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
								filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
							/>
						}
						<Column frozen alignFrozen="right" header="Aktionen" body={rowData => <PopupMenu buttonClassName="p-button-secondary" label="Aktionen" icon="pi pi-chevron-down" model={[
							{ label: 'Bearbeiten', icon: 'pi pi-pencil', command: () => editVerkauf(rowData) },
							{ label: 'Löschen', icon: 'pi pi-trash', command: () => deleteVerkauf(rowData) }
						]}/>} style={{width: '10em', textAlign: 'center'}}/>
					</DataTable>
				</div>
            </div>
			<Dialog header="Einstellungen" visible={einstellungenVisible} style={{ width: '15vw' }}
				footer={
					<div>
						<Button label="Schließen" icon="pi pi-check" onClick={e => {
							setEinstellungenVisble(false);
							saveVerkäufeEinstellungen();
						}} />
					</div>
				}
				onHide={e => {
					setEinstellungenVisble(false);
					saveVerkäufeEinstellungen();
				}}>
				<div className="grid">
					{columns?.map((col) => (
						<div className="col-12">
							<Checkbox key={col.field} value={col} checked={selectedColumns.find((item) => item.field === col.field)?.visible} className="mr-3"
							onChange={e => {
								let _columns = [...columns];
								_columns.forEach(x => {
									x.visible = selectedColumns.find((_x) => _x.field === x.field)?.visible
								});
								let column = _columns.find((item) => item.field === col.field);
								if (column !== undefined && column !== null)
									column.visible = e.checked;
								setSelectedColumns(_columns);
							}}/>
						{col.field}
						</div>
					))}
				</div>
			</Dialog>
        </React.Fragment>
    );
}