import { GetToken } from './AuthService';

export class ImportService {
	async upload(file, name, url) {
		if(file === undefined || file === null) return;
		const formData = new FormData();
		formData.append(name, file);
		return fetch(url, {
			method: 'POST',
			body: formData,
			headers: {
				'Authorization': 'Bearer ' + (await GetToken())
			}
		});
	}

	async uploadFiles(files, url) {
		if(files === undefined || files === null) return;
		const formData = new FormData();
		files.forEach( file => 
			formData.append(file.name, file, file.name)
		);
		console.log(files);
		return fetch(url, {
			method: 'POST',
			body: formData,
			headers: {
				'Authorization': 'Bearer ' + (await GetToken())
			}
		});
	}
}