import React, { useRef, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { GlobalState } from './GlobalState';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { PopupMenu } from './PopupMenu';
import { KundenService } from '../service/KundenService';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { MitarbeiterService } from '../service/MitarbeiterService';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { Laender, countryOptionTemplate } from './Laenderdropdown';
import { flagTemplate } from './Laenderdropdown';
import VerkäuferTemplate from './Verkäufer';
import KommissionColumn from './Kunden/Kommission';
import AdresseColumn from './Kunden/Adresse';
import LandColumn from './Kunden/Land';
import PLZColumn from './Kunden/PLZ';
import OrtColumn from './Kunden/Ort';
import EmailColumn from './Kunden/Email';
import MobilColumn from './Kunden/Mobil';
import KundentypColumn from './Kunden/Kundentyp';
import VerkäuferColumn from './Kunden/Verkäufer';
import TelefonColumn from './Kunden/Telefon';
import FaxDWColumn from './Kunden/FaxDW';
import VornameColumn from './Kunden/Vorname';
import NachnameColumn from './Kunden/Nachname';
import FirmenbezeichnungColumn from './Kunden/Firmenbezeichnung';
import KundennummerColumn from './Kunden/Kundennummer';
import { AngebotskundenService } from '../service/AngebotskundenService';

export default function Kunden(props) {
	const kundenService = new KundenService();
	const angebotskundenService = new AngebotskundenService();
	const mitarbeiterService = new MitarbeiterService();
	const history = useHistory();

	const [globalState, setGlobalState] = useContext(GlobalState);
	const [kunden, setKunden] = useState([]);

	const columns = [
		{field: "Kommission", visible: true },
		{field: "Adresse", visible: true },
		{field: "Land", visible: true},
		{field: "PLZ", visible: true },
		{field: "Ort", visible: true },
		{field: "E-Mail", visible: true},
		{field: "Telefon", visible: true},
		{field: "Mobil", visible: true},
		{field: "Kundentyp", visible: true},
		{field: "Verkäufer", visible: true},
		{field: "Fax-DW", visible: true},
		{field: "Vorname", visible: true},
		{field: "Nachname", visible: true},
		{field: "Firmenname", visible: true},
		{field: "Kundennummer", visible: true},
		{field: "offeneAngebote", visible: true},
		{field: "VKGesamt", visible: true}
	]

	const [selectedColumns, setSelectedColumns] = useState(columns);


	const [einstellungenVisible, setEinstellungenVisble] = useState(false);
	const [derzeitigerBenutzer, setDerzeitigerBenutzer] = useState();
	const [KundenstammdatenExportVisible, setKundenstammdatenExportVisible] = useState(false);

	const [selectedAdresse, setSelectedAdresse] = useState([]);
	const [selectedPLZ, setSelectedPLZ] = useState([]);
	const [selectedKommission, setSelectedKommission] = useState([]);
	const [selectedOrt, setSelectedOrt] = useState([]);
	const [selectedMail, setSelectedMail] = useState([]);
	const [selectedMobil, setSelectedMobil] = useState([]);
	const [selectedVerkäufer, setSelectedVerkäufer] = useState([]);
	const [selectedKundenTyp, setSelectedKundenTyp] = useState([]);
	const [selectedTelefon, setSelectedTelefon] = useState([]);
	const [selectedBlacklistStatus, setSelectedBlacklistStatus] = useState([]);
	const [selectedLand, setSelectedLand] = useState([]);
	const [selectedFaxDW, setSelectedFaxDW] = useState([]); 
	const [selectedVorname, setSelectedVorname] = useState([]);
	const [selectedNachname, setSelectedNachname] = useState([]);
	const [selectedFirmenname, setSelectedFirmenname] = useState([]);
	const [selectedKundennummer, setSelectedKundennummer] = useState([]); 

	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [selectedKunden, setSelectedKunden] = useState([]);
	const [expandedRows, setExpandedRows] = useState([]);
	const [groupByVerkäufer, setGroupByVerkäufer] = useState(false);

	const [Adressen, setAdressen] = useState([]); 
	const [Kommission, setKommission] = useState([]); 
	const [PLZ, setPLZ] = useState([]);
	const [Ort, setOrt] = useState([]);
	const [Mail, setMail] = useState([]); 
	const [Mobil, setMobil] = useState([]);
	const [Verkäufer, setVerkäufer] = useState([]);
	const [Telefon, setTelefon] = useState([]);
	const [FaxDW, setFaxDW] = useState([]);
	const [Firmenbezeichnung, setFirmenbezeichnung] = useState([]);
	const [Vorname, setVorname] = useState([]);
	const [Nachname, setNachname] = useState([]);
	const [Kundennummer, setKundennummer] = useState([]);

	const datatableref = useRef(null);

	const statuse = [{BlacklistStatus: "Blacklist"},
					 {BlacklistStatus: "Whitelist"},
					 {BlacklistStatus: "Neutral"}];

	useEffect(() => {
		console.log(props.art);
		if(props.art === "Stammkunden"){
			setGlobalState({...globalState, page: 'Kunden'});
			kundenService.getAll().then(onKundeLoad);
		}
		else if (props.art === "Angebotskunden"){
			setGlobalState({...globalState, page: 'Angebotskunden'});
			angebotskundenService.getAll().then(onKundeLoad);
		}
		let _derzeitigerBenutzer;
		if (sessionStorage.mitarbeiter !== undefined && sessionStorage.mitarbeiter !== null) {
			_derzeitigerBenutzer = JSON.parse(sessionStorage.mitarbeiter);
			setDerzeitigerBenutzer(_derzeitigerBenutzer);
		}
		else
			mitarbeiterService.getMitarbeiterMitBenutzername(sessionStorage.benutername).then(data => {
				setDerzeitigerBenutzer(data);
				_derzeitigerBenutzer = data;
			});
	}, [props.art]);

	useEffect(() => {
		if (derzeitigerBenutzer === undefined || derzeitigerBenutzer === null)
			return;

		try {
			let _selectedColumnsSaved = JSON.parse(derzeitigerBenutzer.KundenEinstellungen);
			let _newSelctedColumns = new Array();
			columns.forEach( e => {
				let x = _selectedColumnsSaved.find(y => y.field === e.field);
				if (x !== undefined)
					_newSelctedColumns.push(x);
				else
					_newSelctedColumns.push(e);
			});
			setSelectedColumns(_newSelctedColumns);
		} catch (e) {
			console.log(e);
		}
	}, [derzeitigerBenutzer])

	const showVerkäufe = rowData => {console.log(rowData); history.push("/verkäufe/" + rowData.Kundennummer);}

	const showAngebote = rowData => {console.log(rowData); history.push("/angebote/" + rowData.Kundennummer);}

	const showAngebotsKundenAngebote = rowData => {history.push("/angebote/A" + rowData.Kundennummer);}

	const createKunde = () => history.push('/kunde/neu');

	const createFAKunde = () => history.push('/kunde/FANeu');

	const editKunde = rowData => history.push("/kunde/bearbeiten/" + rowData.Kundennummer);

	const editAngebotsKunde = rowData => history.push("/angebotkunde/bearbeiten/" + rowData.Kundennummer);

	const deactivateKunden = rowData => {
		kundenService.deactivate(rowData.Kundennummer).then(() => {
			kundenService.getAll().then(data => setKunden(data));
		});
	};

	const onBlacklistStatusChange = (e) => {
		setSelectedBlacklistStatus(e.target.value);
		datatableref.current.filter(e.target.value, "BlacklistStatus", "in")
	}

	function onKundeLoad(data) {
		setKunden(data);
		let adressen = [];
		let PLZ = []; 
		let Kommission = [];
		let Ort = [];
		let Mail = [];
		let Mobil = []; 
		let Verkäufer = []; 
		let Telefon = [];
		let FaxDW = [];
		let Vorname = []; 
		let Nachname = [];
		let Firmenname = []; 
		let Kundennummer = []; 

		data.forEach(element => {
			adressen.push(element.ReAdresse);
			PLZ.push(element.RePlz);
			Kundennummer.push(element.Kundennummer); 
			if (element.ErstePersonVorname?.length > 0){
				Vorname.push(element.ErstePersonVorname); 
			} 
			if (element.ErstePersonNachname?.length > 0){
				Nachname.push(element.ErstePersonNachname); 
			} 
			if (element.Firmenbezeichnung?.length > 0){
				Firmenname.push(element.Firmenbezeichnung); 
			} 
			if (element.ErstePersonFax?.length > 0){
				FaxDW.push(element.ErstePersonFax); 
			} 
			if (element.Kommission?.length > 0) {
				Kommission.push(element.Kommission);
			}
			if (element.ReOrt?.length > 0) {
				Ort.push(element.ReOrt);
			}
			if (element.ErstePersonEmail?.length > 0) {
				Mail.push(element.ErstePersonEmail);
			}
			if (element.ErstePersonMobil?.length > 0) {
				Mobil.push(element.ErstePersonMobil);
			}
			if (element.Verkäufer != null) {
				Verkäufer.push(element.Verkäufer); 
			}
			if (element.ErstePersonTelefon?.length > 0){
				Telefon.push(element.ErstePersonTelefon);
			}
			
		});

		let _Vorname = [];
		Vorname.filter(onlyUnique).forEach(e => {
			_Vorname.push({Vorname: e});
		})
		setVorname(_Vorname); 

		let _Nachname = []; 
		Nachname.filter(onlyUnique).forEach(e => {
			_Nachname.push({Nachname: e});
		})
		setNachname(_Nachname); 

		let _Firmenbezeichnung = []; 
		Firmenname.filter(onlyUnique).forEach(e => {
			_Firmenbezeichnung.push({Firmenname: e});
		})
		setFirmenbezeichnung(_Firmenbezeichnung); 

		let _Kundennummer = [];
		Kundennummer.filter(onlyUnique).forEach(e => {
			_Kundennummer.push({Kundennummer: e}); 
		})
		setKundennummer(_Kundennummer); 

		let _FaxDW = [];
		FaxDW.filter(onlyUnique).forEach(e => {
			_FaxDW.push({FaxDW: e});
		});
		setFaxDW(_FaxDW); 

		let _Telefon = [];
		Telefon.filter(onlyUnique).forEach(e => {
			_Telefon.push({Telefon: e});
		}); 
		setTelefon(_Telefon); 

		let _Verkäufer = []; 
		Verkäufer.forEach(e => {
			if (_Verkäufer.find(x => x.Name === e.Name) === undefined)
				_Verkäufer.push(e);
		});
		setVerkäufer(_Verkäufer);

		let _Mobil = [];
		Mobil.filter(onlyUnique).forEach(e => {
			_Mobil.push({ErstePersonMobil: e});
		});
		setMobil(_Mobil);

		let _Mail = [];
		Mail.filter(onlyUnique).forEach(e => {
			_Mail.push({ErstePersonEmail: e});
		});
		setMail(_Mail);

		let _Kommission = [];
		Kommission.filter(onlyUnique).forEach(e => {
			_Kommission.push({Kommission: e});
		});
		setKommission(_Kommission);

		let _adressen = [];
		adressen.filter(onlyUnique).forEach(e => {
			_adressen.push({ReAdresse: e});
		});
		setAdressen(_adressen);

		let _PLZ = [];
		PLZ.filter(onlyUnique).forEach(e => {
			_PLZ.push({RePlz: e});
		});
		setPLZ(_PLZ);

		let _Ort = [];
		Ort.filter(onlyUnique).forEach(e => {
			_Ort.push({ReOrt: e});
		});
		setOrt(_Ort);
	}

	function saveKundenEinstellungen() {
		try {
			let _mitarbeiter = derzeitigerBenutzer;
			_mitarbeiter.KundenEinstellungen = JSON.stringify(selectedColumns, function(key, val) {
				if (key !== "filterElement")
					return val;
			});
			sessionStorage.setItem("mitarbeiter", JSON.stringify(_mitarbeiter));
			mitarbeiterService.updateEinstellungen({...derzeitigerBenutzer, KundenEinstellungen: _mitarbeiter.KundenEinstellungen});
		} catch (e) {
			console.log(e);
		}
	}

	function onlyUnique(value, index, self){
		return self.indexOf(value) === index;
	}

	const onEmailSend = (rowData) => {
		window.location.href = "mailto:"+rowData.ErstePersonEmail;
	}

	const exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(kunden);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'kunden');
        });
    }

	const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }
	
	const statusBodyTemplate = (rowData) => {
        return <span style={{borderRadius: "50%", backgroundColor: conditioncolor(rowData)}} className={rowData.BlacklistStatus === "Blacklist" ? "pi pi-times-circle" : 
								rowData.BlacklistStatus === "Whitelist" ? "pi pi-check-circle" : "pi pi-circle-off"}></span>;
    }

	const openKundenExplorer = () => {
		window.open("file:///C:\mitarbeiterverwaltung\mitarbeiterverwaltung");
	}

	const conditioncolor = (rowData) => {
		if(rowData.BlacklistStatus === "Blacklist"){
			return "LightPink"; 
		}
		
		else if (rowData.BlacklistStatus === "Whitelist"){
			return "LightGreen";
		}
		
		else{
			return "Azure";
		}
	}

	const BlacklistFilterTemplate = (rowData) => {
		return(
			<div ><span style={{borderRadius: "50%", backgroundColor: conditioncolor(rowData)}} className={rowData.BlacklistStatus === "Blacklist" ? "pi pi-times-circle" : 
			rowData.BlacklistStatus === "Whitelist" ? "pi pi-check-circle" : "pi pi-circle-off"}></span><span> {rowData.BlacklistStatus}</span></div>
		)
	}

	const selectedBlacklistFilterTemplate = (rowData) => {
		if(rowData === undefined){
			return; 
		} 
		if(rowData == "Blacklist"){
			return(
				<div style={{borderRadius: "50%", backgroundColor: "LightPink"}} className={"pi pi-times-circle"}></div>
			)
		}
		if(rowData == "Whitelist"){
			return(
				<div style={{borderRadius: "50%", backgroundColor: "LightGreen"}} className={"pi pi-check-circle"}></div>
			)
		}
		if(rowData == "Neutral"){
			return(
				<div style={{borderRadius: "50%", backgroundColor: "Azure"}} className={"pi pi-circle-off"}></div>
			)
		}
	}

	const renderHeader = () => {
        return (
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText autoFocus type="search" onInput={(e) => setGlobalFilterValue(e.target.value)} placeholder="Globale Suche" />
                </span>
				<span className='m-0' style={{float: "right"}}>
					<Button icon= "pi pi-filter-slash" label="Filter zurücksetzen" onClick={onFilterResetClick}></Button>
				</span>
            </div>
        )
    }

	const onFilterResetClick = () => {
		let filters = datatableref.current.getFilters()
		for (const filter in filters){
			datatableref.current.filters = filters[filter].value = '';
		}
		setSelectedAdresse([]);
		setSelectedPLZ([]);
		setSelectedKommission([]);
		setSelectedOrt([]);
		setSelectedMail([]);
		setSelectedMobil([]);
		setSelectedVerkäufer([]);
		setSelectedKundenTyp ([]);
		setSelectedTelefon([]);
		setSelectedBlacklistStatus([]);
		setSelectedLand([]);
		setSelectedFaxDW([]); 
		setSelectedVorname([]);
		setSelectedNachname([]);
		setSelectedFirmenname([]);
		setSelectedKundennummer([]);
		setSelectedKunden([]);
		datatableref.current.reset();
	}

	const Kommissionbody = (row) => <a style={selectedKunden === row ? {textDecoration: "underline", color: "white"} : {textDecoration: "underline", color: "blue"}} onClick={() => history.push("/Kunde/bearbeiten/" + row.Kundennummer)}>{row.Kommission}</a>

	const Emailbody = (row) => {
		let _mail = "mailto:" + row.ErstePersonEmail;
		return <a style={selectedKunden === row ? {textDecoration: "underline", color: "white"} : {textDecoration: "underline", color: "blue"}} href={_mail}>{row.ErstePersonEmail}</a>
	}

	const Mobilbody = (row) => {{ 
		let tellcall = "tel:"+row.ErstePersonMobil;
		return <a style={selectedKunden === row ? {textDecoration: "underline", color: "white"} : {textDecoration: "underline", color: "blue"}} href={tellcall}>{row.ErstePersonMobil}</a>}}

	const Telefonbody = (row) => {
		let tellcall = "tel:"+row.ErstePersonTelefon; 
		return <a style={selectedKunden === row ? {textDecoration: "underline", color: "white"} : {textDecoration: "underline", color: "blue"}} href={tellcall}>{row.ErstePersonTelefon}</a>}

	return (
		<React.Fragment>
			<div className="p-component grid">
				<div className="col-6">
					{props.art === "Stammkunden" && <div>
						<Button className='mr-3' label="Neuer priv. Kunde" icon="pi pi-plus-circle" onClick={createKunde}/>
						<Button label="Neuer Fa. Kunde" icon="pi pi-plus-circle" onClick={createFAKunde}/>
					</div>}
				</div>

				<div className="col-6" style={{textAlign: 'right'}}>
					<Button label="Kundenstammdaten exportieren" icon="pi pi-download" style={{marginRight:"10px"}} onClick={e => setKundenstammdatenExportVisible(true)}/>
					<Button label="Einstellungen" icon="pi pi-cog" onClick={e => setEinstellungenVisble(true)}/>
				</div>
				<div className='col-12'>
					<Checkbox checked={groupByVerkäufer} onChange={(e) => setGroupByVerkäufer(e.checked)} /> Nach Verkäufer gruppieren
				</div>
				<div className="col-12">
					<DataTable ref={datatableref} responsive value={kunden}
						header={renderHeader}
						filterDisplay="row"
						className="p-datatable-striped tableCursorPointer"
						currentPageReportTemplate="({first} - {last} von {totalRecords})"
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
						rowHover paginator rows={20} rowsPerPageOptions={[20,30,50,100]} scrollable="true" scrollHeight="650px" scrollDirection="both"
						globalFilter={globalFilterValue} selectionMode="single"  selection={selectedKunden} onSelectionChange={e => { setSelectedKunden(e.value)}}
						onRowClick={(e) => { setSelectedKunden(e.data) }}
						globalFilterFields={["global", "Kommission", "ReAdresse", "RePlz", "ReOrt", "ErstePersonEmail", "ErstePersonMobil", "KundenArt", "Verkäufer.Name", "ErstePersonTelefon", "ErstePersonFax", "ErstePersonVorname", "ErstePersonNachname", "Firmenbezeichnung", "Kundennumer"]}
						groupRowsBy="Verkäufer.Name" expandableRowGroups={groupByVerkäufer} expandedRows={expandedRows}
						sortOrder={1} sortMode="single" sortField={(groupByVerkäufer) ? "Verkäufer.Name" : "Kundennummer"}
						rowGroupHeaderTemplate={(rowData) => <VerkäuferTemplate Verkäufer={rowData.Verkäufer} ></VerkäuferTemplate>}
						onRowToggle={(e) => setExpandedRows(e.data)} rowGroupMode={(groupByVerkäufer) ? "subheader" : ""}
						dataKey="Kundennummer"
						>
						<Column frozen alignFrozen="left" align="center" field="BlacklistStatus" header="Status" style={{width: "110px"}} body={statusBodyTemplate} filter sortable
						filterMatchMode='contains'
						bodyClassName='align-content-center align-items-center text-center'
						filterElement={
							<MultiSelect value={selectedBlacklistStatus} onChange={onBlacklistStatusChange}
								style={{width: "80px"}} header="Status" options={statuse}
								optionLabel="BlacklistStatus" optionValue="BlacklistStatus"
								itemTemplate={BlacklistFilterTemplate} selectedItemTemplate={selectedBlacklistFilterTemplate} placeholder={"Alle"} filter/>
						}
						showFilterMatchModes={false} showFilterMenuOptions={false} showFilterOperator={false}
						filterClear={<div></div>} filterApply={<div></div>}  showFilterMenu={false}
						/>

						{selectedColumns.find((item) => item.field === "Kommission").visible === true &&
							KommissionColumn({
								selectedKommission: selectedKommission,
								Kommission: Kommission,
								setSelectedKommission: setSelectedKommission,
								datatableref: datatableref,
								body: Kommissionbody
							})
						}

						{selectedColumns.find((item) => item.field === "Adresse").visible === true &&
							AdresseColumn({
								selectedAdresse: selectedAdresse, 
								Adressen: Adressen, 
								setSelectedAdresse:setSelectedAdresse, 
								datatableref:datatableref
							})
						}
						
						{selectedColumns.find((item) => item.field === "Land")?.visible === true &&
							LandColumn({
								selectedLand: selectedLand, 
								Laender: Laender,
								setSelectedLand: setSelectedLand,
								datatableref: datatableref})
						}

						{selectedColumns.find((item) => item.field === "PLZ")?.visible === true &&
							PLZColumn({
								selectedPLZ: selectedPLZ,
								PLZ: PLZ,
								setSelectedPLZ: setSelectedPLZ,
								datatableref: datatableref
							})
						}

						{selectedColumns.find((item) => item.field === "Ort")?.visible === true &&
							OrtColumn({
								selectedOrt: selectedOrt,
								Ort: Ort,
								setSelectedOrt: setSelectedOrt,
								datatableref: datatableref
							})
						}

						{selectedColumns.find((item) => item.field === "E-Mail")?.visible === true &&
							EmailColumn({
								selectedMail: selectedMail,
								Mail: Mail,
								setSelectedMail: setSelectedMail,
								datatableref: datatableref,
								body: Emailbody
							})
						}

						{selectedColumns.find((item) => item.field === "Telefon")?.visible === true &&
							TelefonColumn({
								selectedTelefon: selectedTelefon,
								Telefon: Telefon,
								setSelectedTelefon: setSelectedTelefon,
								datatableref: datatableref,
								body: Telefonbody
							})
						}
						{selectedColumns.find((item) => item.field === "Mobil")?.visible === true &&
							MobilColumn({
								selectedMobil: selectedMobil,
								Mobil: Mobil,
								setSelectedMobil: setSelectedMobil,
								datatableref: datatableref,
								body: Mobilbody
							})
						}

						{selectedColumns.find((item) => item.field === "Kundentyp")?.visible === true &&
							KundentypColumn({
								selectedKundenTyp: selectedKundenTyp,
								setSelectedKundenTyp: setSelectedKundenTyp,
								datatableref: datatableref
							})
						}

						{selectedColumns.find((item) => item.field === "Verkäufer")?.visible === true &&
							VerkäuferColumn({
								selectedVerkäufer: selectedVerkäufer,
								Verkäufer: Verkäufer,
								setSelectedVerkäufer: setSelectedVerkäufer,
								datatableref: datatableref
							})
						}


						{selectedColumns.find((item) => item.field === "Fax-DW")?.visible === true &&
							FaxDWColumn({
								selectedFaxDW: selectedFaxDW,
								FaxDW: FaxDW,
								setSelectedFaxDW: setSelectedFaxDW,
								datatableref: datatableref
							})
						}
						
						{selectedColumns.find((item) => item.field === "Vorname")?.visible === true &&
							VornameColumn({
								selectedVorname: selectedVorname,
								Vorname: Vorname,
								setSelectedVorname: setSelectedVorname,
								datatableref: datatableref
							})
						}

						{selectedColumns.find((item) => item.field === "Nachname")?.visible === true &&
							NachnameColumn({
								selectedNachname: selectedNachname,
								Nachname: Nachname,
								setSelectedNachname: setSelectedNachname,
								datatableref: datatableref
							})
						}

						{selectedColumns.find((item) => item.field === "Firmenname")?.visible === true &&
							FirmenbezeichnungColumn({
								selectedFirmenname: selectedFirmenname,
								Firmenbezeichnung: Firmenbezeichnung,
								setSelectedFirmenname: setSelectedFirmenname,
								datatableref: datatableref
							})
						}

						{selectedColumns.find((item) => item.field === "Kundennummer")?.visible === true &&
						 	KundennummerColumn({
								selectedKundennummer: selectedKundennummer,
								Kundennummer: Kundennummer,
								setSelectedKundennummer: setSelectedKundennummer,
								datatableref: datatableref
							}) 
						}

						{selectedColumns.find((item) => item.field === "offeneAngebote")?.visible === true &&
						<Column alignHeader="center" header="offene Angebote" field="offeneAngebote" style={{width: "180px"}} sortable filterMatchMode="contains"
							showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
							filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
							body={(row) => <div className='w-full text-center'>{row.offeneAngebote}</div>}
						/> 
						}

						{selectedColumns.find((item) => item.field === "VKGesamt")?.visible === true &&
						<Column alignHeader="center" header="VK-Summe" field="VKSumme" style={{width: "120px"}} sortable filterMatchMode="contains"
							showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
							filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
							body={(row) => {
								let _vkneotto = row.VKSumme?.toLocaleString('de-AT', { style: 'currency', currency: 'EUR' });
								return <div className="w-full text-right mr-5">{_vkneotto}</div>;
							}}
						/> 
						}

						<Column frozen alignFrozen='right' header="Aktionen" body={rowData => <PopupMenu buttonClassName="p-button-secondary" label="Aktionen" icon="pi pi-chevron-down" model={[
							{ label: 'Bearbeiten', icon: 'pi pi-pencil', command: () => {if(props.art === "Stammkunden"){
																							editKunde(rowData);
																						}
																						else{
																							editAngebotsKunde(rowData);
																						}} },
							{ label: 'Deaktivieren', icon: 'pi pi-trash', command: () => deactivateKunden(rowData) },
							{ label: "Kundenstammblatt drucken", icon: "pi pi-print"},
							{ label: "Kundenordner öffnen", icon: "pi pi-folder-open", command: () => openKundenExplorer()},
							{ label: "E-Mail senden", icon: "pi pi-envelope", command: () => onEmailSend(rowData) },
							{ label: "Verkäufe", icon: "pi pi-euro", command: () => showVerkäufe(rowData) },
							{ label: "Reklamationen", icon: "pi pi-flag"},
							{ label: "Rechnungen", icon: "pi pi-file-o"},
							{ label: "Angebote", icon: "pi pi-tag", command: () => {if(props.art === "Stammkunden"){
																						showAngebote(rowData);
																					}
																					else{
																						showAngebotsKundenAngebote(rowData); 
																					}} }
						]}/>}
						style={{width: '10em', textAlign: 'center'}}/>
					</DataTable>
				</div>
			</div>
			<Dialog header="Einstellungen" visible={einstellungenVisible} style={{ width: '15vw' }}
				footer={
					<div>
						<Button label="Schließen" icon="pi pi-check" onClick={e => {setEinstellungenVisble(false); saveKundenEinstellungen()}} />
					</div>
				}
				onHide={e => {
					setEinstellungenVisble(false);
					saveKundenEinstellungen();
					}}>
					<div className="grid">
						{columns?.map((col) => (
							<div className="col-12">
								<Checkbox key={col.field} value={col} checked={selectedColumns.find((item) => item.field === col.field).visible} className="mr-3"
								onChange={e => {
									let _columns = [...columns];
									_columns.forEach(x => {
										x.visible = selectedColumns.find((_x) => _x.field === x.field)?.visible
									});
									let column = _columns.find((item) => item.field === col.field);
									if (column !== undefined && column !== null)
										column.visible = e.checked;
									setSelectedColumns(_columns);
								}}/>
							{col.field}
							</div>
						))}
					</div>
			</Dialog>
			<Dialog header="Kundenstammdaten exportieren" visible={KundenstammdatenExportVisible} style={{ width: "15vw"}}
				footer={
					<div>
						<Button label="Exportieren" icon="pi pi-file-excel" onClick={e => {setKundenstammdatenExportVisible(false); exportExcel();}}/>
					</div>
				}
				onHide={e => setKundenstammdatenExportVisible(false)}>
					<div className="grid">
						{selectedColumns?.map((col) => (
							<div className='col-12'>
								<Checkbox key={col.field} value={col} checked={selectedColumns.some((item) => item.field === col.field)} className="mr-3"
								onChange={e => {
									let _selectedCols = [...selectedColumns];

									if (e.checked)
										_selectedCols.push(e.value);
									else
										_selectedCols.splice(_selectedCols.findIndex(x => x.field === e.value.field), 1);
							
									setSelectedColumns(_selectedCols);
								}}/>
							{col.header}
							</div>
						))}
					</div>
			</Dialog>
		</React.Fragment>
	);
}