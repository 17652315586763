import React, { useRef, useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GlobalState } from '../GlobalState';
import { AngebotService } from '../../service/Angebotsservice';
import { MitarbeiterService } from '../../service/MitarbeiterService';
import CONFIG from 'config';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { addDE } from '../LocalDE';
import { DataTable } from 'primereact/datatable';
import { Column } from "primereact/column";
import { FileUpload } from "primereact/fileupload";
import { InputTextarea } from 'primereact/inputtextarea';
import { KundenService } from '../../service/KundenService';
import { Tag } from 'primereact/tag';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { ImportService } from '../../service/ImportService';
import { AngebotTypService } from '../../service/AngebotTypService';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { SelectButton } from "primereact/selectbutton";
import VerkäuferTemplate from '../Verkäufer';
import { flagTemplate } from '../Laenderdropdown';
import AngebotKundenSuche from './AngebotKundensuche';
import AngebotKundeAnzeigen from './AngebotKundeAnzeigen';


export default function Angebot(props) {
	const labelWidth = '15em';

	const angebotService = new AngebotService();
	const angebotTypService = new AngebotTypService();
	const mitarbeiterService = new MitarbeiterService();
	const kundenService = new KundenService();
	const importService = new ImportService();
	const history = useHistory();
	const { id } = useParams();
	const toastRef = useRef(null);

	const [globalState, setGlobalState] = useContext(GlobalState);
	const [angebot, setAngebot] = useState({});
    const [kunden, setKunden] = useState([]); 
	const [origKunden, setOrigKunden] = useState([]);
	const [verkäufer, setVerkäufer] = useState([]);
	const [plzsuche, setPlzsuche] = useState(false);
	const [plz, setPlz] = useState([]);
	const [selectedPlz, setSelectedPlz] = useState();
	const [bemerkung, setBemerkung] = useState('');
	const [neuerKundeDialogVisible, setNeuerKundeDialogVisible] = useState(false);
	const [kundeSucheDialogVisible, setKundeSucheDialogVisible] = useState(false);
	const [kunde, setKunde] = useState(null);
	const [derzeitigerBenutzer, setDerzeitigerBenutzer] = useState();
	const [totalSize, setTotalSize] = useState(0);
	const [angebottypen, setAngebottypen] = useState([]);
	const [angebotGeladen, setAngebotGeladen] = useState(false);
	const [kundebearbeiten, setKundebearbeiten] = useState(false);
	const [baufirmen, setBaufirmen] = useState([]);
	const [gültigkeit, setGültigkeit] = useState();
    const toast = useRef(null);
    const fileUploadRef = useRef(null);

	const kundentypOptions = [{name: "Privat", value: 0}, 
							  {name: "Firma", value: 1}]

	useEffect(() => {
		setGlobalState({...globalState, page: props.action === 'bearbeiten' ? 'Angebot > Bearbeiten' : 'Angebot > Anlegen'});
		mitarbeiterService.getVerkaeufer().then(data => setVerkäufer(data));
		
		let _derzeitigerBenutzer;
		if (sessionStorage.mitarbeiter !== undefined && sessionStorage.mitarbeiter !== null) {
			_derzeitigerBenutzer = JSON.parse(sessionStorage.mitarbeiter);
			setDerzeitigerBenutzer(_derzeitigerBenutzer);
		}
		else
			mitarbeiterService.getMitarbeiterMitBenutzername(sessionStorage.benutername).then(data => {
				setDerzeitigerBenutzer(data);
				_derzeitigerBenutzer = data;
			});

		kundenService.getAll().then(kundenData => {
			setKunden(kundenData);
			setOrigKunden(kundenData);
			if (props.action === 'bearbeiten') {
				try {
					angebotService.getFromId(Number(id)).then(data => {
						if (data.Status === undefined || data.Status?.length === 0)
							data.Status = "Offen";
						data.Erfassung = new Date(data.Erfassung);
						data.nextKontakt = new Date(data.nextKontakt);
						data.Gültigkeit = new Date(data.Gültigkeit);
						setAngebotGeladen(true);
						if (data.Kunde?.KundenTyp === 1)
						setKundebearbeiten(true);
						else if (data.Kunde?.KundenTyp === 0)
							data.Kunde = kundenData.find(x => x.Kundennummer === data.Kunde.Kundennummer);
						if (data.Kunde !== undefined && data.Kunde !== null)
							setKunde(data.Kunde);

						data.Bemerkungen = data.Bemerkungen.map(x => x = {...x, Datum: new Date(x.Datum)});
						console.log(data);
						setAngebot(data);
					}).catch(x => console.log(x));
				} catch (e) {
					console.log(e)
				}
			} else {
				angebotService.getNextID().then(id => {
					let _gültigkeit = new Date();
					_gültigkeit.setDate(_gültigkeit.getDate() + 7 );
					let _nextKontakt = new Date();
					_nextKontakt.setDate(_nextKontakt.getDate() + 14);
					let _angebot = {
						Status: "Offen",
						Erfassung: new Date(),
						nextKontakt: _nextKontakt,
						ID: id,
						Gültigkeit: _gültigkeit,
						Verkäufer: {MitarbeiterNr: _derzeitigerBenutzer.MitarbeiterNr, Name: _derzeitigerBenutzer.Name}
					};
					setAngebot(_angebot);
					console.log(_derzeitigerBenutzer);
					setKunde({...kunden, 
						KundenArt: 0,
						ReLand: {name: "AT - Österreich", code: "AT"},
					});
					setKundebearbeiten(true);
				});
			}
		});
		
		angebotTypService.getAll().then(data => setAngebottypen(data));
		angebotService.getBaufirmen().then(data => setBaufirmen(data));
	}, []);

	useEffect(() => {
		if (derzeitigerBenutzer === null || derzeitigerBenutzer === undefined)
			return;
		setAngebot({...angebot,
			Verkäufer: {MitarbeiterNr: derzeitigerBenutzer.MitarbeiterNr, Name: derzeitigerBenutzer.Name}
			});
	}, [derzeitigerBenutzer])

	addDE();
    
	const onSpeichernClick = () => {
		if (props.action === 'bearbeiten') {
			console.log(angebot);
			angebotService.update(angebot).then((x) => {
				importService.uploadFiles(fileUploadRef.current?.files, CONFIG.ServerUrl + '/Angebot/' + x.ID + '/Upload').then((y) => {
					toastRef.current.show({severity:'success', summary: 'Erfolgreich', detail:'Angebot gespeichert.', life: 2000});
					setTimeout(() => history.goBack(), 1000);
				});
			})
		}
		else {
			console.log(angebot);

			angebotService.create(angebot).then(res => res.json()).then((x) => {
				if (fileUploadRef?.current?.files?.length > 0) {
					importService.uploadFiles(fileUploadRef.current.files, CONFIG.ServerUrl + '/Angebot/' + x.ID + '/Upload').then((y) => {
						toastRef.current.show({severity:'success', summary: 'Erfolgreich', detail:'Angebot gespeichert.', life: 2000});
						setTimeout(() => history.goBack(), 1000);
						return;
					});
				}
				else
				{
					toastRef.current.show({severity:'success', summary: 'Erfolgreich', detail:'Angebot gespeichert.', life: 2000});
					setTimeout(() => history.goBack(), 1000);
					return;
				}
			}).catch((e) => {
				console.log(e);
				toastRef.current.show({severity:'error', summary: 'Das Angebot konnte nicht gespeichert werden', life: 3000});
			});
		}
	}

    const selectedVerkäufertemplate = (v, props) => {
		if (v){
			return <VerkäuferTemplate Verkäufer={v} ></VerkäuferTemplate>
		}
		return <div>Verkäufer wählen</div>; 
    }

	const verkäuferOptionTemplate = (v) => {
        return <VerkäuferTemplate Verkäufer={v} ></VerkäuferTemplate>
    }

	const kundeOptionTemplate = (v, props) => {
		if (v) {
            return (
                <div className="buyer-item">
                	<div>{v.ErstePersonVorname} {v.ErstePersonNachname}</div>
            	</div>
            );
        }

        return (
            <span>
                {props.placeholder}
            </span>
        );
    }

	const onTemplateSelect = (e) => {
		console.log(e);
		try {
			let _totalSize = totalSize;
			[...e.files].forEach(file => {
				_totalSize += file.size;
			});

			if (_totalSize === undefined)
				setTotalSize(0);
			else
				setTotalSize(_totalSize);
		} catch (e) {
			console.log(e);
		}
    }

	const onTemplateUpload = (e) => {
        let _totalSize = 0;
        e.files.forEach(file => {
            _totalSize += (file.size || 0);
        });

        setTotalSize(_totalSize);
        toast.current.show({severity: 'info', summary: 'Success', detail: 'File Uploaded'});
    }

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        callback();
    }

    const onTemplateClear = () => {
        setTotalSize(0);
    }

	const ortTemplate = (v) => {
        return (
            <div>
                <div>{v.plz} {v.ort}</div>
            </div>
        );
    }

	const renderPlzSucheFooter = (e) => {
        return (
            <div>
                <Button label="Übernehmen" icon="pi pi-save" onClick={onPlzSucheFooterClick} className="p-button-text" />
            </div>
        );
    }

	const onPlzSucheFooterClick = () => {
		setKunde({...kunde, ReOrt: selectedPlz.ort, RePlz: selectedPlz.plz});
		setPlzsuche(false);
	}

	const typTemplate = (t, props) => {
		if (t === null)
			return props.placeholder;
        return (
            t.Bezeichnung
        );
    }

	const itemTemplate = (file, props) => {
        return (
            <div className="grid">
                <div className="col-10 align-items-center justify-content-center" style={{textAlign: 'left'}}>
                    <div className='p-component p-my-2'>
                        {file.name}
                    </div>
                </div>
				<div className='col-2'>
					<Tag value={props.formatSize} severity="warning" className="p-mx-3 p-my-2" />
					<Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
				</div>
            </div>
        )
    }

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-image mt-2 p-3" style={{'fontSize': '2em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)'}}></i>
                <span style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)'}} className="p-ml-1">Dateien hier hereinziehen</span>
            </div>
        )
    }

	const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize/10000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
                {chooseButton}
                {/* {uploadButton} */}
                {cancelButton}
                {/* <ProgressBar value={value} displayValueTemplate={() => `${formatedValue} / 1 MB`} style={{width: '300px', height: '20px', marginLeft: 'auto'}}></ProgressBar> */}
            </div>
        );
    }

	const chooseOptions = {icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined'};
    const uploadOptions = {icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined'};
    const cancelOptions = {icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'};

	const wertemplate = (row) => {
		return <VerkäuferTemplate Verkäufer={row.Wer} />;
	}

	const datumTemplate = (row) => {
		const year = row.Datum.getFullYear();
		let month = row.Datum.getMonth() + 1;
		if (month < 10){
			month = "0"+month;
		}
		const day = row.Datum.getDate();
		const hour = row.Datum.getHours();
		let minutes = row.Datum.getMinutes();
		if (minutes < 10){
			minutes = "0"+minutes;
		}


		const datestring = `${day}.${month}.${year}  ${hour}:${minutes}`
		return datestring; 
	}

	const statuse = [
		{Status: "Offen"},
		{Status: "Angenommen"},
		{Status: "Verkauft"},
		{Status: "Abgelehnt"},
		{Status: "Warteschlange"}
	]

	const selectedStatusTemplate = (rowData) => {
		if (angebot.Status !== undefined) {
			return (
				<span>
					<div style={{borderRadius: "50%", backgroundColor: conditioncolor(rowData)}} className={rowData.Status === "Offen" ? "pi pi-circle-off" : 
						rowData.Status === "Angenommen" ? "pi pi-check-circle" : 
						rowData.Status === "Abgelehnt" ? "pi pi-times-circle" : 
						rowData.Status === "Warteschlange" ? "pi pi-history" : 
						rowData.Status === "Verkauft" ? "pi pi-euro": ""}>
					</div> {rowData.Status}</span>
            
			);
		}
		else{
			return (
				<span>Nicht ausgewählt</span>
			);
		}
	}

	const statusOptionTemplate = (rowData) => {
		return(<span>
			<div style={{borderRadius: "50%", backgroundColor: conditioncolor(rowData)}} 
			className={rowData.Status === "Offen" ? "pi pi-circle-off" : 
			rowData.Status === "Angenommen" ? "pi pi-check-circle" : 
			rowData.Status === "Abgelehnt" ? "pi pi-times-circle" : 
			rowData.Status === "Warteschlange" ? "pi pi-history" : 
			rowData.Status === "Verkauft" ? "pi pi-euro": ""}></div> {rowData.Status}</span>
			
		)
	}

	const conditioncolor = (rowData) => {
		if(rowData.Status === "Offen"){
			return "Azure";
		} 
		else if(rowData.Status === "Angenommen"){
			return "LightGreen"; 
		}
		else if(rowData.Status === "Abgelehnt"){
			return "LightPink"; 
		}
		else{
			return; 
		}
	}

    return (
        <React.Fragment>
	        <Toast ref={toastRef}/>
			<div className="p-component grid">
				<div className="col-12">
					<div className="field grid">
						<Button label="Speichern" icon="pi pi-save" style={{marginRight: '.5em'}} onClick={onSpeichernClick} />
						<Button label="Abbrechen" icon="pi pi-times" onClick={() => history.goBack()} />
					</div>
				</div>
				<div className="col-6">
					<div className="field grid">
						<label htmlFor="erfassung" className="col-fixed" style={{width: labelWidth}}>Angebotsnummer</label>
						<div className="col">
							{angebot.ID}
						</div>
					</div>
					<div className="field grid">
						<label htmlFor="erfassung" className="col-fixed" style={{width: labelWidth}}>Datum Erfassung</label>
						<div className="col">
							<Calendar disabled inputId="erfassung" value={angebot.Erfassung} showIcon={true} locale="de" style={{width: labelWidth}} onChange={e => setAngebot({...angebot, Erfassung: e.target.value})}/>
						</div>
					</div>
					{props.action === 'bearbeiten' &&
					<div className="field grid">
						<label htmlFor="status" className="col-fixed" style={{width: labelWidth}}>Status</label>
						<div className="col">
							<Dropdown options={statuse} optionLabel="Status" optionValue="Status"
							valueTemplate={selectedStatusTemplate(angebot)} itemTemplate={statusOptionTemplate}
							value={angebot.Status === undefined ? setAngebot({...angebot, Status: "Offen"}): angebot.Status} id="Status" type="text" style={{width: labelWidth}}
							onChange={e => {setAngebot({...angebot, Status: e.target.value});}}/>
						</div>
					</div>}
					<div className="field grid">
						<label htmlFor="kunde" className="col-fixed" style={{width: labelWidth}}>Kunde</label>
						<div className="col">
                            <InputText disabled value={angebot.Kunde?.Kommission} style={{width: labelWidth}}/>
							<Button label="Suchen" style={{marginLeft:"10px"}} icon="pi pi-search" onClick={e => setKundeSucheDialogVisible(true)}></Button>
							<AngebotKundenSuche setKunde={(e) => setAngebot({...angebot, Kunde: e})}
								options={kundentypOptions}
								visible={kundeSucheDialogVisible}
								setVisible={setKundeSucheDialogVisible}  />
						</div>
					</div>
                    <div className="field grid">
						<label htmlFor="verkäufer" className="col-fixed" style={{width: labelWidth}}>Verkäufer</label>
						<div className="col">
						<Dropdown value={angebot.Verkäufer} options={verkäufer} onChange={e => setAngebot({...angebot, Verkäufer: e.value})}
							optionLabel="Name" filter showClear filterBy="Name" placeholder="Verkäufer wählen"
							valueTemplate={selectedVerkäufertemplate} id="verkäufer" emptyMessage="Keine Verkäufer gefunden"
							itemTemplate={verkäuferOptionTemplate} style={{width: labelWidth}}/>
						</div>
					</div>
					<div className="field grid">
						<label htmlFor="typ" className="col-fixed" style={{width: labelWidth}}>Typ</label>
						<div className="col">
						<Dropdown value={angebot.Was} options={angebottypen} style={{width: labelWidth}} onChange={e => setAngebot({...angebot, Was: e.value})}
							optionLabel="Name" filter showClear filterBy="Bezeichnung" placeholder="Typ wählen"
							valueTemplate={typTemplate} id="typ" emptyMessage="Keine Typen gefunden"
							itemTemplate={typTemplate} 
							/>
						</div>
					</div>
					<div className="field grid">
						<label htmlFor="baufirma" className="col-fixed" style={{width: labelWidth}}>Baufirma</label>
						<div className="col">
							<Dropdown tabIndex={-1} value={angebot.Baufirma} id="baufirma" options={baufirmen} style={{width: labelWidth}}
								onChange={e => setAngebot({...angebot, Baufirma: e.target.value})} editable />
						</div>
					</div>
					<div className="field grid">
						<label htmlFor="vknetto" className="col-fixed" style={{width: labelWidth}}>VK Netto</label>
						<div className="col">
							<InputNumber value={angebot.VKnetto} inputClassName="text-right" inputStyle={{width: labelWidth}} id="vknetto" mode="currency" currency="EUR" locale="de-DE" onChange={e => setAngebot({...angebot, VKnetto: e.value})}/>
						</div>
					</div>
					<div className="field grid">
						<label htmlFor="nextkontakt" className="col-fixed" style={{width: labelWidth}}>nächster Kontakt</label>
						<div className="col">
							<Calendar showWeek={true} inputId="nextkontakt" value={angebot.nextKontakt} style={{width: labelWidth}} showIcon={true} locale="de"
							onChange={e => setAngebot({...angebot, nextKontakt: e.target.value})}/>
						</div>
					</div>
					<div className="field grid">
						<label htmlFor="gültigkeit" className="col-fixed" style={{width: labelWidth}}>Gültigkeit</label>
						<div className="col">
							<Calendar showWeek={true} inputId="gültigkeit" value={angebot.Gültigkeit} style={{width: labelWidth}} showIcon={true} locale="de"
							onChange={e => setAngebot({...angebot, Gültigkeit: e.target.value})}/>
						</div>
					</div>
				</div>
				<div className='col-6'>
					<div className='grid'>
						<div className='col-12'>
						{(angebot?.Kunde?.KundenArt === 1) &&
							<React.Fragment>
								<div className="field grid">
									<label htmlFor="firmenbezeichnung" className="col-fixed" style={{width: labelWidth}}>Firmenbezeichnung</label>
									<div className="col">
										<label disabled>{angebot?.Kunde?.Firmenbezeichnung}</label>
									</div>
								</div>
								<div className="field grid">
									<label htmlFor="uid" className="col-fixed" style={{width: labelWidth}}>UID Nummer</label>
									<div className="col">
										<label disabled>{angebot?.Kunde?.uid}</label>
									</div>
								</div>
								<div className="field grid">
									<label htmlFor="fn" className="col-fixed" style={{width: labelWidth}}>Firmenbuchnummer</label>
									<div className="col">
										<label disabled>{angebot?.Kunde?.FN}</label>
									</div>
								</div>
							</React.Fragment>
							}
							<div className="field grid">
								<label htmlFor="vorname" className="col-fixed" style={{width: labelWidth}}>Titel</label>
								<div className="col">
									<label disabled id="vorname">{angebot?.Kunde?.ErstePersonTitel}</label>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="vorname" className="col-fixed" style={{width: labelWidth}}>Vorname</label>
								<div className="col">
									<label disabled value={angebot?.Kunde?.ErstePersonVorname} id="vorname" onChange={e => props?.setKunde({...props?.kunde, ErstePersonVorname: e.target.value})}>{angebot?.Kunde?.ErstePersonVorname}</label>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="nachname" className="col-fixed" style={{width: labelWidth}}>Nachname</label>
								<div className="col">
									<label disabled value={angebot?.Kunde?.ErstePersonNachname} id="nachname" onChange={e => props?.setKunde({...props?.kunde, ErstePersonNachname: e.target.value})}>{angebot?.Kunde?.ErstePersonNachname}</label> 
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="mobil" className="col-fixed" style={{width: labelWidth}}>Mobilnummer</label>
								<div className="col">
									<a href={"tel:"+angebot?.Kunde?.ErstePersonMobil} id="mobil" >{angebot?.Kunde?.ErstePersonMobil}</a> 
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="mail" className="col-fixed" style={{width:labelWidth}}>E-Mail</label>
								<div className="col">
									<a href={"mailto:"+angebot?.Kunde?.ErstePersonEmail} id="mail" >
										{angebot?.Kunde?.ErstePersonEmail}
									</a>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="adresse" className="col-fixed" style={{width: labelWidth}}>Straße</label>
								<div className="col">
									<label disabled value={angebot?.Kunde?.ReAdresse} id="adresse" onChange={e => props?.setKunde({...props?.kunde, ReAdresse: e.target.value})}>
									{angebot?.Kunde?.ReAdresse}</label>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="plz" className="col-fixed" style={{width: 'calc(' + labelWidth + ' / 4)'}}>PLZ</label>
								<label htmlFor="ort" className="col-fixed" style={{width: 'calc((' + labelWidth + ' / 4) * 3)'}}>Ort</label>
								<div className="col">
									<label disabled id="plz" type="text" style={{width: '5em'}}>{flagTemplate(angebot?.Kunde?.ReLand)} {angebot?.Kunde?.RePlz}</label>
									<label disabled value={angebot?.Kunde?.ReOrt} id="ort" type="text" style={{marginLeft: "10px", width: '15em'}} onChange={e => props?.setKunde({...props?.kunde, ReOrt: e.target.value})}>{angebot?.Kunde?.ReOrt}</label> 
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='col-12'>
					<div className="field grid">
						<label htmlFor="vknetto" className="col-fixed" style={{width: labelWidth}}>Dateien</label>
						<div className="col">
							<ul>
								{
									angebot?.Dateien?.map(function(item){
										return <li>{item}</li>;
									})
								}		
							</ul>
						</div>
					</div>
					<div className="field grid">
						<label htmlFor="notizen" className="col-fixed" style={{width: labelWidth}}>Notizen</label>
						<div className="col">
							<InputTextarea value={angebot.Notiz} rows={5} cols={60} onChange={e => setAngebot({...angebot, Notiz: e.target.value})} style={{width: '40em'}}></InputTextarea>
						</div>
					</div>
				</div>
				{(props.action === 'bearbeiten') &&
					<div className='col-12'>
						<Button onClick={(e) => {
							let url = "localdir:" + angebot.AngebotOrdner;
							window.open(url, "_self");
							}} icon="pi pi-folder-open" style={{width: "180px"}}>&nbsp; Angebotsordner öffnen</Button>
					</div>
				}
				<div className='col-12'>
					<Accordion>
						<AccordionTab header="Bemerkungen">
							<div className="field grid">
								<div className="col-12">
									<InputTextarea value={bemerkung} rows={5} cols={60} onChange={e => setBemerkung(e.target.value)} style={{width: '40em'}}></InputTextarea>
									<Button style={{marginBottom: "5px", marginLeft: "10px"}} className='p-mt-1' icon="pi pi-plus" label="Bemerkung hinzufügen"
										onClick={e => {
											if (bemerkung.length === 0)
												return;
											let _bemerkung =
											{
												ID: 0,
												Datum: new Date(),
												Notiz: bemerkung,
												Wer: { MitarbeiterNr: derzeitigerBenutzer.MitarbeiterNr, Name: derzeitigerBenutzer.Name}
											};
											let bemerkungen = angebot.Bemerkungen?.concat(_bemerkung);
											if (bemerkungen === undefined)
												bemerkungen = [_bemerkung];
											setAngebot({...angebot, Bemerkungen: bemerkungen});
											setBemerkung('');
										}}
									/>
								</div>
								<div className='col-12'>
									<DataTable value={angebot.Bemerkungen}
										className="p-datatable-striped" rowHover paginator rows={20}
										rowsPerPageOptions={[20,30,40]} scrollable scrollHeight="60vh"
										emptyMessage="Keine Bemerkungen gefunden"
										>
										<Column field="Datum" header="Datum" style={{width: "100px"}} body={datumTemplate} sortable filter/>
										<Column field="Wer" header="Mitarbeiter" style={{width: "200px"}} body={wertemplate}  sortable filter/>
										<Column field="Notiz" header="Bemerkung" style={{width: "200px"}} sortable filter/>
									</DataTable>
								</div>
							</div>
						</AccordionTab>
						<AccordionTab header="Dateien hochladen">
							<div className="field grid">
								<div className="col">
								<FileUpload style={{width: "600px"}} ref={fileUploadRef} name="upload" multiple maxFileSize={100000000}
									onUpload={onTemplateUpload} onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
									headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
									chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />
								</div>
							</div>
						</AccordionTab>
					</Accordion>
				</div>
            </div>
			<Dialog visible={neuerKundeDialogVisible} onHide={e => setNeuerKundeDialogVisible(false)} style={{ width: '40vw' }}
				header={
					<span className='p-pr-5'>Neuer Kunde</span>
				}
				footer= {
					<div className='grid col-12'>
						<Button label='Abbrechen' icon="pi pi-times" onClick={e => setNeuerKundeDialogVisible(false)} />
						<Button label='OK' icon="pi pi-check" onClick={e => {
							let _kunden = origKunden;
							let _kunde = {...kunde, KundenTyp: 1};
							_kunde.Kommission = _kunde.ErstePersonVorname + " " + _kunde.ErstePersonNachname
							console.log(_kunde);
							_kunde.MitarbeiterNr = -1;
							_kunden.push(_kunde);
							setKunden(_kunden);
							let _angebot = {...angebot, Kunde: _kunde};
							console.log(_angebot);
							setAngebot(_angebot);
							setNeuerKundeDialogVisible(false);
						}}/>
					</div>
				}
			>

			<SelectButton value={kunde?.KundenArt} style={{marginBottom: "20px"}}
				options={kundentypOptions} optionLabel="name" optionValue="value"
				onChange={(e) => {
					let _kunde = {...kunde, KundenArt: e.target.value};
					console.log(_kunde);
					setKunde(_kunde);
					}}>
			</SelectButton>
			<AngebotKundeAnzeigen kunde={kunde} setKunde={setKunde} labelWidth={labelWidth} setPlzsuche={setPlzsuche} />
			</Dialog>
			<Dialog header="PLZ Suche" visible={plzsuche} style={{ width: '20vw' }} footer={renderPlzSucheFooter} onHide={() => setPlzsuche(false)}>
				<div className='col-12 field grid'>
					<label htmlFor="plzsuche" className="col-fixed" style={{width: labelWidth}}>PLZ</label>
					<div className="col">
						<Dropdown value={selectedPlz} options={plz} 
							optionLabel="plz" filter showClear  placeholder="PLZ wählen"
							emptyMessage="Keine PLZ gefunden"
							id="plzsuche"
							onChange={e => setSelectedPlz(e.target.value)}
							style={{ width: '8vw' }}
						/>
					</div>
				</div>
				<div className='col-12 field grid'>
					<label htmlFor="ortsuche" className="col-fixed" style={{width: labelWidth}}>Ort</label>
					<div className="col">
						<Dropdown value={selectedPlz} options={plz} 
							filter showClear  placeholder="Ort wählen"
							optionLabel='ort'
							itemTemplate={ortTemplate}
							emptyMessage="Keinen Ort gefunden"
							id="ortsuche"
							style={{ width: '8vw' }}
							onChange={e => setSelectedPlz(e.target.value)}
						/>
					</div>
				</div>
			</Dialog>
        </React.Fragment>
    );
}