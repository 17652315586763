import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { GlobalState } from './GlobalState';
import React, { useRef, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

export default function Services(props) {
	const [selectedColumns, setSelectedColumns] = useState();
    const [services, setServices] = useState([]);
    const [globalState, setGlobalState] = useContext(GlobalState);
    const [einstellungenVisible, setEinstellungenVisble] = useState(false);
    const history = useHistory();
    const dt = useRef(null);

	const columns = [
		{field: 'Verkäufer', header: 'Verkäufer'}
	]

    useEffect(() => {
        setGlobalState({...globalState, page: 'Service'});
        setSelectedColumns(columns);
	}, []);

	const columnComponents =
        selectedColumns?.map(col=> {
			var result = columns.find(obj => {return obj.field === col.field});
			return <Column key={result.field} field={result.field} header={result.header} filter sortable filterMatchMode="contains" filterElement={result.filterElement}/>;
    	});

    return (
		<React.Fragment>
			<div className="p-component grid">
                <div className="col-6">
					<Button label="neues Service" icon="pi pi-plus-circle" onClick={() => history.push("/service/neu")}/>
				</div>
				<div className="col-6" style={{textAlign: 'right'}}>
					<Button label="Einstellungen" icon="pi pi-circle" onClick={e => setEinstellungenVisble(true)}/>
				</div>
                <div className='col-12'>
                    <DataTable value={services} className="p-datatable-striped" rowHover paginator rows={20} rowsPerPageOptions={[20,30,40]} scrollable scrollHeight="60vh" ref={dt}>
                         {columnComponents}
                    </DataTable>
                </div>
            </div>
        </React.Fragment>
    );
}