
export function checkPhoneNumber (props) {

	const phoneno = /^(\+\d{1,3}[- ]?)?\d{8,12}$/
	
	function checkPhoneNumber(number){
		let formattedNumber = number;
		if(formattedNumber !== undefined){
			formattedNumber = formattedNumber.replace(/\s+/g, "");
		}

		if (phoneno.test(formattedNumber)){

			return true;
		}
		else{
			return false;
		}
	}

	function formatPhoneNumber(number){
		number = number.replace(/\s+/g, "");
		if(number.length === 10) {
			console.log(13); 
			number = number.slice(0, 3) + " " + number.slice(3, 6) + " " + number.slice(6, 9) + " " + number.slice(9, 10);
			return { status: true, Nummer: number } ;
		}
		if(number.length === 11) {
			console.log(13); 
			number = number.slice(0, 3) + " " + number.slice(3, 6) + " " + number.slice(6, 9) + " " + number.slice(9, 11);
			return { status: true, Nummer: number } ;
		}
		if(number.length === 12) {
			console.log(13); 
			number = number.slice(0, 3) + " " + number.slice(3, 6) + " " + number.slice(6, 9) + " " + number.slice(9, 11) + " " + number.slice(11, 12);
			return { status: true, Nummer: number } ;
		}
		if(number.length === 13) {
			console.log(13); 
			number = number.slice(0, 3) + " " + number.slice(3, 6) + " " + number.slice(6, 9) + " " + number.slice(9, 11) + " " + number.slice(11, 13);
			return { status: true, Nummer: number } ;
		}
		else if(number.length === 14) {
			console.log(14); 
			number = number.slice(0, 3) + " " + number.slice(3, 6) + " " + number.slice(6, 9) + " " + number.slice(9, 11) + " " + number.slice(11, 14);
			return { status: true, Nummer: number } ;
		}
		else if(number.length === 15) {
			console.log(15);
			number = number.slice(0, 3) + " " + number.slice(3, 6) + " " + number.slice(6, 9) + " " + number.slice(9, 11) + " " + number.slice(11, 15); 
			return { status: true, Nummer: number } ;
		}
		else if(number.length === 16) {
			number = number.slice(0, 3) + " " + number.slice(3, 6) + " " + number.slice(6, 9) + " " + number.slice(9, 11) + " " + number.slice(11, 16);
			console.log(16); 
			return { status: true, Nummer: number } ;
		}
		else if(number.length < 13 || number.length > 16){
			console.log("false3")
			return { status: false, Nummer: number }; 
		}
	}

	if (props !== undefined){
		if(checkPhoneNumber(props)){
			props = formatPhoneNumber(props)
			return props;
		}
		else{
			props = formatPhoneNumber(props)
			return props; 
		}
	}
	else {
		return false; 
	}
	

}