import React, { useRef, useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GlobalState } from './GlobalState';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from "primereact/column";
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';

export default function Service(props)
{
    const history = useHistory();
    const toastRef = useRef(null);

	const [globalState, setGlobalState] = useContext(GlobalState);
    const [kunde, setKunde] = useState();
    const [kunden, setKunden] = useState([]);
    const [auftragTyp, setAuftragTyp] = useState();
    const [aktAufträge, setAktAufträge] = useState([]);
    const [vorAufträge, setVorAufträge] = useState([]);
    const [service, setService] = useState(undefined);
    const [durchzuführendeArbeit, setDurchzuführendeArbeit] = useState(undefined);
    const dtaktaufträge = useRef(null);
    const dtvoraufträge = useRef(null);
    const labelWidth = '12em';

    useEffect(() => {
        setGlobalState({...globalState, page: 'Service'});
        setKunden([]);
        setAktAufträge([]);
        setVorAufträge([]);
	}, []);


    const AuftragOptions = [
        { Id: 1, Bezeichnung: "kein bestehnder Auftrag" },
        { Id: 2, Bezeichnung: "aktuelle Aufträge" },
        { Id: 3, Bezeichnung: "ehemalige Aufträge"}
    ]

    const onSpeichernClick = (e) => {

    }

    return (
		<React.Fragment>
	        <Toast ref={toastRef}/>
			<div className="p-component grid">
                <div className="col-12">
                    <div className="field grid">
                        <Button label="Speichern" icon="pi pi-save" style={{marginRight: '.5em'}} onClick={onSpeichernClick} />
                        <Button label="Abbrechen" icon="pi pi-times" onClick={() => history.back()} />
                    </div>
                </div>
                <Accordion activeIndex={0} style={{width: "100%"}}>
                    <AccordionTab header="Kunde">
                        <div className="col-12">
                            <div className="field grid">
                                <label htmlFor="kunde" className="col-fixed" style={{width: labelWidth}}>Kunde</label>
                                <div className="col">
                                    <Dropdown value={kunde} options={kunden} onChange={e => setKunde(e.value)}
                                        optionLabel="Name" filter showClear filterBy="Name" placeholder="Kunde wählen"
                                        id="kunde" emptyMessage="keine Kunden gefunden"
                                        />
                                    <Button className='p-ml-2' label="neuer Kunde" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="field grid">
                                <label htmlFor="baustelle" className="col-fixed" style={{width: labelWidth}}>Baustelle</label>
                                <div className="col">
                                    <Dropdown value={kunde} options={kunden} onChange={e => setKunde(e.value)}
                                        optionLabel="Name" filter showClear filterBy="Name" placeholder="Baustelle wählen"
                                        id="baustellen" emptyMessage="keine Baustelle gefunden"
                                        />
                                    <Button className='p-ml-2' label="Baustelle hinzufügen" />
                                </div>
                            </div>
                        </div>
                    </AccordionTab>
                    <AccordionTab header="Auftrag">
                        <div className="col-12">
                            <div className="field grid">
                                <label htmlFor="auftrag" className="col-fixed" style={{width: labelWidth}}>Auftrag</label>
                                <div className="col">
                                <Dropdown id="auftrag" value={auftragTyp} options={AuftragOptions} optionLabel="Bezeichnung"
                                    onChange={(e) => setAuftragTyp(e.target.value)}/>
                                </div>
                            </div>
                            {auftragTyp?.Id === 2 &&
                                <DataTable value={aktAufträge} className="p-datatable-striped" rowHover paginator rows={20} rowsPerPageOptions={[20,30,40]} scrollable scrollHeight="60vh" ref={dtaktaufträge}>
                                    <Column header="Kunde"/>
                                </DataTable>
                            }
                            {auftragTyp?.Id === 3 &&
                                <DataTable value={vorAufträge} className="p-datatable-striped" rowHover paginator rows={20} rowsPerPageOptions={[20,30,40]} scrollable scrollHeight="60vh" ref={dtvoraufträge}>
                                    <Column header="Kunde"/>
                                </DataTable>
                            }
                        </div>
                    </AccordionTab>
                    <AccordionTab header="Montage">
                        <div className='col-12'>
                            <div className="field grid">
                                    <label htmlFor="montagedauer" className="col-fixed" style={{width: labelWidth}}>Montagedauer</label>
                                    <div className='col'>
                                        <InputNumber id="montagedauer"/>
                                        <Dropdown options={["Std", "Tage"]}/>
                                    </div>
                            </div>
                            <div className="field grid">
                                <label htmlFor="montagetermin" className="col-fixed" style={{width: labelWidth}}>Montagetermin</label>
                                <div className='col'>
                                    <Calendar id="montagetermin" />
                                </div>
                            </div>
                            <div className="field grid">
                                <label htmlFor="anzahlmonteure" className="col-fixed" style={{width: labelWidth}}>Anzahl Monteure</label>
                                <div className='col'>
                                    <InputNumber id="anzahlmonteure" />
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='grid'>
                                <div className='col-6'>
                                    <div className="field grid">
                                        <label htmlFor="auftrag" className="col-fixed" style={{width: labelWidth}}>Hinweise</label>
                                        <div className='col'>
                                            <InputTextarea rows={10} cols={30} />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className="field grid">
                                        <label htmlFor="auftrag" className="col-fixed" style={{width: "15em"}}>durchzuführende Arbeiten</label>
                                        <div className='col'>
                                            <InputText value={durchzuführendeArbeit} onChange={(e) => setDurchzuführendeArbeit(e.target.value)} />
                                            <Button label="hinzufügen" onClick={() => {
                                                let _arbeiten = undefined;
                                                if (service === undefined || service === null || service.durchzuführendeArbeiten === undefined || service.durchzuführendeArbeiten === null)
                                                    _arbeiten = new Array();
                                                else
                                                    _arbeiten = service.durchzuführendeArbeiten;
                                                
                                                _arbeiten.push(durchzuführendeArbeit);
                                                setService({...service, durchzuführendeArbeiten: _arbeiten});
                                                setDurchzuführendeArbeit("");
                                            }} />
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <ul>
                                        {service?.durchzuführendeArbeiten?.map((e) => {
                                            return (
                                                <li>{e}</li>
                                            )}
                                        )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </AccordionTab>
                </Accordion>
             </div>
        </React.Fragment>
    );
}