import React, { useRef, useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GlobalState } from './GlobalState';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';

function Aufträge(props)
{
	const [globalState, setGlobalState] = useContext(GlobalState);
    const [aufträge, setAufträge] = useState([]);
    const [einstellungenVisible, setEinstellungenVisble] = useState(false);
	const [selectedColumns, setSelectedColumns] = useState([]);
    const [derzeitigerBenutzer, setDerzeitigerBenutzer] = useState();
    const dt = useRef(null);

    const columns = [
		{field: 'Verkäufer', visible: true},
	]

    useEffect(() => {
		setGlobalState({...globalState, page: 'Aufträge'});
    }, []);

    const onFilterResetClick = () => {
		let filters = dt.current.getFilters()
		for (const filter in filters){
			dt.current.filters = filters[filter].value = '';
		}
		dt.current.reset();
	}

	const renderHeader = () => {
        return (
            <div>
				<span>
					<Button icon= "pi pi-filter-slash" label="Filter zurücksetzen" onClick={onFilterResetClick}></Button>
				</span>
            </div>
        )
    }

    return(
        <React.Fragment>
            <div className="p-component grid">
                <div className="col-12">
					<DataTable value={aufträge} header={renderHeader}
                        className="p-datatable-striped" rowHover paginator rows={20} rowsPerPageOptions={[20,30,40]}
                        scrollable scrollHeight="60vh" ref={dt} filterDisplay="row" scrollDirection="both"
                        currentPageReportTemplate="({first} - {last} von {totalRecords})"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown">


                    </DataTable>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Aufträge;
