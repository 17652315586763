import { Dropdown } from 'primereact/dropdown';

export 	const Laender =
[{name: "AT - Österreich", code: "AT"},
{name: "DE - Deutschland", code: "DE"},
{name: "CZ - Tschechien", code: "CZ"}]

export const selectedCountryTemplate = (option, props) => {
	if (option) {
		return (
			<div className="country-item country-item-value">
				<img alt={option?.name} src="http://primefaces.org/primereact/images/flag/flag_placeholder.png" className={`flag flag-${option?.code?.toLowerCase()}`} />
				<span> {option?.name}</span>
			</div>
		);
	}

	return (
		<span>
			{props.placeholder}
		</span>
	);
}

export const countryOptionTemplate = (option) => {
	return (
		<div className="country-item">
			<img alt={option?.name} src="http://primefaces.org/primereact/images/flag/flag_placeholder.png" className={`flag flag-${option?.code?.toLowerCase()}`} />
			<span> {option?.name}</span>
		</div>
	);
}

export const flagTemplate = (option) => {
	return (
		<div className="country-item">
			<img alt={option?.name} src="http://primefaces.org/primereact/images/flag/flag_placeholder.png" className={`flag flag-${option?.code?.toLowerCase()}`} />
		</div>
	);
}

export function Laenderdropdown(props) {

	return ( 
		<div>
			<Dropdown
			value={props.Land}
			options={Laender} optionLabel="name"
			filter showClear filterBy="name"
			placeholder={"Land auswählen"}
			valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate}
			onChange={e => props.onChange(e)}
			style={{width: 'calc(20em / 0.967)'}}
			className={props.Pflichtfeld === true && (props.Land === "" || props.Land === undefined || props.Land === null) ? 'p-invalid' : ''}
			/>
		</div>
	)
}