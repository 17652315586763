import React from 'react';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';

export default function TelefonColumn(props) {

	const onTelefonChange = (e) => {
		props.setSelectedTelefon(e.target.value);
		props.datatableref.current.filter(e.target.value, "ErstePersonTelefon", "in");
	}

	return(
		<Column header="Telefon" field="ErstePersonTelefon" style={{width: "200px"}} filter sortable filterMatchMode="contains"
		filterElement={
			<MultiSelect value={props.selectedTelefon} options={props.Telefon} optionLabel="Telefon" optionValue='Telefon' onChange={onTelefonChange} filter
			placeholder={"Alle"} />
		}
		showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
		filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
		body={props.body}
		/>
	)
}