import React, {createContext, useState} from 'react';

export const GlobalState = createContext({});

export default function GlobalStateComponent({children}) {
	const [state, setState] = useState({});
	return (
		<GlobalState.Provider value={[state, setState]}>
			{children}
		</GlobalState.Provider>
	);
}