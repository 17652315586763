import React, { useState, useEffect, useRef, useContext } from 'react';
import { Button } from 'primereact/button';
import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom'
import { InputText } from 'primereact/inputtext';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Calendar } from 'primereact/calendar';
import { FileUpload } from 'primereact/fileupload';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import { addDE } from './LocalDE';
import { LieferantenService } from '../service/LieferantenService';
import { ProduzentenService } from '../service/ProduzentenService';
import { ImageService} from '../service/ImageService';
import { GlobalState } from './GlobalState';
import { ImportService } from '../service/ImportService';
import CONFIG from 'config';

function Lieferant(props) {
	const labelWidth = '200px';
	const chooseOptions = {label: 'Wähle', icon: 'pi pi-fw pi-upload'};

	const history = useHistory();
	const lieferantenService = new LieferantenService();
	const imageService = new ImageService();
	const importService = new ImportService();
	const produzentenService = new ProduzentenService();
	const { id } = useParams();

	const [lieferant, setLieferant] = useState({});
	const [logoData, setLogoData] = useState(null);
	const [fotoData, setFotoData] = useState(null);
	const [logoClearDisabled, setLogoClearDisabled] = useState(true);
	const [fotoClearDisabled, setFotoClearDisabled] = useState(true);
	const [globalState, setGlobalState] = useContext(GlobalState);
	const [produzentAnlegen, setProduzentAnlegen] = useState(false);
	const [action, setAction] = useState();

	const logoUploadRef = useRef(null);
	const fotoUploadRef = useRef(null);
	const toastRef = useRef(null);

	addDE();

	const onSpeichernSuccess = () => {
		toastRef.current.show({severity:'success', summary: 'Erfolgreich', detail:'Lieferant gespeichert.', life: 2000});
		setTimeout(function(){ history.goBack(); }, 1000);
	}

	const onSpeichernClick = async () => {
		if(lieferant.Name === undefined || lieferant.Name === null || lieferant.Name === '') {
			toastRef.current.show({severity:'error', summary: 'Der Lieferant konnte nicht gespeichert werden', detail:'Nicht alle Pflichtfelder wurden ausgefüllt.', life: 3000});
			return;
		}
		if (action !== 'bearbeiten') {
			const l = await lieferantenService.getLieferantFromName(lieferant.Name)
			if(l !== undefined && l !== null) {
				toastRef.current.show({ severity: "error", summary: "Der Lieferant konnte nicht gespeichert werden", detail: "Ein Lieferant mit gleichen namen wurde bereits erstellt.", life: 5000, closable: false });
				return;
			}
		}
		try {
			const lId = await lieferantenService.set(lieferant);
			const logoResponse = await importService.upload(logoUploadRef.current.files?.[0], logoUploadRef.current.props.name, CONFIG.ServerUrl + '/Lieferant/Logo/' + lId);
			const fotoResponse = await importService.upload(fotoUploadRef.current.files?.[0], fotoUploadRef.current.props.name, CONFIG.ServerUrl + '/Lieferant/Foto/' + lId);
			if(logoResponse !== undefined && !logoResponse.ok) {
				const error = await logoResponse.json();
				toastRef.current.show({severity:'error', summary: error.summary, detail: error.detail, life: 3000});
				setLieferant({...lieferant, ID: lId});
				setAction('bearbeiten');
				return;
			}
			if(fotoResponse !== undefined && !fotoResponse.ok) {
				const error = await fotoResponse.json();
				toastRef.current.show({severity:'error', summary: error.summary, detail: error.detail, life: 3000});
				setLieferant({...lieferant, ID: lId});
				setAction('bearbeiten');
				return;
			}
			if (produzentAnlegen === true)
				produzentenService.set(lieferant);
			onSpeichernSuccess();
		} catch(e) {
			console.log(e);
			toastRef.current.show({severity:'error', summary: 'Der Lieferant konnte nicht gespeichert werden', life: 3000});
		}
	};

	const onCancelClick = () => history.goBack();

	const onLieferantLoad = data => {
		if (typeof(data) === "object") {
			if (typeof(data.Aktenkundigkeit) === "string") {
				data.Aktenkundigkeit = new Date(data.Aktenkundigkeit);
			}
			setLieferant(data);
			if (data.LogoID !== undefined && data.LogoID !== null) {
				imageService.getImage(data.LogoID).then(imgData => {
					setLogoData("data:image;base64," + imgData.Data);
				});
			}
			if (data.FotoID !== undefined && data.FotoID !== null) {
				imageService.getImage(data.FotoID).then(imgData => {
					setFotoData("data:image;base64," + imgData.Data);
				});
			}
		} else {
			setLieferant({});
		}
	};

	function onLieferantChange(parameter) {
		return e => {
			setLieferant(old => ({
				...old,
				[parameter]: e.target.value
			}));
		};
	}

	function onLieferantChangeString(parameter, maxLength) {
		return e => {
			setLieferant(old => ({
				...old,
				[parameter]: e.target.value.substring(0, maxLength ?? e.target.value.length)
			}));
		};
	}

	function onLieferantChangeNumber(parameter) {
		return e => {
			const str = e.target.value.replaceAll(/\D/g, '');
			setLieferant(old => ({
				...old,
				[parameter]: str === '' ? '' : Number(str)
			}));
		};
	}

	function onLieferantChangeCheckbox(parameter) {
		return e => {
			setLieferant(old => ({
				...old,
				[parameter]: e.target.checked
			}));
		};
	}

	useEffect(() => {
		setGlobalState({...globalState, page: props.action === 'bearbeiten' ? 'Lieferanten > bearbeiten' : 'Lieferanten > anlegen'});
		setAction(props.action);
		if (props.action === 'bearbeiten') {
			lieferantenService.getLieferantFromId(Number(id)).then(onLieferantLoad);
		} else {
			onLieferantLoad();
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<React.Fragment>
			<Toast ref={toastRef} />
			<div className="grid p-component">
				<div className="col-12">
					<div className="field grid">
						<Button label="Speichern" icon="pi pi-save" style={{marginRight: '.5em'}} onClick={onSpeichernClick} />
						<Button label="Abbrechen" icon="pi pi-times" onClick={onCancelClick} />
						{ props.action === 'neu' &&
							<React.Fragment>
								<Checkbox inputId="produzentAnlegen" className="p-ml-3" style={{alignSelf: 'center'}} checked={produzentAnlegen} onChange={e => setProduzentAnlegen(e.target.checked)}/>
								<label htmlFor="produzentAnlegen" className="p-ml-1 p-mb-0">Produzent Anlegen</label>
							</React.Fragment>
						}
					</div>
				</div>
				<div className="col-6">
					<div className="field grid">
						<label htmlFor="lieferantenNr" className="col-fixed" style={{width: labelWidth}}>Lieferanten-Nr.</label>
						<div className="col">
							<InputText id="lieferantenNr" type="text" value={lieferant.ID} disabled style={{width: labelWidth}}/>
						</div>
					</div>
					<div className="field grid">
						<label htmlFor="name" className="col-fixed" style={{width: labelWidth}}>Name</label>
						<div className="col">
							<InputText value={lieferant.Name} id="name" type="text" style={{width: labelWidth}} onChange={onLieferantChangeString("Name", 255)} className={lieferant.Name === undefined || lieferant.Name === null || lieferant.Name === '' ? 'p-invalid' : ''}/>
						</div>
					</div>
					<div className="field grid">
						<label htmlFor="logo" className="col-fixed" style={{width: labelWidth}}>Logo</label>
						<div className="col">
							<FileUpload ref={logoUploadRef} chooseOptions={chooseOptions} customUpload name="logo" mode="basic" accept="image/*" maxFileSize={1000000} style={{display: 'inline-block'}} onSelect={() => setLogoClearDisabled(logoUploadRef.current.files === undefined)} onClear={() => setLogoClearDisabled(true)}/>
							<Button icon="pi pi-times" onClick={() => logoUploadRef.current.clear()} disabled={logoClearDisabled}/>
						</div>
					</div>
					<div className="field grid">
						<label htmlFor="foto" className="col-fixed" style={{width: labelWidth}}>Foto</label>
						<div className="col">
							<FileUpload ref={fotoUploadRef} chooseOptions={chooseOptions} customUpload name="foto" mode="basic" accept="image/*" maxFileSize={1000000} style={{display: 'inline-block'}} onSelect={() => setFotoClearDisabled(fotoUploadRef.current.files === undefined)} onClear={() => setFotoClearDisabled(true)}/>
							<Button icon="pi pi-times" onClick={() => fotoUploadRef.current.clear()} disabled={fotoClearDisabled}/>
						</div>
					</div>
				</div>
				<div className="col-3 grid">
					{(lieferant.LogoID !== undefined && lieferant.LogoID !== null) ? <React.Fragment><span className="col-12" style={{textAlign: 'center', fontWeight: 'bold'}}>Logo</span><img className="col-12" src={logoData} style={{minWidth: '0px', maxHeight: '10em', objectFit: 'contain'}}/></React.Fragment> : <React.Fragment></React.Fragment>}
				</div>
				<div className="col-3 grid">
					{(lieferant.FotoID !== undefined && lieferant.FotoID !== null) ? <React.Fragment><span className="col-12" style={{textAlign: 'center', fontWeight: 'bold'}}>Foto</span><img className="col-12" src={fotoData} style={{minWidth: '0px', maxHeight: '10em', objectFit: 'contain'}}/></React.Fragment> : <React.Fragment></React.Fragment>}
				</div>
				<div className="col-12">
					<Accordion className="accordion-custom" activeIndex={0}>
						<AccordionTab header={<React.Fragment><span>Allgemein</span></React.Fragment>}>
							<div className="field grid">
								<label htmlFor="aktenkundigkeit" className="col-fixed" style={{width: labelWidth}}>Aktenkundigkeit</label>
								<div className="col">
									<Calendar id="aktenkundigkeit" value={lieferant.Aktenkundigkeit} showIcon={true} locale="de" style={{width: '20em'}} onChange={onLieferantChange("Aktenkundigkeit")}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="uidNr" className="col-fixed" style={{width: labelWidth}}>UID-Nr.</label>
								<div className="col">
									<InputText value={lieferant.UIDNR} id="uidNr" type="text" style={{width: '20em'}} onChange={onLieferantChangeString("UIDNR", 255)}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="fnNr" className="col-fixed" style={{width: labelWidth}}>FN-Nr.</label>
								<div className="col">
									<InputText value={lieferant.FNNR} id="fnNr" type="text" style={{width: '20em'}} onChange={onLieferantChangeString("FNNR", 255)}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="interneLieferantenNr" className="col-fixed" style={{width: labelWidth}}>interne Lieferantennummer</label>
								<div className="col">
									<InputText value={lieferant.interneLiefereantennr} id="interneLieferantenNr" type="text" style={{width: '20em'}} onChange={onLieferantChangeString("interneLiefereantennr", 255)}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="info" className="col-fixed" style={{width: labelWidth}}>Info</label>
								<div className="col">
									<InputTextarea value={lieferant.Info} id="info" rows={5} autoResize style={{width: '20em'}} onChange={onLieferantChangeString("Info")}/>
								</div>
							</div>
						</AccordionTab>
						<AccordionTab header={<React.Fragment><span>Adresse</span></React.Fragment>}>
							<div className="field grid">
								<label htmlFor="strasse" className="col-fixed" style={{width: labelWidth}}>Straße</label>
								<div className="col">
									<InputText value={lieferant.Adresse} id="strasse" type="text" style={{width: '20em'}} onChange={onLieferantChangeString("Adresse", 255)}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="plz" className="col-fixed" style={{width: 'calc(' + labelWidth + ' / 4)'}}>PLZ</label>
								<label htmlFor="ort" className="col-fixed" style={{width: 'calc((' + labelWidth + ' / 4) * 3)'}}>Ort</label>
								<div className="col">
									<InputText value={lieferant.PLZ} id="plz" type="text" style={{width: '5em'}} onChange={onLieferantChangeNumber("PLZ")}/>
									<InputText value={lieferant.Ort} id="ort" type="text" style={{width: '15em'}} onChange={onLieferantChangeString("Ort", 255)}/>
								</div>
							</div>
						</AccordionTab>
						<AccordionTab header={<React.Fragment><span>Kontakt</span></React.Fragment>}>
							<div className="field grid">
								<label htmlFor="telefon" className="col-fixed" style={{width: labelWidth}}>Telefon</label>
								<div className="col">
									<InputText value={lieferant.TelNr} id="telefon" type="text" style={{width: '20em'}} onChange={onLieferantChangeString("TelNr", 255)}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="fax" className="col-fixed" style={{width: labelWidth}}>Fax</label>
								<div className="col">
									<InputText value={lieferant.Fax} id="fax" type="text" style={{width: '20em'}} onChange={onLieferantChangeString("Fax", 255)}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="handy" className="col-fixed" style={{width: labelWidth}}>Handy</label>
								<div className="col">
									<InputText value={lieferant.Handy1} id="handy" type="text" style={{width: '20em'}} onChange={onLieferantChangeString("Handy1", 255)}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="email" className="col-fixed" style={{width: labelWidth}}>E-Mail</label>
								<div className="col">
									<InputText value={lieferant.Email} id="email" type="text" style={{width: '20em'}} onChange={onLieferantChangeString("Email", 255)}/>
								</div>
							</div>
						</AccordionTab>
						<AccordionTab header={<React.Fragment><span>Berichte</span></React.Fragment>}>
							<div className="field">
								<Checkbox inputId="befuellungsliste" value="Befuellungsliste" checked={lieferant.Befuellungsliste} onChange={onLieferantChangeCheckbox("Befuellungsliste")}></Checkbox>
								<label htmlFor="befuellungsliste" className="p-pl-1 p-mb-0 p-ml-1">Befüllungsliste</label>
							</div>
							<div className="field">
								<Checkbox inputId="tagesbericht" value="Tagesbericht" checked={lieferant.Tagesbericht} onChange={onLieferantChangeCheckbox("Tagesbericht")}></Checkbox>
								<label htmlFor="tagesbericht" className="p-pl-1 p-mb-0 p-ml-1">Tagesbericht</label>
							</div>
							<div className="field">
								<Checkbox inputId="wochenbericht" value="Wochenbericht" checked={lieferant.Wochenbericht} onChange={onLieferantChangeCheckbox("Wochenbericht")}></Checkbox>
								<label htmlFor="wochenbericht" className="p-pl-1 p-mb-0 p-ml-1">Wochenbericht</label>
							</div>
							<div className="field">
								<Checkbox inputId="monatsbericht" value="Monatsbericht" checked={lieferant.Monatsbericht} onChange={onLieferantChangeCheckbox("Monatsbericht")}></Checkbox>
								<label htmlFor="monatsbericht" className="p-pl-1 p-mb-0 p-ml-1">Monatsbericht</label>
							</div>
							<div className="field">
								<Checkbox inputId="aufBefuellungslisteAnzeigen" value="Monatsbericht" checked={lieferant.AufBefuellungslisteAnzeigen} onChange={onLieferantChangeCheckbox("AufBefuellungslisteAnzeigen")}></Checkbox>
								<label htmlFor="aufBefuellungslisteAnzeigen" className="p-pl-1 p-mb-0 p-ml-1">auf Befüllungsliste anzeigen</label>
							</div>
						</AccordionTab>
					</Accordion>
				</div>
			</div>
		</React.Fragment>
	);
}
export default Lieferant;