import React, { useRef, useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GlobalState } from './GlobalState';
import { AngebotService } from '../service/Angebotsservice';
import { MitarbeiterService } from '../service/MitarbeiterService';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { addDE } from './LocalDE';
import { InputTextarea } from 'primereact/inputtextarea';
import { KundenService } from '../service/KundenService';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { VerkäufeService } from '../service/VerkäufeService';
import { Checkbox } from 'primereact/checkbox';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { flagTemplate } from './Laenderdropdown';
import { formatDate } from '../helper/dateFormatter';
import { formatCurrency } from '../helper/currencyFormatter';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export default function Verkauf(props) {
	const labelWidth = '16em';
    const mitarbeiterService = new MitarbeiterService();
	const kundenService = new KundenService();
	const verkaufsService = new VerkäufeService();
	const angebotService = new AngebotService();

    const history = useHistory();
	const { id } = useParams();
	const toastRef = useRef(null);

    const [globalState, setGlobalState] = useContext(GlobalState);
	const [verkauf, setVerkauf] = useState({});
	const [angebot, setAngebot] = useState({});
    const [kunden, setKunden] = useState([]);
	const [origKunden, setOrigKunden] = useState([]);
	const [verkäufer, setVerkäufer] = useState([]);
	const [kundebearbeiten, setKundebearbeiten] = useState(false);
	const [EKInfoVisible, setEKInfoVisible] = useState(false);
	const [derzeitigerBenutzer, setDerzeitigerBenutzer] = useState();
	const [skonto, setSkonto] = useState();
	const [skontoOptions, setSkontoOptions] = useState([
		"7/7/30",
		"2/8/14",
		"3/8/14",
		"3/8/30",
		"5/8/30",
	]);
	const [VkWareVorSkontoExklMwst, setVkWareVorSkontoExklMwst] = useState();
	const [VkWareNachSkontoExklMwst, setVkWareNachSkontoExklMwst] = useState();
	const [produkte, setProdukte] = useState([]);
	
	const Mwst = 0.20;

	const checkNeuerVerkaufMitAngebot = () => (props.action === "neu" && id !== undefined);

	const getSkonto = () => {
		if (verkauf.Skonto === undefined || verkauf.Skonto === null)
			return;
		let _skonto = verkauf.Skonto;
		let splits = _skonto.split(' ');
		_skonto = parseInt(splits[0]);
		setSkonto(_skonto);
	}

	useEffect(() => {
		getSkonto();
	}, [verkauf]);

	useEffect(() => {
		if (skonto !== null && skonto !== undefined)
			onVKWareVorSkontoExklMwstChange({value: verkauf.VkWareVorSkontoExklMwst});
	}, [skonto])
 
    useEffect(() => {
		setGlobalState({...globalState, page: props.action === 'bearbeiten' ? 'Verkauf > Bearbeiten' : 'Verkauf > Anlegen'});
		mitarbeiterService.getVerkaeufer().then(data => setVerkäufer(data));
		kundenService.getAll().then(data => {setKunden(data); setOrigKunden(data)});
		if (props.action === 'bearbeiten') {
			try {
				verkaufsService.get(Number(id)).then(data => {
					if (data !== null && data !== undefined) {
						data.Liefertermin = new Date(data.Liefertermin);
						console.log(data);
						let rabatte = getRabatte({rabatt1: data.Rabatt1, rabatt2: data.Rabatt2, rabatt3: data.Rabatt3}, data.VkGesamtVorSkontoInklMwst);
						data.Rabatt1b = rabatte.rabatt1b;
						data.Rabatt1g = rabatte.rabatt1g;
						data.Rabatt2b = rabatte.rabatt2b;
						data.Rabatt2g = rabatte.rabatt2g;
						data.Rabatt3b = rabatte.rabatt3b;
						data.Rabatt3g = rabatte.rabatt3g;
						data.BezugsdokumentDatum = new Date(data.BezugsdokumentDatum);
						angebotService.getFromId(data.AngebotId).then(adata => 
						{
							adata.Erfassung = new Date(adata.Erfassung);
							adata.nextKontakt = new Date(adata.nextKontakt);
							data.AngebotDateien = adata.Dateien;
							setVerkauf(data);
							setAngebot(adata);
						});
					}
				}).catch(x => console.log(x));	
			} catch (e) {
				console.log(e)
			}
		}
		else if(checkNeuerVerkaufMitAngebot()){
			angebotService.getFromId(Number(id)).then(data=> {
				data.Erfassung = new Date(data.Erfassung);
				data.nextKontakt = new Date(data.nextKontakt);
				let _verkauf = {...verkauf,
						AngebotId: Number(id),
						AngebotDateien: data.Dateien,
						VkGesamtVorSkontoInklMwst: Number(data.VKnetto),
						BezugsdokumentDatum: data.Erfassung,
						VerkaufsStatus: 0,
						BezugsdokumentDatum: new Date(),
					};
				setVerkauf(_verkauf);
				setAngebot(data);
			})
			console.log(verkauf);
		} 
		else {
			setVerkauf({...verkauf,
				BezugsdokumentDatum: new Date(),
				VerkaufsStatus: 0
			})
			setKundebearbeiten(true);
		}
		if (sessionStorage.mitarbeiter !== undefined && sessionStorage.mitarbeiter !== null)
			setDerzeitigerBenutzer(JSON.parse(sessionStorage.mitarbeiter));
		else
			mitarbeiterService.getMitarbeiterMitBenutzername(sessionStorage.benutername).then(data => setDerzeitigerBenutzer(data));
		
	}, []);

	const onSpeichernClick = () => {
		console.log(verkauf);
		if (props.action === "neu")
			verkaufsService.neu(verkauf).then(
				history.goBack()
			);
		else if (props.action === "bearbeiten")
			verkaufsService.update(verkauf).then(
				history.goBack()
			);
	}

	const onVKWareVorSkontoExklMwstChange = (e) => {
		setVkWareVorSkontoExklMwst(e.value);
		let _VkWareNachSkontoExklMwst = skonto !== undefined && skonto  !== null ?
			e.value / (1 + (skonto / 100)) : e.value
		setVkWareNachSkontoExklMwst(_VkWareNachSkontoExklMwst)
		setVerkauf({...verkauf,
			VkWareVorSkontoExklMwst: e.value,
			VkWareNachSkontoExklMwst: _VkWareNachSkontoExklMwst
		}
	)}

	const getRabatte = (rabatte, VkGesamtVorSkontoInklMwst) => {
		let rabatt1 = rabatte.rabatt1;
		let rabatt2 = rabatte.rabatt2;
		let rabatt3 = rabatte.rabatt3;
		let rabatt1b = VkGesamtVorSkontoInklMwst / (1 + Mwst) * (rabatt1 * 0.01)
		let rabatt1g = VkGesamtVorSkontoInklMwst / (1 + Mwst) - rabatt1b;
		let rabatt2b = (VkGesamtVorSkontoInklMwst / (1 + Mwst) - rabatt1b) * (rabatt2 * 0.01);
		let rabatt2g = VkGesamtVorSkontoInklMwst / (1 + Mwst) - rabatt1b - rabatt2b;
		let rabatt3b = (VkGesamtVorSkontoInklMwst / (1 + Mwst) - rabatt1b - rabatt2b) * (rabatt3 * 0.01)
		let rabatt3g = VkGesamtVorSkontoInklMwst / (1 + Mwst) - rabatt1b - rabatt2b - rabatt3b;
		return {
			rabatt1b: rabatt1b,
			rabatt1g: rabatt1g,
			rabatt2b: rabatt2b,
			rabatt2g: rabatt2g,
			rabatt3b: rabatt3b,
			rabatt3g: rabatt3g,
		};
	}

	addDE();

	const VerkaufStatus = [
		{label: <React.Fragment><i class="pi pi-check-circle mr-1"></i>beauftragt</React.Fragment>, value: 0},
		{label: <React.Fragment><i class="pi pi-history mr-1"></i>bestellt</React.Fragment>, value: 1},
		{label: <React.Fragment><i class="pi pi-euro mr-1"></i>verrechnet</React.Fragment>, value: 2},
		{label: <React.Fragment><i class="pi pi-check mr-1"></i>erledigt</React.Fragment>, value: 3},
	];

    return (
        <React.Fragment>
	        <Toast ref={toastRef}/>
			<div className="p-component grid">
				<div className="col-12">
					<div className="field grid">
						<Button label="Speichern" icon="pi pi-save" style={{marginRight: '.5em'}} onClick={onSpeichernClick} />
						<Button label="Abbrechen" icon="pi pi-times" onClick={() => history.goBack()} />
					</div>
				</div>
                <div className="col-12">
					<div className="field grid">
						<label htmlFor="status" className="col-fixed" style={{width: labelWidth}}>Status</label>
						<div className="col">
							<Dropdown options={VerkaufStatus}
							value={verkauf.VerkaufsStatus} id="status" type="text" style={{width: '10em'}} optionValue="value" optionLabel='label'
							onChange={e => {
								console.log(e.target.value);
								setVerkauf({...verkauf, VerkaufsStatus: e.target.value})}
							}/>
						</div>
					</div>
                </div>
                <div className="col-12">
					<div className="field grid">
						<label htmlFor="internezuweisung" className="col-fixed" style={{width: labelWidth}}>Interne Zuweisung</label>
						<div className="col">
							<InputText value={verkauf.InterneZuweisung} id="internezuweisung" onChange={e => setVerkauf({...verkauf, InterneZuweisung: e.target.value})}/>
						</div>
					</div>
                </div>
				<Accordion style={{width: "100%"}} >
					<AccordionTab header="Allgemein" >
					<div className="col-12">
						<div className="field grid">
							<label htmlFor="generalunternehmer" className="col-fixed" style={{width: labelWidth}}>Generalunternehmer</label>
							<div className='col'>
								<Checkbox checked={verkauf.IstGeneralunternehmer} onChange={(e) => setVerkauf({...verkauf, IstGeneralunternehmer: e.checked})} />
								<InputText id="generalunternehmer" disabled={!verkauf.IstGeneralunternehmer} value={verkauf.Generalunternehmer} onChange={(e) => setVerkauf({...verkauf, Generalunternehmer: e.target.value})} style={{marginLeft:"10px"}}/>
							</div>
						</div>
					</div>
					<div className="col-12">
						<div className="field grid">
							<label htmlFor="bezugsdokument" className="col-fixed" style={{width: labelWidth}}>Bezugsdokument</label>
							<div className="col">
								<Dropdown options={verkauf.AngebotDateien}
								value={verkauf.Bezugsdokument} id="bezugsdokument" type="text" style={{width: '10em'}}
								onChange={e => setVerkauf({...verkauf, Bezugsdokument: e.target.value})}/>
							</div>
						</div>
					</div>
					<div className="col-12">
						<div className="field grid">
							<label htmlFor="bezugsdokumentdatum" className="col-fixed" style={{width: labelWidth}}>Vom (Datum)</label>
							<div className="col">
								<Calendar showWeek disabled inputId="bezugsdokumentdatum" value={verkauf.BezugsdokumentDatum}
									showIcon={true} locale="de" onChange={e => setVerkauf({...verkauf, BezugsdokumentDatum: e.target.value})}/>
							</div>
						</div>
					</div>
					<div className="col-12">
						<div className="field grid">
							<label htmlFor="bestätigt" className="col-fixed" style={{width: labelWidth}}>Bestätigt per</label>
							<div className="col">
								<Dropdown options={[
										{ label: <React.Fragment><i class="pi pi-send mr-1"></i>Email</React.Fragment>, value: "Email"},
										{ label: <React.Fragment><i class="pi pi-phone mr-1"></i>Telefon</React.Fragment>, value: "Telefon"},
										{ label: <React.Fragment><i class="pi pi-whatsapp mr-1"></i>Whatsapp</React.Fragment>, value: "Whatsapp"},
										{ label: <React.Fragment><i class="pi pi-pencil mr-1"></i>Unterschrift</React.Fragment>, value: "Unterschrift"},
									]}
									optionValue="value" optionLabel='label'
									value={verkauf.BestätigtBei} id="bestätigtbei" type="text" style={{width: '10em'}}
									onChange={e => setVerkauf({...verkauf, BestätigtBei: e.target.value})}/>
							</div>
						</div>
					</div>
					<div className="col-12">
						<div className="field grid">
							<label htmlFor="bestätigt" className="col-fixed" style={{width: labelWidth}}>Art</label>
							<div className="col">
								<Dropdown options={["Rohbau", "Sanierung", "Kernsanierung", "Service", "Montage", "Folgebestellung"]}
									value={verkauf.Art} id="bestätigt" type="text" style={{width: '10em'}}
									onChange={e => setVerkauf({...verkauf, Art: e.target.value})}/>
							</div>
						</div>
					</div>
					<div className="col-12">
						<div className="field grid">
							<label htmlFor="liefertermin" className="col-fixed" style={{width: labelWidth}}>ca. Liefertermin</label>
							<div className="col">
								<Calendar showWeek inputId="liefertermin" value={verkauf.Liefertermin}
									showIcon={true} locale="de" onChange={e => setVerkauf({...verkauf, Liefertermin: e.target.value})}/>
							</div>
						</div>
					</div>
					</AccordionTab>
					<AccordionTab header="Produkte">
						<div className="col-12">
							<DataTable value={produkte}>
								<Column key="Bezeichnung" field="Bezeichnung" />
								<Column key="aktiviert" field="aktiviert"  />
							</DataTable>
							<Button label="Hinzufügen" />
						</div>
					</AccordionTab>
					{id !== undefined && 
					<AccordionTab header="Angebot">
						<div className="col-6">
							<div className="field grid">
								<label htmlFor="id" className="col-fixed" style={{width: labelWidth}}>Angebotsnummer</label>
								<div className="col">
									{angebot.ID}
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="erfassung" className="col-fixed" style={{width: labelWidth}}>Datum Erfassung</label>
								<div className="col">
									{formatDate(angebot.Erfassung)}
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="verkäufer" className="col-fixed" style={{width: labelWidth}}>Verkäufer</label>
								<div className="col">
									{angebot.Verkäufer?.Name}
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="typ" className="col-fixed" style={{width: labelWidth}}>Typ</label>
								<div className="col">
									{angebot.Was?.Bezeichnung}
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="baufirma" className="col-fixed" style={{width: labelWidth}}>Baufirma</label>
								<div className="col">
									{angebot.Baufirma}
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="vknetto" className="col-fixed" style={{width: labelWidth}}>VK Netto</label>
								<div className="col">
									{formatCurrency(angebot.VKnetto)}
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="nextkontakt" className="col-fixed" style={{width: labelWidth}}>nächster Kontakt</label>
								<div className="col">
									{formatDate(angebot.nextKontakt)}
								</div>
							</div>
						</div>
						<div className='col-6'>
								{(angebot?.Kunde?.KundenArt === 1) &&
									<React.Fragment>
										<div className="field grid">
											<label htmlFor="firmenbezeichnung" className="col-fixed" style={{width: labelWidth}}>Firmenbezeichnung</label>
											<div className="col">
												<label disabled>{angebot?.Kunde?.Firmenbezeichnung}</label>
											</div>
										</div>
										<div className="field grid">
											<label htmlFor="uid" className="col-fixed" style={{width: labelWidth}}>UID Nummer</label>
											<div className="col">
												<label disabled>{angebot?.Kunde?.uid}</label>
											</div>
										</div>
										<div className="field grid">
											<label htmlFor="fn" className="col-fixed" style={{width: labelWidth}}>Firmenbuchnummer</label>
											<div className="col">
												<label disabled>{angebot?.Kunde?.FN}</label>
											</div>
										</div>
									</React.Fragment>
									}
									<div className="field grid">
										<label htmlFor="vorname" className="col-fixed" style={{width: labelWidth}}>Vorname</label>
										<div className="col">
											<label disabled id="vorname">{angebot?.Kunde?.ErstePersonVorname}</label>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="nachname" className="col-fixed" style={{width: labelWidth}}>Nachname</label>
										<div className="col">
											<label disabled id="nachname" >{angebot?.Kunde?.ErstePersonNachname}</label> 
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="nachname" className="col-fixed" style={{width: labelWidth}}>Telefon</label>
										<div className="col">
											<label disabled id="nachname">{angebot?.Kunde?.ErstePersonMobil}</label> 
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="mail" className="col-fixed" style={{width:labelWidth}}>E-Mail</label>
										<div className="col">
											<label disabled id="mail">
											{angebot?.Kunde?.ErstePersonEmail}</label>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="adresse" className="col-fixed" style={{width: labelWidth}}>Adresse</label>
										<div className="col">
											<label disabled id="adresse">
											{angebot?.Kunde?.ReAdresse}</label>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="plz" className="col-fixed" style={{width: 'calc(' + labelWidth + ' / 4)'}}>PLZ</label>
										<label htmlFor="ort" className="col-fixed" style={{width: 'calc((' + labelWidth + ' / 4) * 3)'}}>Ort</label>
										<div className="col">
											<label disabled id="plz" type="text" style={{width: '5em'}}>{angebot?.Kunde?.RePlz}</label>
											<label disabled id="ort" type="text" style={{marginLeft: "10px", width: '15em'}}>{angebot?.Kunde?.ReOrt}</label> 
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="reland" className="col-fixed" style={{width: labelWidth}}>Land</label>
										<div className="col">
											<React.Fragment children={flagTemplate(angebot?.Kunde?.ReLand)} />
										</div>
									</div>
						</div>
					</AccordionTab>}
					<AccordionTab header="Verrechnung" >
						<div className="col-12">
							<div className="field grid">
								<label htmlFor="vkgesamtvorskonto" className="col-fixed" style={{width: labelWidth}}>VK Gesamt VOR Skonto inkl. MwSt.</label>
								<div className="col">
									<InputNumber value={verkauf?.VkGesamtVorSkontoInklMwst} 
										id="vkgesamtvorskonto" onChange={e => setVerkauf({...verkauf, VkGesamtVorSkontoInklMwst: e.value})}
										mode="decimal" locale="de-DE" minFractionDigits={2} maxFractionDigits={2}/>
								</div>
							</div>
						</div>
						<div className='grid'>
							<div className='col-6'>
								<div className="col-12">
									<div className="field grid">
										<label htmlFor="vkmontagevorskonto" className="col-fixed" style={{width: labelWidth}}>VK Montage VOR Skonto inkl. MwSt.</label>
										<div className="col">
											<InputNumber value={verkauf.VkMontageVorSkonto} id="internezuweisung"
												onChange={e => setVerkauf({...verkauf,
													VkMontageVorSkonto: e.value,
													VkMontageVorSkontoExklMwst: e.value * (1 - Mwst)
												})}
											mode="decimal" locale="de-DE" minFractionDigits={2} maxFractionDigits={2}/>
										</div>
									</div>
								</div>
								<div className="col-12">
									<div className="field grid">
										<label htmlFor="internezuweisung" className="col-fixed" style={{width: labelWidth}}>Skonto</label>
										<div className="col">
											<Dropdown value={verkauf?.Skonto} options ={skontoOptions} 
												onChange={e => setVerkauf({...verkauf, Skonto: e.target.value})}
												/>
										</div>
									</div>
								</div>
								<div className="col-12">
									<div className="field grid">
									<label htmlFor="anzahlung" className="col-fixed" style={{width: labelWidth}}>Anzahlung inkl. MWSt.</label>
										<div className="col">
											<InputNumber value={verkauf.AnzahlungProzent} id="anzahlung" style={{marginRight: "10px"}}
												inputStyle={{width: "3rem"}}
												onChange={e => setVerkauf({...verkauf,
													AnzahlungProzent: e.value,
													Anzahlung: verkauf.VkGesamtVorSkontoInklMwst * (e.value / 100),
													AnzahlungVorSkontoExklMwst: verkauf.VkGesamtVorSkontoInklMwst * (e.value / 100) / ( 1+ Mwst)
												})}
													max="100"
													min="0"
													/>
											<InputNumber className='ml-3' value={verkauf.Anzahlung} id="anzahlung" onChange={e => setVerkauf({...verkauf, Anzahlung: e.value})}
											mode="decimal" locale="de-DE" minFractionDigits={2} maxFractionDigits={2}/>
											<div>% von VK Summe</div>
										</div>
									</div>
								</div>
								<div className="col-12">
									<div className="field grid">
										<label htmlFor="internezuweisung" className="col-fixed" style={{width: labelWidth}}>Anzahlung vor Skonto exkl. MwSt.</label>
										<div className="col">
											<InputNumber value={verkauf.AnzahlungVorSkontoExklMwst} id="internezuweisung" onChange={e => setVerkauf({...verkauf, AnzahlungVorSkontoExklMwst: e.value})}
											mode="decimal" locale="de-DE" minFractionDigits={2} maxFractionDigits={2}/>
										</div>
									</div>
								</div>
								<div className="col-12">
									<div className="field grid">
										<label htmlFor="internezuweisung" className="col-fixed" style={{width: labelWidth}}>Rabatt 1</label>
										<div className='col'>
											<div className='grid'>
												<div className='col-2'>
													<InputNumber
														value={verkauf.Rabatt1} id="rabatt1" inputStyle={{width: "6rem"}}
														onChange={e => {
															let rabatte = getRabatte({rabatt1: e.value, rabatt2: verkauf.Rabatt2, rabatt3: verkauf.Rabatt3}, verkauf.VkGesamtVorSkontoInklMwst)
															setVerkauf({
																...verkauf,
																	Rabatt1: e.value,
																	Rabatt1b: rabatte.rabatt1b,
																	Rabatt1g: rabatte.rabatt1g,
																	Rabatt2b: rabatte.rabatt2b,
																	Rabatt2g: rabatte.rabatt2g,
																	Rabatt3b: rabatte.rabatt3b,
																	Rabatt3g: rabatte.rabatt3g,
														})}}
														min={0} max={100}
														/>
												</div>
												<div className='col-10'>
													<InputNumber disabled value={verkauf.Rabatt1b} id="rabatt1b"
														onChange={e => setVerkauf({...verkauf, Rabatt1b: e.value})}/>
												</div>
												<div className='col-2'>

												</div>
												<div className='col-10'>
													{formatCurrency(verkauf.Rabatt1g)}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-12">
									<div className="field grid">
										<label htmlFor="internezuweisung" className="col-fixed" style={{width: labelWidth}}>Rabatt 2</label>
										<div className='col'>
											<div className='grid'>
												<div className='col-2'>
													<InputNumber value={verkauf.Rabatt2} id="rabatt2"
														inputStyle={{width: "6rem"}}
														onChange={(e) => {
															let rabatte = getRabatte({rabatt1: verkauf.Rabatt1, rabatt2: e.value, rabatt3: verkauf.Rabatt3}, verkauf.VkGesamtVorSkontoInklMwst)
															setVerkauf({
																...verkauf,
																	Rabatt2: e.value,
																	Rabatt1b: rabatte.rabatt1b,
																	Rabatt1g: rabatte.rabatt1g,
																	Rabatt2b: rabatte.rabatt2b,
																	Rabatt2g: rabatte.rabatt2g,
																	Rabatt3b: rabatte.rabatt3b,
																	Rabatt3g: rabatte.rabatt3g,
															});
														}}
														format={(e) => {console.log(e)}}
														min={0} max={100}/>
												</div>
												<div className='col-10'>
													<InputNumber disabled value={verkauf.Rabatt2b} id="rabatt2b"
														onChange={e => setVerkauf({...verkauf, Rabatt2b: e.value})}/>
												</div>
												<div className='col-2'>

												</div>
												<div className='col-10'>
													{formatCurrency(verkauf.Rabatt2g)}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-12">
									<div className="field grid">
										<label htmlFor="internezuweisung" className="col-fixed" style={{width: labelWidth}}>Rabatt 3</label>
										<div className='col'>
											<div className='grid'>
												<div className='col-2'>
													<InputNumber value={verkauf.Rabatt3} id="internezuweisung"
														inputStyle={{width: "6rem"}}
														onChange={(e) => {
															let rabatte = getRabatte({rabatt1: verkauf.Rabatt1, rabatt2: verkauf.Rabatt2, rabatt3: e.value}, verkauf.VkGesamtVorSkontoInklMwst)
															setVerkauf({
																...verkauf,
																	Rabatt3: e.value,
																	Rabatt1b: rabatte.rabatt1b,
																	Rabatt1g: rabatte.rabatt1g,
																	Rabatt2b: rabatte.rabatt2b,
																	Rabatt2g: rabatte.rabatt2g,
																	Rabatt3b: rabatte.rabatt3b,
																	Rabatt3g: rabatte.rabatt3g,
															});
														}}
														min={0} max={100}/>
												</div>
												<div className='col-10'>
													<InputNumber disabled value={verkauf.Rabatt3b} id="internezuweisung"
														onChange={e => setVerkauf({...verkauf, Rabatt3b: e.value})}/>
												</div>
												<div className='col-2'>

												</div>
												<div className='col-10'>
													{formatCurrency(verkauf.Rabatt3g)}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className='col-12'>
									<Button label="EK Info Lieferanten" onClick={() => setEKInfoVisible(true)}/>
								</div>
								<Dialog header="EK Info Lieferanten" visible={EKInfoVisible} style={{width: "15vw"}}
								footer={
									<div>
										<Button label="Schließen" icon="pi pi-check" onClick={(e) => setEKInfoVisible(false)}/>
									</div>
								}></Dialog>
							</div>
							<div className='col-6'>
								<div className="col-12">
									<div className="field grid">
										<label htmlFor="internezuweisung" className="col-fixed" style={{width: labelWidth}}>VK Ware VOR Skonto exkl. MwSt.</label>
										<div className='col'>
											<InputNumber value={VkWareVorSkontoExklMwst} id="internezuweisung"
												onChange={e => onVKWareVorSkontoExklMwstChange(e)}
											mode="decimal" locale="de-DE" minFractionDigits={2} maxFractionDigits={2}/>
										</div>
									</div>
								</div>
								<div className="col-12">
									<div className="field grid">
										<label htmlFor="internezuweisung" className="col-fixed" style={{width: labelWidth}}>VK Ware Nach Skonto exkl. MwSt.</label>
										<div className='col'>
											<InputNumber value={VkWareNachSkontoExklMwst} id="internezuweisung"
												onChange={e => {
													let _VkWareVorSkontoExklMwst = skonto !== undefined && skonto  !== null ?
														e.value * (1 + (skonto / 100)) : e.value
													setVkWareVorSkontoExklMwst(_VkWareVorSkontoExklMwst);
													setVkWareNachSkontoExklMwst(e.value)
													setVerkauf({...verkauf,
													VkWareVorSkontoExklMwst: _VkWareVorSkontoExklMwst,
													VkWareNachSkontoExklMwst: e.value
												})}}
											mode="decimal" locale="de-DE" minFractionDigits={2} maxFractionDigits={2}/>
										</div>
									</div>
								</div>
								<div className="col-12">
									<div className="field grid">
										<label htmlFor="internezuweisung" className="col-fixed" style={{width: labelWidth}}>VK Montage VOR Skonto exkl. MwSt.</label>
										<div className='col'>
											<InputNumber value={verkauf.VkMontageVorSkontoExklMwst} id="internezuweisung" 
											OnChange={e => setVerkauf({...verkauf,
												VkMontageVorSkonto: e.value * (1 + Mwst),
												VkMontageVorSkontoExklMwst: e.value
											})}
											mode="decimal" locale="de-DE" minFractionDigits={2} maxFractionDigits={2}/>
										</div>
									</div>
								</div>
								<div className="col-12">
									<div className="field grid">
										<label htmlFor="internezuweisung" className="col-fixed" style={{width: labelWidth}}>EK Ware Nach Skonto exkl. MwSt.</label>
										<div className='col'>
											<InputNumber value={verkauf.EkWareNachSkontoExklMwst} id="internezuweisung" onChange={e => setVerkauf({...verkauf, EkWareNachSkontoExklMwst: e.value})}
											mode="decimal" locale="de-DE" minFractionDigits={2} maxFractionDigits={2}/>
										</div>
									</div>
								</div>
								<div className="col-12">
									<div className="field grid">
										<label htmlFor="internezuweisung" className="col-fixed" style={{width: labelWidth}}>Bemerkungen.</label>
										<div className='col'>
											<InputTextarea value={verkauf?.Bemerkungen} onChange={(e) => setVerkauf({...verkauf, Bemerkungen: e.target.val})} cols="30" rows="10"/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</AccordionTab>
				</Accordion>
            </div>
        </React.Fragment>
    );
}