import { GetToken } from './AuthService';
import { fetchSimple } from "../helper/fetchSimple";
import CONFIG from 'config';

export class KundenService {
	async getAll() {
		return fetchSimple(CONFIG.ServerUrl + "/Kunden", await GetToken(CONFIG.ServerUrl)).then(res => res.json());
	}

	async getFromId(id) {
		return fetchSimple(CONFIG.ServerUrl + "/Kunde/" + id, await GetToken(CONFIG.ServerUrl)).then(res => res.json());
	}

	async create(kunde) {
		return fetchSimple(CONFIG.ServerUrl + "/Kunde/Create", await GetToken(CONFIG.ServerUrl), kunde).then(response => response);
	}

	async delete(id) {
		return fetchSimple(CONFIG.ServerUrl + "/Kunde/Delete/" + id, await GetToken(CONFIG.ServerUrl)).then(res => res);
	}

	async deactivate(id) {
		return fetchSimple(CONFIG.ServerUrl + "/Kunde/Deactivate/" + id, await GetToken(CONFIG.ServerUrl)).then(res => res);
	}
	async getNeueKundennummer() {
		return fetchSimple(CONFIG.ServerUrl + "/Kunde/NeueKundennummer/", await GetToken(CONFIG.ServerUrl)).then(res => res.json());
	}
}