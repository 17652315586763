import React from 'react';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import { flagTemplate } from '../Laenderdropdown';

export default function PLZColumn(props) {
	
	const onPLZChange = (e) => {
		props.setSelectedPLZ(e.target.value);
		props.datatableref.current.filter(e.target.value, "RePlz", "in");
	}
	
	return (<Column header="PLZ" field="RePlz" style={{width: "120px"}} filter sortable filterMatchMode="in"
		filterElement={
			<MultiSelect value={props.selectedPLZ} options={props.PLZ} optionLabel="RePlz" optionValue='RePlz' onChange={onPLZChange}
			placeholder={"Alle"} filter/>
		}
		showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
		filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
		body={row => {
			if (row.ReLand === null || row.ReLand === undefined)
				return;
			return <React.Fragment>{flagTemplate(row.ReLand)}<div className='ml-2'>{row.RePlz}</div></React.Fragment>;
		}}
		/>
	);
}