import React, { useRef } from "react";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";

export function PopupMenu(props) {
	const menuRef = useRef(null);

	return (
		<React.Fragment>
			<Menu className={props.menuClassName} ref={menuRef} popup model={props.model}/>
			<Button className={props.buttonClassName} style={props.style} label={props.label} icon={props.icon} disabled={props.disabled} onClick={e => { props.onClick?.(e); e.stopPropagation(); menuRef.current.toggle(e); }}/>
		</React.Fragment>
	);
}