import React, { useContext, useEffect, useState, useRef } from "react";
import { GlobalState } from "./GlobalState";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MitarbeiterService } from "../service/MitarbeiterService";
import { AuthService } from "../service/AuthService";
import { PopupMenu } from "./PopupMenu";
import { useHistory } from "react-router";
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { Checkbox } from "primereact/checkbox";
import { MultiSelect } from "primereact/multiselect";
import MitarbeiterBildTemplate from "./MitarbeiterBild";
import { Laender, flagTemplate } from "./Laenderdropdown";
import { InputText } from 'primereact/inputtext'

export default function MitarbeiterList() {
	const mitarbeiterService = new MitarbeiterService();
	const authService = new AuthService();

	const history = useHistory();

	const datatableref = useRef(null);

	const [globalState, setGlobalState] = useContext(GlobalState);
	const [mitarbeiter, setMitarbeiter] = useState([]);
	const [einstellungenVisible, setEinstellungenVisble] = useState(false);

	const [selectedVorname, setSelectedVorname] = useState(false); 
	const [selectedNachname, setSelectedNachname] = useState(false); 
	const [selectedBenutzername, setSelectedBenutzername] = useState(false); 
	const [selectedPos, setSelectedPos] = useState(false); 
	const [selectedAdresse, setSelectedAdresse] = useState(false);
	const [selectedPLZ, setSelectedPLZ] = useState(false);
	const [selectedOrt, setSelectedOrt] = useState(false); 
	const [selectedTelefon, setSelectedTelefon] = useState(false); 
	const [selectedEmail, setSelectedEmail] = useState(false); 

	const [Vorname, setVorname] = useState([]); 
	const [Nachname, setNachname] = useState([]);
	const [Benutzername, setBenutzername] = useState([]);
	const [Pos, setPos] = useState([]);
	const [Adresse, setAdresse] = useState([]);
	const [PLZ, setPLZ] = useState([]);
	const [Ort, setOrt] = useState([]);
	const [Telefon, setTelefon] = useState([]);
	const [Email, setEmail] = useState([]);

	const [globalFilterValue, setGlobalFilterValue] = useState('');

	const columns = [
		{field: 'Vorname', visible: true},
        {field: 'Nachname', visible: true},
        {field: 'Benutzername', visible: true},
		{field: 'Pos', visible: true},
		{field: "Adresse", visible: true},
		{field: "PLZ", visible: true},
		{field: "Ort", visible: true},
		{field: "Telefon", visible: true},
		{field: "Email", visible: true}
	]

	const [selectedColumns, setSelectedColumns] = useState([ 
		{field: 'Vorname', visible: true},
        {field: 'Nachname', visible: true},
        {field: 'Benutzername', visible: true},
		{field: 'Pos', visible: true},
		{field: "Adresse", visible: true},
		{field: "PLZ", visible: true},
		{field: "Ort", visible: true},
		{field: "Telefon", visible: true},
		{field: "Email", visible: true}, 
	]);
	const [derzeitigerBenutzer, setDerzeitigerBenutzer] = useState();

	useEffect(() => {
		setGlobalState({...globalState, page: 'Mitarbeiter'});
		mitarbeiterService.getAll().then(data => {
			setMitarbeiter(data);
			let Vorname = []; 
			let Nachname = [];
			let Benutzername = [];
			let Pos = [];
			let Adresse = [];
			let PLZ = [];
			let Ort = [];
			let Telefon = [];
			let Email = [];

			data.forEach(element => {
				console.log(element); 
				
				if (element.Vorname?.length > 0) {
					Vorname.push(element.Vorname); 
				}
				if (element.Nachname?.length > 0) {
					Nachname.push(element.Nachname); 
				}
				if (element.Benutzername?.length > 0){
					Benutzername.push(element.Benutzername);
				}
				if (element.Pos?.length > 0){
					Pos.push(element.Pos);
				}
				if (element.Adresse?.length > 0) {
					Adresse.push(element.Adresse);
				}
				if (element.Plz !== null) {
					PLZ.push(element.Plz);
				}
				if (element.Ort?.length > 0) {
					Ort.push(element.Ort);
				}
				if (element.Telefon?.length > 0) {
					Telefon.push(element.Telefon);
				}
				if (element.Email?.length > 0){
					Email.push(element.Email);
				}
			});

			let _Vorname = [];
			let _Nachname = [];
			let _Benutzername = [];
			let _Pos = [];
			let _Adresse = [];
			let _PLZ = [];
			let _Ort = [];
			let _Telefon = [];
			let _Email = [];

			Vorname.filter(onlyUnique).forEach(e => {
				_Vorname.push({Vorname: e}); 
			})
			setVorname(_Vorname); 

			Nachname.filter(onlyUnique).forEach(e => {
				_Nachname.push({Nachname: e});
			})
			setNachname(_Nachname);

			Benutzername.filter(onlyUnique).forEach(e => {
				_Benutzername.push({Benutzername: e});
			})
			setBenutzername(_Benutzername);

			Pos.filter(onlyUnique).forEach(e => {
				_Pos.push({Pos: e});
			})
			setPos(_Pos);

			Adresse.filter(onlyUnique).forEach(e => {
				_Adresse.push({Adresse: e});
			})
			setAdresse(_Adresse); 

			PLZ.filter(onlyUnique).forEach(e => {
				_PLZ.push({PLZ: e});
			})
			setPLZ(_PLZ);
			
			Ort.filter(onlyUnique).forEach(e => {
				_Ort.push({Ort: e});
			})
			setOrt(_Ort); 

			Telefon.filter(onlyUnique).forEach(e => {
				_Telefon.push({Telefon: e});
			})
			setTelefon(_Telefon); 

			Email.filter(onlyUnique).forEach(e => {
				_Email.push({Email: e});
			})
			setEmail(_Email); 

		});

		let _derzeitigerBenutzer;
		if (sessionStorage.mitarbeiter !== undefined && sessionStorage.mitarbeiter !== null) {
			_derzeitigerBenutzer = JSON.parse(sessionStorage.mitarbeiter);
			setDerzeitigerBenutzer(_derzeitigerBenutzer);
		}
		else
			mitarbeiterService.getMitarbeiterMitBenutzername(sessionStorage.benutername).then(data => {
				setDerzeitigerBenutzer(data);
				_derzeitigerBenutzer = data;
			});
		console.log(mitarbeiter); 
	}, []);

	useEffect(() => {
		if (derzeitigerBenutzer === undefined || derzeitigerBenutzer === null)
			return;

		try {
			let _selectedColumns = JSON.parse(derzeitigerBenutzer.MitarbeiterListEinstellungen);
			let _neu = new Array();
			columns.forEach(x => {
				x.visible = _selectedColumns.find((y) => y.field === x.field)?.visible;
				_neu.push(x);
			});
			setSelectedColumns(_neu);
			console.log(_neu);
		} catch (e) {
			console.log(e);
		}
	}, [derzeitigerBenutzer])

	const onLoeschenClick = rowData => {
		mitarbeiterService.mitarbeiterLoeschen(rowData.MitarbeiterNr).then(async () => {
			const token = await authService.getTokenInfo();
			if (token.id === rowData.MitarbeiterNr) {
				authService.logout();
				setGlobalState({...globalState});
			} else {
				mitarbeiterService.getAll().then(data => setMitarbeiter(data));
			}
		});
	};

	function saveKundenEinstellungen() {
		try {
			let _mitarbeiter = derzeitigerBenutzer;
			_mitarbeiter.MitarbeiterListEinstellungen = JSON.stringify(selectedColumns);
			sessionStorage.setItem("mitarbeiter", JSON.stringify(_mitarbeiter));
			setDerzeitigerBenutzer(_mitarbeiter);
			mitarbeiterService.updateEinstellungen(_mitarbeiter);
		} catch (e) {
			console.log(e);
		}
	}
	
	function onlyUnique(value, index, self){
		return self.indexOf(value) === index;
	}

	const onVornameChange = (e) => {
		setSelectedVorname(e.target.value); 
		datatableref.current.filter(e.target.value, "Vorname", "in")
	}

	const onNachnameChange = (e) => {
		setSelectedNachname(e.target.value); 
		datatableref.current.filter(e.target.value, "Nachname", "in")
	}

	const onBenutzernameChange = (e) => {
		setSelectedBenutzername(e.target.value); 
		datatableref.current.filter(e.target.value, "Benutzername", "in")
	}

	const onPosChange = (e) => {
		setSelectedPos(e.target.value); 
		datatableref.current.filter(e.target.value, "Pos", "in")
	}

	const onAdresseChange = (e) => {
		setSelectedAdresse(e.target.value); 
		datatableref.current.filter(e.target.value, "Adresse", "in")
	}

	const onPLZChange = (e) => {
		setSelectedPLZ(e.target.value); 
		datatableref.current.filter(e.target.value, "Plz", "in")
	}

	const onOrtChange = (e) => {
		setSelectedOrt(e.target.value); 
		datatableref.current.filter(e.target.value, "Ort", "in")
	}

	const onTelefonChange = (e) => {
		setSelectedTelefon(e.target.value); 
		datatableref.current.filter(e.target.value, "Telefon", "in")
	}

	const onEmailChange = (e) => {
		setSelectedEmail(e.target.value); 
		datatableref.current.filter(e.target.value, "Email", "in")
	}

	const onFilterResetClick = () => {
		let filters = datatableref.current.getFilters()
		for (const filter in filters){
			datatableref.current.filters = filters[filter].value = '';
		}
		setSelectedVorname([]); 
		setSelectedNachname([]); 
		setSelectedBenutzername([]); 
		setSelectedPos([]); 
		setSelectedAdresse([]);
		setSelectedPLZ([]);
		setSelectedOrt([]); 
		setSelectedTelefon([]); 
		setSelectedEmail([]); 
		datatableref.current.reset();
	}

	const renderHeader = () => {
        return (
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText autoFocus type="search" onInput={(e) => setGlobalFilterValue(e.target.value)} placeholder="Globale Suche" />
                </span>
                <span className='m-0' style={{float: "right"}}>
					<Button icon= "pi pi-filter-slash" label="Filter zurücksetzen" onClick={onFilterResetClick}></Button>
				</span>
            </div>
        )
    }

	return (
		<React.Fragment>
			<div className="p-component grid">
				<div className="col-6" style={{textAlign: 'left'}}>
					<Button label="Benutzer anlegen" icon="pi pi-plus-circle" onClick={() => history.push("/mitarbeiter/neu")}/>
				</div>
				<div className="col-6" style={{textAlign: 'right'}}>
					<Button label="Einstellungen" icon="pi pi-cog" onClick={e => setEinstellungenVisble(true)}/>
				</div>
				<div className="col-12">
					<DataTable ref={datatableref} value={mitarbeiter} responsive className="p-datatable-striped tableCursorPointer"
						header={renderHeader}
						currentPageReportTemplate="({first} - {last} von {totalRecords})"
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
						rowHover paginator rows={20} rowsPerPageOptions={[20,30,40]}
						filterDisplay="row"
						globalFilter={globalFilterValue}
						scrollable scrollHeight="flex" scrollDirection="both">
					
						<Column field="Bild" style={{width: "50px"}}
							body={(rowData) => <MitarbeiterBildTemplate Verkäufer={rowData.MitarbeiterNr} ></MitarbeiterBildTemplate>}
							/>
						

						{selectedColumns.find((item) => item.field === "Vorname").visible === true &&
						<Column header="Vorname" field="Vorname" style={{width: "200px"}} filter sortable filterMatchMode="contains"
							showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false} showFilterMenu={false}
							filterElement={
								<MultiSelect value={selectedVorname} options={Vorname} optionLabel="Vorname" optionValue='Vorname' onChange={onVornameChange} filter
										placeholder={"Alle"} />
							}
							/>
						}
						{selectedColumns.find((item) => item.field === "Nachname").visible === true &&
						<Column header="Nachname" field="Nachname" style={{width: "200px"}} filter sortable filterMatchMode="contains"
							showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false} showFilterMenu={false}
							filterElement={
								<MultiSelect value={selectedNachname} options={Nachname} optionLabel="Nachname" optionValue='Nachname' onChange={onNachnameChange} filter
										placeholder={"Alle"} />
							}/>
						}
						{selectedColumns.find((item) => item.field === "Benutzername").visible === true &&
						<Column header="Benutzername" field="Benutzername" style={{width: "200px"}} filter sortable filterMatchMode="contains"
							showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false} showFilterMenu={false}
							filterElement={
								<MultiSelect value={selectedBenutzername} options={Benutzername} optionLabel="Benutzername" optionValue='Benutzername' onChange={onBenutzernameChange} filter
										placeholder={"Alle"} />
							}/>
						}
						{selectedColumns.find((item) => item.field === "Pos").visible === true &&
						<Column header="Pos" field="Pos" style={{width: "200px"}} filter sortable filterMatchMode="contains"
							showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false} showFilterMenu={false}
							filterElement={
								<MultiSelect value={selectedPos} options={Pos} optionLabel="Pos" optionValue='Pos' onChange={onPosChange} filter
										placeholder={"Alle"} />
							}/>
						}
						{selectedColumns.find((item) => item.field === "Adresse").visible === true &&
						<Column header="Adresse" field="Adresse" style={{width: "200px"}} filter sortable filterMatchMode="contains"
							showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false} showFilterMenu={false}
							filterElement={
								<MultiSelect value={selectedAdresse} options={Adresse} optionLabel="Adresse" optionValue='Adresse' onChange={onAdresseChange} filter
										placeholder={"Alle"} />
							}/>
						}
						{selectedColumns.find((item) => item.field === "PLZ").visible === true &&
						<Column header="PLZ" field="Plz" style={{width: "200px"}} filter sortable filterMatchMode="contains"
							showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false} showFilterMenu={false}
							filterElement={
								<MultiSelect value={selectedPLZ} options={PLZ} optionLabel="PLZ" optionValue='PLZ' onChange={onPLZChange} filter
										placeholder={"Alle"} />
							}
							body={row => {
								console.log(row);
								let _land = Laender.find(x => x.name === row.Land);
								if (_land === undefined || _land === null)
									return;
								console.log(_land);
								return <React.Fragment>{flagTemplate(_land)}<div className="ml-2">{row.Plz}</div></React.Fragment>
							}}/>
						}
						{selectedColumns.find((item) => item.field === "Ort").visible === true &&
						<Column header="Ort" field="Ort" style={{width: "200px"}} filter sortable filterMatchMode="contains"
							showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false} showFilterMenu={false}
							filterElement={
								<MultiSelect value={selectedOrt} options={Ort} optionLabel="Ort" optionValue='Ort' onChange={onOrtChange} filter
										placeholder={"Alle"} />
							}/>
						}
						{selectedColumns.find((item) => item.field === "Telefon").visible === true &&
						<Column header="Telefon" field="Telefon" style={{width: "200px"}} filter sortable filterMatchMode="contains"
							showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false} showFilterMenu={false}
							filterElement={
								<MultiSelect value={selectedTelefon} options={Telefon} optionLabel="Telefon" optionValue='Telefon' onChange={onTelefonChange} filter
										placeholder={"Alle"} />
							}
							body={(row) => {
								let href = "tel:" + row.Telefon;
								return <a href={href}>{row.Telefon}</a>
							}}
							/>
						}
						{selectedColumns.find((item) => item.field === "Email").visible === true &&
						<Column header="E-Mail" field="Email" style={{width: "300px"}} filter sortable filterMatchMode="contains"
							showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false} showFilterMenu={false}
							filterElement={
								<MultiSelect value={selectedEmail} options={Email} optionLabel="Email" optionValue='Email' onChange={onEmailChange} filter
										placeholder={"Alle"} />
							}
							body={(row) => {
								let _mail = "mailto:" + row.Email;
								return <a href={_mail}>{row.Email}</a>
							}}
							/>
							
						}

						<Column header="Aktionen" frozen alignFrozen="right" body={rowData => <PopupMenu buttonClassName="p-button-secondary" label="Aktionen" icon="pi pi-chevron-down" model={[
							{ label: 'Bearbeiten', icon: 'pi pi-pencil', command: () => history.push("/mitarbeiter/bearbeiten/" + rowData.MitarbeiterNr) },
							{ label: 'Löschen', icon: 'pi pi-trash', command: () => onLoeschenClick(rowData) }
						]}/>} style={{width: '10em', textAlign: 'center'}}/>
					</DataTable>
				</div>
			</div>
			<Dialog header="Einstellungen" visible={einstellungenVisible} style={{ width: '15vw' }}
				footer={
					<div>
						<Button label="Schließen" icon="pi pi-check" onClick={e => {
							setEinstellungenVisble(false);
							saveKundenEinstellungen();
						}} />
					</div>
				}
				onHide={e => {
					setEinstellungenVisble(false);
					saveKundenEinstellungen()
					}}>
					<div className="grid">
						{columns?.map((col) => (
							<div className="col-12">
								<Checkbox key={col.field} value={col} checked={selectedColumns.find((item) => item.field === col.field)?.visible} className="mr-3"
								onChange={e => {
									let _columns = [...columns];
									_columns.forEach(x => {
										x.visible = selectedColumns.find((_x) => _x.field === x.field)?.visible
									});
									let column = _columns.find((item) => item.field === col.field);
									if (column !== undefined && column !== null)
										column.visible = e.checked;
									setSelectedColumns(_columns);
								}}/>
							{col.field}
							</div>
						))}
					</div>
			</Dialog>
		</React.Fragment>
	);
}