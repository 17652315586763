import React from 'react';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';

export default function EmailColumn(props) {

	const onMailChange = (e) => {
		props.setSelectedMail(e.target.value);
		props.datatableref.current.filter(e.target.value, "ErstePersonEmail", "in");
	}

	return (
		<Column header="E-Mail" field="ErstePersonEmail" style={{width: "200px"}} filter sortable filterMatchMode="contains"
		filterElement={
			<MultiSelect value={props.selectedMail} options={props.Mail} optionLabel="ErstePersonEmail" optionValue='ErstePersonEmail' onChange={onMailChange} filter
					placeholder={"Alle"} />
		}
		showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
		filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
		body = {props.body}
		/>
	)
}
