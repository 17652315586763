import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalState } from "./GlobalState";
import { MitarbeiterService } from "../service/MitarbeiterService";
import { useHistory, useParams } from "react-router-dom";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Password } from "primereact/password";
import { Calendar } from "primereact/calendar";
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Accordion, AccordionTab } from "primereact/accordion";
import { ColorPicker } from 'primereact/colorpicker';
import { addDE } from "./LocalDE";
import base64url from 'base64url';
import { FileUpload } from "primereact/fileupload";
import { Avatar } from 'primereact/avatar';
import { ImportService } from "../service/ImportService";
import CONFIG from 'config';
import { PLZOrtDropdown } from './PLZOrtDropdown';
import { Laenderdropdown } from "./Laenderdropdown";
import { Laender } from "./Laenderdropdown";

export default function Mitarbeiter(props) {
	const labelWidth = '12em';
	const inputWidth = '15em';

	const mitarbeiterService = new MitarbeiterService();
	const importService = new ImportService();

	const history = useHistory();
	const { mitarbeiterNr } = useParams();

	const [globalState, setGlobalState] = useContext(GlobalState);
	const [mitarbeiter, setMitarbeiter] = useState({
		Pos: 'Chef'
	});

	const [passwortBestaetingen, setPasswortBestaetingen] = useState();
	const [plzsuche, setPlzsuche] = useState(false);
	const [selectedPlz, setSelectedPlz] = useState();
	const [abteilungen, setAbteilungen] = useState();
	const [telefonValid, setTelefonValid] = useState();
	const [notfallValid, setNotfallValid] = useState();
	const [loading, setLoading] = useState(true);
	const [mitarbeiterEmailValid, setMitarbeiterEmailValid] = useState(true)
	const [avatar, setAvatar] = useState(undefined);
	const [selectedStandorte, setSelectedStandorte] = useState([]);
	const [oldEmail, setOldEmail] = useState();

	const toastRef = useRef(null);
	const avataruploadref = useRef(null);

	const emailRegex = /\S+@\S+\.\S+/;

	const CovidStatus = [
		'ungeimpft',
        'geimpft',
        'gesund',
        'genesen',
		'positiv'
    ];

	addDE();

	useEffect(() => {
		setGlobalState({...globalState, page: props.action === 'bearbeiten' ? 'Mitarbeiter > Bearbeiten' : 'Mitarbeiter > Anlegen'});
		if (props.action === 'bearbeiten') {
			mitarbeiterService.getMitarbeiter(mitarbeiterNr).then(data => {
				data.Geburtsdatum = data.Geburtsdatum === undefined || data.Geburtsdatum === null ? null : new Date(data.Geburtsdatum);
				data.Firmeneinstieg = data.Firmeneinstieg === undefined || data.Firmeneinstieg === null ? null : new Date(data.Firmeneinstieg);
				data.Firmenausstieg = data.Firmenausstieg === undefined || data.Firmenausstieg === null ? null : new Date(data.Firmenausstieg);
				data.KVEinstufung = data.KVEinstufung === undefined || data.KVEinstufung === null ? null : new Date(data.KVEinstufung);
				data.Vertragsverhandlung = data.Vertragsverhandlung === undefined || data.Vertragsverhandlung === null ? null : new Date(data.Vertragsverhandlung);
				data.CovidDatum = data.CovidDatum === undefined || data.CovidDatum === null ? null : new Date(data.CovidDatum);
				data.Farbe = data.Farbe === undefined ||data.Farbe === null ? 'ffffff' : data.Farbe 
				setSelectedStandorte(data.Standorte ?? []);
				setOldEmail(data.Email);
				data.Passwort = "";
				data.Land = Laender.find(x => x.name === data.Land);
				console.log(data);
				setMitarbeiter(data);
				setLoading(false);
			});
			mitarbeiterService.getImage(mitarbeiterNr).then(data => 
				setAvatar(URL.createObjectURL(data))
			);
		}
		else
		{
			setMitarbeiter({...mitarbeiter,
				Farbe: 'ffffff',
				SchuhGr: null,
				Land: {name: "AT - Österreich", code: "AT"}
			});
			setLoading(false);
		}
		//abteilungsService.getAll().then(data => setAbteilungen(data));
	}, []);

	useEffect(() => {
		if(emailRegex.test(mitarbeiter.Email)) {
			setMitarbeiterEmailValid(true);
		}
		else{
			setMitarbeiterEmailValid(false);
		}
	}, [mitarbeiter.Email])

	useEffect(() => {
		if (mitarbeiter.Telefon?.length === 0)
			return true;
		if(phoneno.test(mitarbeiter.Telefon)){
			setTelefonValid(true);
		} else {
			setTelefonValid(false);
		}
		
		if(phoneno.test(mitarbeiter.NotfallKontakt)){
			setNotfallValid(true);
		} else {
			setNotfallValid(false);
		}
		
	}, [mitarbeiter.Telefon, mitarbeiter.NotfallKontakt])

	const onSetPLZOrt = (e) => {
		setMitarbeiter({...mitarbeiter, Plz: e.target.value.PLZ, Ort: e.target.value.Ort});
	}
	const onSetPLZ = (e) => {
		setMitarbeiter({...mitarbeiter, Plz: e.target.value});
	}
	const onSetOrt = (e) => {
		setMitarbeiter({...mitarbeiter, Ort: e.target.value});
	}

	const onSpeichernClick = async () => {
		const mitarbeiterZumSpeichern = {...mitarbeiter};
		mitarbeiterZumSpeichern.Vorname = cleanString(mitarbeiterZumSpeichern.Vorname);
		mitarbeiterZumSpeichern.Nachname = cleanString(mitarbeiterZumSpeichern.Nachname);
		mitarbeiterZumSpeichern.Geburtsdatum = mitarbeiterZumSpeichern.Geburtsdatum ?? null;
		mitarbeiterZumSpeichern.Adresse = cleanString(mitarbeiterZumSpeichern.Adresse);
		mitarbeiterZumSpeichern.Ort = cleanString(mitarbeiterZumSpeichern.Ort);
		if (mitarbeiterZumSpeichern.Land !== undefined && mitarbeiterZumSpeichern.Land !== null)
			mitarbeiterZumSpeichern.Land = cleanString(mitarbeiterZumSpeichern.Land.name); 
		mitarbeiterZumSpeichern.Telefon = cleanString(mitarbeiterZumSpeichern.Telefon);
		mitarbeiterZumSpeichern.Firmeneinstieg = mitarbeiterZumSpeichern.Firmeneinstieg ?? null;
		mitarbeiterZumSpeichern.Firmenausstieg = mitarbeiterZumSpeichern.Firmenausstieg ?? null;
		mitarbeiterZumSpeichern.Austrittsgrund = cleanString(mitarbeiterZumSpeichern.Austrittsgrund);
		mitarbeiterZumSpeichern.Benutzername = cleanString(mitarbeiterZumSpeichern.Benutzername);
		mitarbeiterZumSpeichern.Passwort = (mitarbeiterZumSpeichern.Passwort ?? '') === '' ? null : mitarbeiterZumSpeichern.Passwort;
		mitarbeiterZumSpeichern.Email = cleanString(mitarbeiterZumSpeichern.Email);
		mitarbeiterZumSpeichern.TShirtgr = cleanString(mitarbeiter.TShirtgr);
		mitarbeiterZumSpeichern.NotfallKontakt = cleanString(mitarbeiter.NotfallKontakt);
		var now = new Date(); 
		var fifteenyearsago = now.setFullYear(now.getFullYear()-15)

		if (mitarbeiterZumSpeichern.Passwort !== ((passwortBestaetingen ?? '') === '' ? null : passwortBestaetingen)) {
			toastRef.current.show({ severity: "error", summary: "Der Benutzer konnte nicht angelegt werden", detail: "Die eingegebenen Passwörter stimmen nicht überein", life: 3000, closable: false });
			return;
		}

		if (mitarbeiterZumSpeichern.Geburtsdatum > fifteenyearsago)
		{
			toastRef.current.show({ severity: "error", summary: "Der Benutzer konnte nicht angelegt werden", detail: "Der Mitarbeiter muss min. 15 Jahre alt sein. Bitte Geburtsdatum ändern", life: 3000, closable: false });
			return;
		}
		if (mitarbeiterZumSpeichern.Benutzername === null || (props.action !== 'bearbeiten' && mitarbeiterZumSpeichern.Passwort === null) 
			) {
			toastRef.current.show({ severity: "error", summary: "Der Benutzer konnte nicht angelegt werden", detail: "Anmeldeinfos wurden nicht vollständig ausgefüllt.", life: 3000, closable: false });
			return;
		}

		if (mitarbeiterZumSpeichern.Vorname === undefined || mitarbeiterZumSpeichern.Vorname === null || mitarbeiterZumSpeichern.Vorname?.length === 0){
			toastRef.current.show({ severity: "error", summary: "Der Benutzer konnte nicht angelegt werden", detail: "Vorname wurde nicht ausgefüllt", life: 3000, closable: false });
			return;
		}

		if (mitarbeiterZumSpeichern.Nachname === undefined || mitarbeiterZumSpeichern.Nachname === null || mitarbeiterZumSpeichern.Nachname?.length === 0){
			toastRef.current.show({ severity: "error", summary: "Der Benutzer konnte nicht angelegt werden", detail: "Nachname wurde nicht ausgefüllt", life: 3000, closable: false });
			return;
		}

		if (mitarbeiterZumSpeichern.Geburtsdatum === undefined || mitarbeiterZumSpeichern.Geburtsdatum === null){
			toastRef.current.show({ severity: "error", summary: "Der Benutzer konnte nicht angelegt werden", detail: "Geburtsdatum wurde nicht ausgefüllt", life: 3000, closable: false });
			return;
		}

		if ((mitarbeiterZumSpeichern.Telefon === undefined || mitarbeiterZumSpeichern.Telefon === null || mitarbeiterZumSpeichern.Telefon?.length === 0) &&  (mitarbeiterZumSpeichern.Email === undefined || mitarbeiterZumSpeichern.Email === null || mitarbeiterZumSpeichern.Email?.length === 0 )) {
			toastRef.current.show({ severity: "error", summary: "Der Benutzer konnte nicht angelegt werden", detail: "Telefonnummer oder E-Mail wurde nicht ausgefüllt", life: 3000, closable: false });
			return 
		}
		
		if ( mitarbeiterZumSpeichern.NotfallKontakt === undefined || mitarbeiterZumSpeichern.NotfallKontakt === null || mitarbeiterZumSpeichern.NotfallKontakt?.length === 0 ) {
			toastRef.current.show({ severity: "error", summary: "Der Benutzer konnte nicht angelegt werden", detail: "Notfallkontakt wurde nicht ausgefüllt", life: 3000, closable: false });
			return;
		}

		if ( mitarbeiterZumSpeichern.Land === undefined || mitarbeiterZumSpeichern.Land === null || mitarbeiterZumSpeichern.Land?.length === 0 ) {
			toastRef.current.show({ severity: "error", summary: "Der Benutzer konnte nicht angelegt werden", detail: "Land wurde nicht ausgefüllt", life: 3000, closable: false });
			return;
		}

		if (mitarbeiterZumSpeichern.Passwort !== null) mitarbeiterZumSpeichern.Passwort = base64url.encode(await crypto.subtle.digest('SHA-512', new TextEncoder().encode(mitarbeiterZumSpeichern.Passwort)));

		importService.uploadFiles(avataruploadref.current?.files, CONFIG.ServerUrl + '/Mitarbeiter/' + mitarbeiter.MitarbeiterNr + '/Upload').then(
			() => {
				if (props.action === "neu")
				{
					mitarbeiterService.create(mitarbeiterZumSpeichern).then(async () => {
						toastRef.current.show({severity:'success', summary: 'Erfolgreich', detail:'Benutzer gespeichert.', life: 2000});
						setTimeout(() => history.goBack(), 1000);
					}).catch((e) => {
						console.log(e);
						toastRef.current.show({severity:'error', summary: 'Der Benutzer konnte nicht gespeichert werden', life: 5000});
					});
				}
				else
				{
					mitarbeiterService.mitarbeiterUpdate(mitarbeiterZumSpeichern).then(async () => {
						toastRef.current.show({severity:'success', summary: 'Erfolgreich', detail:'Benutzer gespeichert.', life: 2000});
						setTimeout(() => history.goBack(), 1000);
					}).catch((e) => {
						console.log(e);
						toastRef.current.show({severity:'error', summary: 'Der Benutzer konnte nicht gespeichert werden', detail:'Der Benutzername ist bereits vergeben.', life: 5000});
					});
				}
			}
		)
	};

	const onPlzSucheFooterClick = () => {
		console.log(selectedPlz);
		setMitarbeiter({...mitarbeiter, Ort: selectedPlz.ort, Plz: selectedPlz.plz});
		setPlzsuche(false);
	}

	const renderPlzSucheFooter = (e) => {
        return (
            <div>
                <Button label="Übernehmen" icon="pi pi-save" onClick={onPlzSucheFooterClick} className="p-button-text" />
            </div>
        );
    }


	const ortTemplate = (v) => {
		return (
				<div>
					<div>{v.plz} {v.ort}</div>
				</div>
			);
    }

	function cleanString(str) {
		if (typeof(str) !== 'string') return null;
		str = str?.trim();
		if (str === undefined || str === null || str === '') return null;
		return str;
	}

	const phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})([-. ]?([0-9]{2}))*$/

	return (
		<React.Fragment>
			<Toast ref={toastRef}/>
			<div className="p-component grid">
				<div className="col-12">
					<div className="field grid">
						<Button label="Speichern" icon="pi pi-save" style={{marginRight: '.5em'}} onClick={onSpeichernClick} />
						<Button label="Abbrechen" icon="pi pi-times" onClick={() => history.goBack()} />
					</div>
				</div>
				<div className="col-12">
					<Accordion activeIndex={0}>
						<AccordionTab header="Persönliche Daten">
							<div className="grid">
								<div className="col-12">
									<div className="field grid">
										<label htmlFor="vorname" className="col-fixed" style={{width: labelWidth}}>Vorname</label>
										<div className="col">
											<InputText value={mitarbeiter.Vorname} id="vorname" onChange={e => {
												setMitarbeiter({...mitarbeiter, Vorname: e.target.value, Benutzername: e.target.value + "." + mitarbeiter.Nachname ?? ""});	
											}}
											style={{width: inputWidth}}
											className={(!loading && (mitarbeiter.Vorname === undefined || mitarbeiter.Vorname === null || mitarbeiter.Vorname?.length === 0)) ? 'p-invalid' : ''}
											/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="nachname" className="col-fixed" style={{width: labelWidth}}>Nachname</label>
										<div className="col">
											<InputText value={mitarbeiter.Nachname} id="nachname" onChange={e => {
												setMitarbeiter({...mitarbeiter, Nachname: e.target.value, Benutzername: (mitarbeiter.Vorname ?? "") + "." + e.target.value})
											}}
											style={{width: inputWidth}}
											className={(!loading && (mitarbeiter.Nachname === undefined || mitarbeiter.Nachname === null || mitarbeiter.Nachname?.length === 0)) ? 'p-invalid' : ''}
											/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="geburtsdatum" className="col-fixed" style={{width: labelWidth}}>Geburtsdatum</label>
										<div className="col">
											<Calendar value={mitarbeiter.Geburtsdatum} monthNavigator={true} yearNavigator={true} yearRange={"1900:2030"}
											showIcon={true} locale="de" onChange={e => setMitarbeiter({...mitarbeiter, Geburtsdatum: e.target.value})} style={{width: inputWidth}}
											className={(!loading && (mitarbeiter.Geburtsdatum === undefined || mitarbeiter.Geburtsdatum === null)) ? 'p-invalid' : ''}
											/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="FamStand" className="col-fixed" style={{width: labelWidth}}>Familenstand</label>
										<div className="col">
											<Dropdown options={["ledig", "verheiratet", "in eingetragener Partnerschaft", "geschieden", "aufgelöste eingetragene Partnerschaft", "verwitwet", "hinterbliebener eingetragener Partner"]}
											value={mitarbeiter.FamStand} id="FamStand" type="text" style={{width: inputWidth}}
											onChange={e => setMitarbeiter({...mitarbeiter, FamStand: e.target.value})}
											/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="Kinder" className="col-fixed" style={{width: labelWidth}}>Kinder</label>
										<div className="col">
											<InputNumber inputId="Kinder" value={mitarbeiter.Kinder}
											inputStyle={{width: '10.5em', textAlign: 'center'}}
											onValueChange={e => setMitarbeiter({...mitarbeiter, Kinder: e.value})}
											showButtons buttonLayout="horizontal" step={1}
											decrementButtonClassName="p-button-danger"
											incrementButtonClassName="p-button-success"
											incrementButtonIcon="pi pi-plus"
											decrementButtonIcon="pi pi-minus"
											/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="covidstatus" className="col-fixed" style={{width: labelWidth}}>Covid Status</label>
										<div className="col-2">
											<Dropdown id="covidstatus" value={mitarbeiter.CovidStatus} options={CovidStatus} placeholder="Auswählen"
												style={{width: inputWidth}} onChange={e => setMitarbeiter({...mitarbeiter, CovidStatus: e.target.value})}
												/>
										</div>
										<div className="col-2">
											<Calendar inputId="coviddatum" className="p-ml-3 p-mt-0"
												value={mitarbeiter.CovidDatum } showIcon={true} locale="de" onChange={e => setMitarbeiter({...mitarbeiter, CovidDatum: e.target.value})}/>
										</div>
										<div className="col-8"></div>
									</div>
									<div className="field grid">
										<label htmlFor="schuhgr" className="col-fixed" style={{width: labelWidth}}>Schuhgröße</label>
										<div className="col">
											<InputNumber inputId="schuhgr" mode="decimal" value={mitarbeiter.SchuhGr} style={{width: inputWidth}} onValueChange={e => setMitarbeiter({...mitarbeiter, SchuhGr: e.value})}
											/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="tshirtgr" className="col-fixed" style={{width: labelWidth}}>T-Shirt Größe</label>
										<div className="col">
											<Dropdown value={mitarbeiter.TShirtgr} options={["XS", "S", "M", "L", "XL", "XXL", "XXXL", "XXXXL"]} id="tshirtgr" type="text" style={{width: inputWidth}} onChange={e => setMitarbeiter({...mitarbeiter, TShirtgr: e.target.value})}
											/>
										</div>
									</div>
								</div>
								<div className="col-12">
									<div className="field grid">
										<label htmlFor="avatar" className="col-fixed" style={{width: labelWidth}}>Bild</label>
										<div className="grid">
											<div className="col-8">
												<FileUpload style={{widht: 200}} 
												mode="basic"
												ref={avataruploadref} id="upload" name="avatarupload"
												url=""
												multiple={false} accept="image/*" maxFileSize={10000000}
												emptyTemplate={<p className="m-0">Bild hierhinzuiehen um hinzuzuüfgen.</p>}
												onSelect={e => {
													console.log(e.files);
													console.log(avataruploadref);
													if (avataruploadref.current?.files[0]?.objectURL !== undefined)
														setAvatar(avataruploadref.current.files[0].objectURL);
												}}
												cancelLabel="Abbrechen"
												chooseLabel="Auswählen"
											/>
											
										</div>
										<div className="col-4">
												<Avatar image={avatar} className="mr-2" size="xlarge" shape="circle" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</AccordionTab>
						<AccordionTab header="Kontakt">
							<div className="grid">
								<div className="col-12">
									<div className="field grid">
										<label htmlFor="telefon" className="col-fixed" style={{width: labelWidth}}>Telefon</label>
										<div className="col">
											<InputText value={mitarbeiter.Telefon} id="telefon" type="text"
											style={{width: inputWidth}} onChange={e => setMitarbeiter({...mitarbeiter, Telefon: e.target.value})}
											className={(telefonValid === false || mitarbeiter.Telefon === undefined || mitarbeiter.Telefon === null || mitarbeiter.Telefon?.length === 0) && (mitarbeiterEmailValid === false || mitarbeiter.Email === undefined || mitarbeiter.Email === null || mitarbeiter.Email?.length === 0) ? 'p-invalid' : ''}
											/>
											<span className='p-ml-1'>Format: +43 664 111 11 11</span>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="email" className="col-fixed" style={{width: labelWidth}}>E-Mail</label>
										<div className="col">
											<InputText value={mitarbeiter.Email} id="email" type="text" style={{width: inputWidth}} onChange={e => setMitarbeiter({...mitarbeiter, Email: e.target.value})} className={(telefonValid === false || mitarbeiter.Telefon === undefined || mitarbeiter.Telefon === null || mitarbeiter.Telefon?.length === 0) && (mitarbeiterEmailValid === false || mitarbeiter.Email === undefined || mitarbeiter.Email === null || mitarbeiter.Email?.length === 0) ? "p-invalid" : ""}/>
											<span className='p-ml-1'>Format: max@mustermann.at</span>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="notfallkontakt" className="col-fixed" style={{width: labelWidth}}>Notfallkontakt</label>
										<div className="col">
											<InputText value={mitarbeiter.NotfallKontakt} id="notfallkontakt" type="text" style={{width: inputWidth}} onChange={e => setMitarbeiter({...mitarbeiter, NotfallKontakt: e.target.value})} className={notfallValid === false || mitarbeiter.NotfallKontakt === undefined || mitarbeiter.NotfallKontakt === null || mitarbeiter.NotfallKontakt?.length === 0 ? "p-invalid" : ""}/>
											<span className='p-ml-1'>Format: +43 664 111 11 11</span>
										</div>
									</div>
								</div>
							</div>
						</AccordionTab>
						<AccordionTab header="Anmeldeinfos">
							<div className="grid">
								<div className="col-12">
									<div className="field grid">
										<label htmlFor="benutzername" className="col-fixed" style={{width: labelWidth}}>Benutzername</label>
										<div className="col">
											<InputText value={mitarbeiter.Benutzername} id="benutzername" onChange={e => setMitarbeiter({...mitarbeiter, Benutzername: e.target.value})} className={mitarbeiter.Benutzername === undefined || mitarbeiter.Benutzername === null || mitarbeiter.Benutzername === '' ? 'p-invalid' : ''} style={{width: inputWidth}}/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="passwort" className="col-fixed" style={{width: labelWidth}}>Passwort:</label>
										<div className="col">
											<Password inputId="passwort" value={mitarbeiter.Passwort} onChange={e => setMitarbeiter({...mitarbeiter, Passwort: e.target.value})} toggleMask className={props.action !== 'bearbeiten' && (mitarbeiter.Passwort === undefined || mitarbeiter.Passwort === null || mitarbeiter.Passwort === '') ? 'p-invalid' : ''} inputStyle={{width: inputWidth}}/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="passwortBestaetingen" className="col-fixed" style={{width: labelWidth}}>Passwort bestätigen:</label>
										<div className="col">
											<Password inputId="passwortBestaetingen" value={passwortBestaetingen} onChange={e => setPasswortBestaetingen(e.target.value)} toggleMask className={props.action !== 'bearbeiten' && (passwortBestaetingen === undefined || passwortBestaetingen === null || passwortBestaetingen === '') ? 'p-invalid' : ''} inputStyle={{width: inputWidth}}/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="berechtigungen" className="col-fixed" style={{width: labelWidth}}>Berechtigungen</label>
										<div className="col">
											<Dropdown inputId="berechtigungen" value={mitarbeiter.Pos} onChange={e => setMitarbeiter({...mitarbeiter, Pos: e.target.value})}
												options={['Chef', 'Buchhalter', 'Verkäufer', 'Service', "Innendienst", "Monteur"]}
												style={{width: inputWidth}}/>
										</div>
									</div>
								</div>
							</div>
						</AccordionTab>
						<AccordionTab header="Firmendaten">
							<div className="field grid">
								<label htmlFor="firmeneinstieg" className="col-fixed" style={{width: labelWidth}}>Firmeneinstieg</label>
								<div className="col">
									<Calendar inputId="firmeneinstieg" value={mitarbeiter.Firmeneinstieg} showIcon={true} locale="de" onChange={e => setMitarbeiter({...mitarbeiter, Firmeneinstieg: e.target.value})} style={{width: inputWidth}}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="firmenausstieg" className="col-fixed" style={{width: labelWidth}}>Firmenausstieg</label>
								<div className="col">
									<Calendar inputId="firmenausstieg" value={mitarbeiter.Firmenausstieg} showIcon={true} locale="de" onChange={e => setMitarbeiter({...mitarbeiter, Firmenausstieg: e.target.value})} style={{width: inputWidth}}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="austrittsgrund" className="col-fixed" style={{width: labelWidth}}>Austrittsgrund</label>
								<div className="col">
									<InputText value={mitarbeiter.Austrittsgrund} id="austrittsgrund" type="text" style={{width: inputWidth}} onChange={e => setMitarbeiter({...mitarbeiter, Austrittsgrund: e.target.value})}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="svnr" className="col-fixed" style={{width: labelWidth}}>SVNr</label>
								<div className="col">
								<InputNumber value={mitarbeiter.SVNr} id="svnr" type="text" style={{width: inputWidth}} onChange={e => setMitarbeiter({...mitarbeiter, SVNr: e.value})} useGrouping={false} max={9999999999} className={mitarbeiter.SVNr == 9999999999 ? "p-invalid": ""}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="gehaltdV" className="col-fixed" style={{width: labelWidth}}>Gehalt lt. Dienstvertrag</label>
								<div className="col">
									<InputNumber inputId="gehaltdV" mode="currency" currency="EUR" locale="de-DE" value={mitarbeiter.GehaltDV} style={{width: inputWidth}} onValueChange={e => setMitarbeiter({...mitarbeiter, GehaltDV: e.value})}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="vertragsverhandlung " className="col-fixed" style={{width: labelWidth}}>Vertragsverhandlung</label>
								<div className="col">
									<Calendar inputId="vertragsverhandlung"
									value={mitarbeiter.Vertragsverhandlung } showIcon={true} locale="de" onChange={e => setMitarbeiter({...mitarbeiter, Vertragsverhandlung: e.target.value})}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="kveinstufung " className="col-fixed" style={{width: labelWidth}}>Datum KV Einstufung</label>
								<div className="col">
									<Calendar inputId="kveinstufung "
									value={mitarbeiter.KVEinstufung } showIcon={true} locale="de" onChange={e => setMitarbeiter({...mitarbeiter, KVEinstufung: e.target.value})}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="gehaltnetto" className="col-fixed" style={{width: labelWidth}}>Gehalt Netto</label>
								<div className="col">
									<InputNumber inputId="gehaltnetto" mode="currency" currency="EUR" locale="de-DE" value={mitarbeiter.GehaltNetto} style={{width: inputWidth}} onValueChange={e => setMitarbeiter({...mitarbeiter, GehaltNetto: e.value})}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="abteilung" className="col-fixed" style={{width: labelWidth}}>Abteilung</label>
								<div className="col">
									<Dropdown value={mitarbeiter.Abteilung} options={abteilungen}
									label=""
									id="abteilung" type="text" style={{width: inputWidth}} onChange={e => setMitarbeiter({...mitarbeiter, SVNr: e.target.value})}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="farbe" className="col-fixed" style={{width: labelWidth}}>Farbe</label>
								<div className="col">
									<ColorPicker id="farbe" value={mitarbeiter.Farbe} onChange={e => setMitarbeiter({...mitarbeiter, Farbe: e.value})}></ColorPicker>
								</div>
							</div>
						</AccordionTab>
						<AccordionTab header="Adresse">
							<div className="field grid">
								<label htmlFor="strasse" className="col-fixed" style={{width: labelWidth}}>Straße</label>
								<div className="col">
									<InputText value={mitarbeiter.Adresse} id="strasse" type="text" style={{width: "calc("+labelWidth+"/ 0.768"}} onChange={e => setMitarbeiter({...mitarbeiter, Adresse: e.target.value})}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="plz" className="col-fixed" style={{width: 'calc(' + labelWidth + ' / 4)'}}>PLZ</label>
								<label htmlFor="ort" className="col-fixed" style={{width: 'calc((' + labelWidth + ' / 4) * 3)'}}>Ort</label>
								<div className="col">
								<PLZOrtDropdown plzvalue={mitarbeiter.Plz} ortvalue={mitarbeiter.Ort} Land={mitarbeiter.Land}
									setPLZOrt={onSetPLZOrt} setPLZ={onSetPLZ} setOrt={onSetOrt}></PLZOrtDropdown>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="reland" className="col-fixed" style={{width: labelWidth}}>Land</label>
								<div className="col">
									<Laenderdropdown Land={mitarbeiter.Land} onChange={(e) => setMitarbeiter({...mitarbeiter, Land: e.target.value})}></Laenderdropdown>
								</div>
							</div>
						</AccordionTab>
						<AccordionTab header="Sonstiges">
						<div className="grid">
							<div className="col-12">
								<div className="field grid">
									<label htmlFor="bemerkung" className="col-fixed" style={{width: labelWidth}}>Bemerkung</label>
									<div className="col">
										<InputTextarea value={mitarbeiter.Bemerkung}
											id="bemerkung" type="text" style={{width: "30em", height: "15em"}} 
											onChange={e => setMitarbeiter({...mitarbeiter, Bemerkung: e.target.value})}/>
									</div>
								</div>
							</div>
						</div>
						</AccordionTab>
					</Accordion>					
				</div>
			</div>
		</React.Fragment>
	);
}