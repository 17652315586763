import React from 'react';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';

export default function KundentypColumn(props) {

	const KundenTypen = [{KundenTyp: "Privat", Value: 0},
						 {KundenTyp: "mehrere Privat", Value: 2},
						 {KundenTyp: "Firma", Value: 1}];

	const onKundenTypChange = (e) => {
		props.setSelectedKundenTyp(e.target.value);
		props.datatableref.current.filter(e.target.value, "KundenArt", "in");
	}

	const KundentypBodyTemplate = (rowData) => { 
		if(rowData.KundenArt === 2){
			return(
				<div className='flex align-items-stretch flex-wrap w-12'>
					<div className={"flex align-items-center justify-content-center w-12 pi pi-users"}></div>
				</div>
			) 
		}
		else if(rowData.KundenArt === 0){ 
			return(
				<div className='flex align-items-stretch flex-wrap w-12'>
					<div className={"flex align-items-center justify-content-center w-12 pi pi-user"}></div>
				</div>
			)
		}
		else{
			return(
				<div className='flex align-items-stretch flex-wrap w-12'>
					<div className={"flex align-items-center justify-content-center w-12 pi pi-building"}></div>
				</div>
			)
		}
	}

	const KundentypFilterTemplate = (rowData) => {
		console.log(rowData); 
		if (rowData === undefined){
			return;
		}
		else{
		return(<div><i className={rowData.KundenTyp === "mehrere Privat" ? "pi pi-users" : rowData.KundenTyp === "Privat" ? "pi pi-user" : "pi pi-building"} /><span className='ml-2'>{rowData.KundenTyp}</span></div>);
		}
	}

	const selectedKundentypFilterTemplate = (rowData) => { 
		if(rowData === undefined){
			return;
		}
		else if (rowData === 0){
			return(<div className='pi pi-user'> Privat</div>)
		}
		else if (rowData === 1){
			return(<div className='pi pi-building'> Firma</div>)
		}
		else if (rowData === 2){
			return(<div className='pi pi-users'> mehrere Privat</div>)
		}
	}

	return(
		<Column header="Typ" field="KundenArt" style={{width: "150px"}} filter sortable filterMatchMode="contains" body={KundentypBodyTemplate}
		filterElement={
			<MultiSelect value={props.selectedKundenTyp} options={KundenTypen} optionLabel="KundenTyp" optionValue="Value"
				placeholder={"Alle"} 
				onChange={onKundenTypChange} itemTemplate={KundentypFilterTemplate} selectedItemTemplate={selectedKundentypFilterTemplate}/>
		}
		showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
		filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
		/>
	)
}