import axios from 'axios';
import { GetAxiosConfig } from './AuthService';
import CONFIG from 'config';

export class LieferantenService {
    async getAll() {
        return axios.get(CONFIG.ServerUrl + '/Lieferanten', await GetAxiosConfig()).then(res => res.data);
    }

    async getLieferantFromId(id) {
        return axios.get(CONFIG.ServerUrl + '/Lieferant/' + id, await GetAxiosConfig()).then(res => res.data);
    }

    async getLieferantFromName(name) {
        return axios.get(CONFIG.ServerUrl + '/Lieferant/Name/' + name, await GetAxiosConfig()).then(res => res.data);
    }

    async set(lieferant) {
        return axios.post(CONFIG.ServerUrl + '/Lieferant/Set', lieferant, await GetAxiosConfig()).then(res => res.data);
    }

    async delete(id, targetId) {
        return axios.get(CONFIG.ServerUrl + '/Lieferant/Delete/' + id + '/' + targetId, await GetAxiosConfig()).then(res => res.data);
    }
}