export function fetchSimple(url, jwt, postData) {
	return new Promise((resolve, reject) => {
		const headers = {};
		if (jwt !== undefined && jwt !== null) headers["Authorization"] = "Bearer " + jwt;
		if (postData === undefined) {
			fetch(url, {
				method: "GET",
				headers: headers
			}).then(response => {
				if (response.ok) return resolve(response);
				else return reject(response);
			}).catch(e => reject(e));
		} else {
			if (!(postData instanceof FormData)) headers["Content-Type"] = "application/json";
			fetch(url, {
				method: "POST",
				headers: headers,
				body: postData instanceof FormData ? postData : JSON.stringify(postData)
			}).then(response => {
				if (response.ok) return resolve(response);
				else return reject(response);
			}).catch(e => reject(e));
		}
	});
}