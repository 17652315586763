import base64url from "base64url";
import CONFIG from "config";
import { fetchSimple } from "../helper/fetchSimple";
let timeout = {};

async function RenewToken(oldToken, url) {
	const response = await fetch(url + "/RenewToken", {
		headers: {
			"Authorization": "Bearer " + oldToken
		}
	});
	if (response.ok) {
		SetToken(await response.text(), url);
	} else {
		sessionStorage.removeItem(url + "_jwt");
		await Login(url);
	}
}

function SetToken(token, url) {
	if(token === undefined || token === null || token === "") return;
	const timeLeft = GetTimeLeft(token);
	if (timeLeft > 0) {
		sessionStorage.setItem(url + "_jwt", token);
		clearTimeout(timeout[url]);
		timeout[url] = setTimeout(() => {
			RenewToken(token, url);
		}, timeLeft);
	}
}

function GetPayload(token) {
	return JSON.parse(base64url.decode(token.split(".")[1]));
}

function GetTimeLeft(token) {
	return (GetPayload(token).exp - 10) * 1000 - new Date().getTime();
}

async function Login(url) {
	const username = sessionStorage.getItem("username");
	const password = sessionStorage.getItem("password");
	
	if (username === undefined || username === null || password === undefined || password === null) return null;

	const response = await fetch(url + "/Login", {
		method: "POST",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify({username: username, password: password})
	});
	if (response.ok) {
		SetToken(await response.text(), url);
	} else if (response.status === 429) {
		throw new Error("Zu viele Login versuche");
	}
	return response;
}

export async function GetToken(url) {
	const jwt = sessionStorage.getItem(url + "_jwt");
	if (jwt !== undefined && jwt !== null && GetTimeLeft(jwt) > 0) return jwt;
	if((await Login(url))?.ok) return sessionStorage.getItem(url + "_jwt");
	return null;
}

export async function GetAxiosConfig(url) {
	const jwt = await GetToken(url);
	if (jwt === null) return {};
	return {
		headers: {
			"Authorization": "Bearer " + jwt
		}
	};
}

export class AuthService {
	async login(username, password) {
		const hash = await crypto.subtle.digest("SHA-512", new TextEncoder().encode(password))
		const base64 = base64url.encode(hash);
		sessionStorage.setItem("username", username);
		sessionStorage.setItem("password", base64);
		try {
			if (!(await Login(CONFIG.ServerUrl)).ok) {
				sessionStorage.removeItem("username");
				sessionStorage.removeItem("password");
				throw new Error("Login Fehlgeschlagen");
			} else {
				const mitarbeiter = await fetchSimple(CONFIG.ServerUrl + "/Mitarbeiter/Benutzername/" + username, await GetToken(CONFIG.ServerUrl)).then(res => res.json());
				sessionStorage.setItem("mitarbeiter", JSON.stringify(mitarbeiter));
			}
		} catch (e) {
			sessionStorage.removeItem("username");
			sessionStorage.removeItem("password");
			throw e;
		}
		
	}

	logout() {
		sessionStorage.removeItem("username");
		sessionStorage.removeItem("password");
		sessionStorage.removeItem(CONFIG.ServerUrl + "_jwt");
	}

	isLogedIn() {
		const jwt = sessionStorage.getItem(CONFIG.ServerUrl + "_jwt");
		if (jwt !== undefined && jwt !== null && GetTimeLeft(jwt) > 0) return true;

		const username = sessionStorage.getItem("username");
		const password = sessionStorage.getItem("password");
		return username !== undefined && username !== null && password !== undefined && password !== null;
	}
}