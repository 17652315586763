export function formatDate(inputDate)
{
    if (inputDate === undefined || inputDate === null)
        return "";
    var date = inputDate.getDate();
    var month = inputDate.getMonth() + 1; // take care of the month's number here ⚠️
    var year = inputDate.getFullYear();

    if (date < 10) {
        date = '0' + date;
    }
    
    if (month < 10) {
        month = '0' + month;
    }

    date = date.toString().padStart(2, '0');
    month = month.toString().padStart(2, '0');
    return `${date}.${month}.${year}`;
}