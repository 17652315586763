import { GetToken } from './AuthService';
import { fetchSimple } from "../helper/fetchSimple";
import CONFIG from 'config';

export class AngebotskundenService {
	async getAll() {
		return fetchSimple(CONFIG.ServerUrl + "/AngebotsKunden", await GetToken(CONFIG.ServerUrl)).then(res => res.json());
	}

	async getFromId(id) {
		return fetchSimple(CONFIG.ServerUrl + "/AngebotsKunden/" + id, await GetToken(CONFIG.ServerUrl)).then(res => res.json());
	}

	async create(kunde) {
		return fetchSimple(CONFIG.ServerUrl + "/AngebotsKunden/Create", await GetToken(CONFIG.ServerUrl), kunde).then(response => response);
	}

	async delete(id) {
		return fetchSimple(CONFIG.ServerUrl + "/AngebotsKunden/Delete/" + id, await GetToken(CONFIG.ServerUrl)).then(res => res);
	}

	async deactivate(id) {
		return fetchSimple(CONFIG.ServerUrl + "/AngebotsKunden/Deactivate/" + id, await GetToken(CONFIG.ServerUrl)).then(res => res);
	}
	async getNeueKundennummer() {
		return fetchSimple(CONFIG.ServerUrl + "/AngebotsKunden/NeueKundennummer/", await GetToken(CONFIG.ServerUrl)).then(res => res.json());
	}
}