import { GetToken } from './AuthService';
import { fetchSimple } from "../helper/fetchSimple";
import CONFIG from 'config';

export class AngebotService {
	async getAll() {
		return fetchSimple(CONFIG.ServerUrl + "/Angebote", await GetToken(CONFIG.ServerUrl)).then(res => res.json());
	}

	async getFromId(id) {
		return fetchSimple(CONFIG.ServerUrl + "/Angebote/" + id, await GetToken(CONFIG.ServerUrl)).then(res => res.json());
	}

	async create(kunde) {
		return fetchSimple(CONFIG.ServerUrl + "/Angebot/Create", await GetToken(CONFIG.ServerUrl), kunde).then(response => response);
	}

	async update(angebot) {
		return fetchSimple(CONFIG.ServerUrl + "/Angebot/Update", await GetToken(CONFIG.ServerUrl), angebot).then(res => res.json());
	}

	async delete(id) {
		return fetchSimple(CONFIG.ServerUrl + "/Angebote/Delete/" + id, await GetToken(CONFIG.ServerUrl)).then(res => res);
	}

	async getDashboard() {
		return fetchSimple(CONFIG.ServerUrl + "/Angebote/Dashboard", await GetToken(CONFIG.ServerUrl)).then(res => res.json());
	}

	async getPlzOrte() {
		return fetchSimple(CONFIG.ServerUrl + "/Angebote/PlzOrte", await GetToken(CONFIG.ServerUrl)).then(res => res.json());
	}

	async getBaufirmen() {
		return fetchSimple(CONFIG.ServerUrl + "/Angebote/Baufirmen", await GetToken(CONFIG.ServerUrl)).then(res => res.json());
	}

	async verkaufe(id) {
		return fetchSimple(CONFIG.ServerUrl + "/Angebot/Verkaufe/", await GetToken(CONFIG.ServerUrl), id).then(res => res);
	}

	async getNextID(id) {
		return fetchSimple(CONFIG.ServerUrl + "/Angebote/NextID", await GetToken(CONFIG.ServerUrl)).then(res => res.json());
	}
}