import React, { useState, useEffect, useContext} from 'react';
import { GlobalState } from './GlobalState';
import { Card } from "primereact/card";
import { AngebotService } from '../service/Angebotsservice';
import { useHistory } from 'react-router-dom';

function Dashboard(props) {
	const [globalState, setGlobalState] = useContext(GlobalState);
	const [angebote, setAngebote] = useState();
	const angebotService = new AngebotService();

	const history = useHistory();
	useEffect(() => {
		setGlobalState({...globalState, page: 'Dashboard'});
		angebotService.getDashboard().then(data => setAngebote(data));
	}, []);

	let euroGerman = Intl.NumberFormat("de-DE", {
		style: "currency",
		currency: "EUR",
	});

	return(
		<React.Fragment>
			<div className="grid">
				<div className="col-12 md:col-6 lg:col-4 p-1" style={{position: "relative"}}>
					<Card style={{height: 300}}>
						<h1 >Offene Angebote</h1>
						<div className='grid'>
							<div className='col-6'>
								Anzahl: <span onClick={() => history.push("/angebote/Status/Offen")} style={{cursor: "pointer", textDecoration: "underline", color: "blue"}}>{angebote?.offeneAngebote}</span>
								<br />
								Summe: {euroGerman.format(angebote?.offeneAngeboteVKnetto)}
							</div>
						</div>
						<h1 >Angenommene Angebote</h1>
						<div className='grid'>
							<div className='col-12'>
								Anzahl: <span onClick={() => history.push("/angebote/Status/Angenommen")} style={{cursor: "pointer", textDecoration: "underline", color: "blue"}}>{angebote?.angenommeneAngebote}</span>
								<br />
								Summe: {euroGerman.format(angebote?.angenommeneAngeboteVKnetto)}
							</div>
						</div>
						<h1 >Abgeschlossene Angebote</h1>
						<div className='grid'>
							<div className='col-12'>
								Anzahl: <span onClick={() => history.push("/angebote/Status/Verkauft")} style={{cursor: "pointer", textDecoration: "underline", color: "blue"}}>{angebote?.abgeschlosseneAngebote}</span>
								<br />
								Summe: {euroGerman.format(angebote?.abgeschlosseneAngeboteVKnetto)}
							</div>
						</div>
					</Card>
				</div>
				<div className="col-12 md:col-6 lg:col-4 p-1" style={{position: "relative"}}>
					<Card style={{height: 300}}>
						<h1>Verkäufe</h1>
					</Card>
				</div>
				<div className="col-12 md:col-6 lg:col-4 p-1" style={{position: "relative"}}>
					<Card style={{height: 300}}>
						<h1>Umsätze</h1>
					</Card>
				</div>
			</div>
		</React.Fragment>
	);
}

export default Dashboard;