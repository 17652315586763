import React, {useContext, useEffect} from 'react';
import { GlobalState } from './GlobalState';

export default function Einstellungen(props)
{
	const [globalState, setGlobalState] = useContext(GlobalState);

    useEffect(() => {
		setGlobalState({...globalState, page: 'Einstellungen'});
    }, [])

    return (
        <div className="p-component grid">
             <div className="col-12">
                <a href='/downloads/localdir.reg'>Registry Eintrag für Explorer herunterladen</a>
            </div>
        </div>
    );
}