import { Dropdown } from 'primereact/dropdown';
import React, { useRef, useState, useEffect } from 'react';
import Papa from 'papaparse'

export function PLZOrtDropdown(props) {
	const replzref = useRef(null);
	const [plzoptions, setPlzoptions] = useState([]);
	const [deOptions, setDeOptions] = useState([]);
	const [deOptionsLoaded, setDeOptionsLoaded] = useState(false);
	const [atOptions, setAtOptions] = useState([]);
	const [atOptionsLoaded, setAtOptionsLoaded] = useState(false);
	const [czOptions, setCZOptions] = useState([]);
	const [czOptionsLoaded, setCZOptionsLoaded] = useState(false);
	const [plz, setPlz] = useState([]);

	const getDEData = (setData) => {
		Papa.parse("assets/PLZ_2021.csv",
		{
			download: true ,
			skipEmptyLines: true,
			header: false,
			complete: function(results) {
				let _data = new Array();
				results.data.forEach(x => {
					_data.push({
						PLZ: x[0],
						Ort: x[1] + ((x[2]?.length > 0) ? " " : "") + x[2]
					});
				});
				setDeOptions(_data);
				setDeOptionsLoaded(true);
				if (setData === true)
					setPlz(_data);
			}
		});
	}


	const getATData = (setData) =>{
		fetch('assets/plz.json'
		,{
		  headers : { 
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		   }
		}).then(function(response){
			return response.json();
		  }) .then(function(myJson) {
			let _data = new Array();
			myJson.forEach(e =>
			{
				let ortschaften = e.Ortschaften.split(',');
				ortschaften.forEach(o => {
					_data.push({
						Gültigkeitsbeginn: e.Gültigkeitsbeginn,
						Gültigkeitsende: e.Gültigkeitsende,
						PLZ: e.PLZ,
						Ort: o.trim()
					});
				});
			});
			setAtOptions(_data);
			setAtOptionsLoaded(true);
			if (setData === true)
				setPlz(_data);
		  });
	}
	
	const getCZData = (setData) => {
		Papa.parse("assets/zipcodes.cz.csv",
		{
			download: true ,
			skipEmptyLines: true,
			header: false,
			complete: function(results) {
				let _data = new Array();
				results.data.forEach(x => {
					_data.push({
						PLZ: x[1].replace(/\s+/g,''),
						Ort: x[2],
					});
				});
				console.log("Finished:", _data);
				setCZOptions(_data);
				setCZOptionsLoaded(true);
				if (setData === true)
					setPlz(_data);
			}
		});
	}
	  
	const ortTemplate = (v) => {
		return (
			<div>
				<div>{v.PLZ} {v.Ort}</div>
			</div>
		);
    }

	useEffect(() => {
		if (props.Land?.code === "AT")
		{
			if (atOptionsLoaded === true)
				setPlz(atOptions);
			else
				getATData(true);
		}
		else if (props.Land?.code === "DE")
		{
			if (deOptionsLoaded === true)
				setPlz(deOptions);
			else
				getDEData(true);
		}
		else if (props.Land?.code === "CZ")
		{
			if (czOptionsLoaded === true)
				setPlz(czOptions);
			else
				getCZData(true);
		}
	}, [props.Land])

	function isPlausible(_plz, _ort)
	{
		if (props.setPlausible === undefined || props.setPlausible === null)
			return;
		var _option = plz.find(x => x.PLZ === _plz);
		if (_option === undefined)
			props.setPlausible(false);
		else if (_option.Ort === _ort.trim())
			props.setPlausible(true);
		else
			props.setPlausible(false);
	}

	return (
		<div>
			<Dropdown ref={replzref} editable value={props.plzvalue} options={plzoptions} optionLabel="PLZ" id="plz" type="text" style={{width: '7em'}}
				tabIndex={-1}
				onChange={e => {
					if (e?.target?.value?.length >= 2) {
						setPlzoptions(plz.filter(x => x.PLZ.toString().startsWith(e.target.value)));
						replzref.current.state.overlayVisible = true;
					}
					else
					{
						setPlzoptions([]);
						replzref.current.state.overlayVisible = false;
					}
					if (e.target.value.PLZ !== undefined)
					{
						props.setPLZOrt(e); 
						isPlausible(e.target.value.PLZ, e.target.value.Ort);
					}
					else
					{
						props.setPLZ(e);
						isPlausible(e.target.value, props.ortvalue);
					}
				}}
				className={(props.plzvalue === undefined || props.plzvalue === null || props.plzvalue.length === 0)?'p-invalid' : ''}
			itemTemplate={ortTemplate}
			/>

			<Dropdown ref={replzref} editable value={props.ortvalue} options={plzoptions} optionLabel="PLZ" id="plz" type="text" style={{marginLeft: "10px", width: '13em'}}
				tabIndex={-1}
				onChange={e => {
					if (e?.target?.value?.length >= 2) {
						setPlzoptions(plz.filter(x => x.Ort.toString().startsWith(e.target.value)));
						replzref.current.state.overlayVisible = true;
					}
					else
					{
						setPlzoptions([]);
						replzref.current.state.overlayVisible = false;
					}
					if (e.target.value.PLZ !== undefined)
					{
						console.log(e);
						props.setPLZOrt(e);
						isPlausible(e.target.value.PLZ, e.target.value.Ort);
					} else
					{
						props.setOrt(e);
						isPlausible(props.plzvalue, e.target.value);
					}
				}}
				className={(props.ortvalue === undefined || props.ortvalue === null || props.ortvalue.length === 0)?'p-invalid' : ''}
			itemTemplate={ortTemplate}
			/>
		</div>
	)
}