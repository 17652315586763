import React, { useRef, useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GlobalState } from './GlobalState';
import { KundenService } from '../service/KundenService';
import { MitarbeiterService } from '../service/MitarbeiterService';
import CONFIG from 'config';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import { AutoComplete } from 'primereact/autocomplete';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { addDE } from './LocalDE';
import { DataTable } from 'primereact/datatable';
import { Column } from "primereact/column";
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { PopupMenu } from './PopupMenu';
import { UIDService } from '../service/UIDService';
import { FirmenbuchService } from '../service/FirmenbuchService';
import { Laenderdropdown } from "./Laenderdropdown";
import { PLZOrtDropdown } from "./PLZOrtDropdown";
import { Laender } from "./Laenderdropdown";
import { flagTemplate } from './Laenderdropdown';
import VerkäuferTemplate from './Verkäufer';
import { clean } from 'semver';
import { AngebotService } from '../service/Angebotsservice';
import { AngebotskundenService } from '../service/AngebotskundenService';
import { AnsprechpersonenService } from '../service/AnsprechpersonenService';

export default function Kunde(props) {
	const labelWidth = '10em';

	const kundenService = new KundenService();
	const angebotskundenService = new AngebotskundenService();
	const mitarbeiterService = new MitarbeiterService();
	const uidservice = new UIDService();
	const firmenbuchService = new FirmenbuchService();
	const ansprechpersonenService = new AnsprechpersonenService();
	const history = useHistory();
	const { id } = useParams();
	const toastRef = useRef(null);
	const plzref = useRef(null);
	const erstepersonmobilref = useRef(null);
	const erstepersontelefonref = useRef(null);
	const zweitepersonmobilref = useRef(null);
	const zweitepersontelefonref = useRef(null);
	const ansprechpersonmobilref = useRef(null);
	const ansprechpersontelefonref = useRef(null);

	const [globalState, setGlobalState] = useContext(GlobalState);
	const [kunde, setKunde] = useState({});
	const [ansprechperson, setAnsprechperson] = useState({});
	const [abteilungen, setAbteilungen] = useState([]);
	const [filteredAbreilungen, setFilteredAbteilungen] = useState([]);
	const [verkäufer, setVerkäufer] = useState([]);
	const [displayBst, setDisplayBst] = useState(false);
	const [displayBstEdit, setDisplayBstEdit] = useState(false);
	const [derzeitigerBenutzer, setDerzeitigerBenutzer] = useState();
	const [uidValidationError, setUidValidationError] = useState("notchecked");
	const [fnValidationError, setFnValidationError] = useState("notchecked")
	const [uidLoading, setUidLoading] = useState(false);
	const [firmenbuchloading, setFirmenbuchloading] = useState(false);
	const [ErstePersonMobilSelectionStart, setErstePersonMobilSelectionStart] = useState();
	const [ErstePersonTelefonSelectionStart, setErstePersonTelefonSelectionStart] = useState();
	const [ZweitePersonMobilSelectionStart, setZweitePersonMobilSelectionStart] = useState();
	const [ZweitePersonTelefonSelectionStart, setZweitePersonTelefonSelectionStart] = useState();
	const [AnsprechPersonMobilSelectionStart, setAnsprechPersonMobilSeletionStart] = useState();
	const [AnsprechPersonTelefonSelectionStart, setAnsprechPersonTelefonSelectionStart] = useState();

	const [selectedVorwahlErstePersonTelefon, setSelectedVorwahlErstePersonTelefon] = useState("+43"); 
	const [selectedVorwahlErstePersonMobil, setSelectedVorwahlErstePersonMobil] = useState("+43");
	const [selectedVorwahlZweitePersonMobil, setSelectedVorwahlZweitePersonMobil] = useState("+43");
	const [selectedVorwahlZweitePersonTelefon, setSelectedVorwahlZweitePersonTelefon] = useState("+43");
	const [selectedVorwahlAnsprechPersonTelefon, setSelectedVorwahlAnsprechPersonTelefon] = useState("+43");
	const [selectedVorwahlAnsprechPersonMobil, setSelectedVorwahlAnsprechPersonMobil] = useState("+43");


	const [erstePersonEmailValid, setErstePersonEmailValid] = useState(true);
	const [zweitePersonEmailValid, setZweitePersonEmailValid] = useState(true);
	const [ansprechPersonEmailValid, setAnsprechPersonEmailValid] = useState(true);

	const [erstePersonMobilValid, setErstePersonMobilValid] = useState(false);
	const [zweitePersonMobilValid, setZweitePersonMobilValid] = useState(false);
	const [ansprechPersonMobilValid, setAnsprechPersonMobilValid] = useState(false);

	const [erstePersonTelefonValid, setErstePersonTelefonValid] = useState(false);
	const [zweitePersonTelefonValid, setZweitePersonTelefonValid] = useState(false);
	const [ansprechPersonTelefonValid, setAnsprechPersonTelefonValid] = useState(false);

	const [bstNeuPlausible, setBstNeuPlausible] = useState(true);
	const [bsteditPlausible, setBsteditPlausible] = useState(true);
	const [addBstPlausible, setAddBstPlausible] = useState(true);
	const [rePlausible, setRePlausible] = useState(true);

	const [kundentyp, setKundentyp] = useState('privat');

	const [remailValid, setRemailValid] = useState(true);

	const [AnsprechPartnerDialogVisible, setAnsprechPartnerDialogVisible] = useState(false);

	addDE();

	const Anrede = [
		"Herr",
		"Frau",
		"Sonstiges"
    ];

	const Laendervorwahlen = ["+43", "+42", "+49"]

	const deleteBst = (e) => {
		let _baustellen = kunde.Baustellen;
		_baustellen.splice(_baustellen.findIndex(x => x.ID === e.ID), 1);
		setKunde({...kunde, Baustellen: _baustellen });
		return;
	};

	function splitTelNummerAndSetSelection(nummer, setSelection, options)
	{
		if (nummer  === undefined || nummer === null)
			return '';
		nummer = nummer.trim();
		if (nummer.length >= 3)
		{
			let vorwahl = nummer.substring(0, 3);
			let index = options.find(x => x === vorwahl);
			setSelection(index);
			if (nummer.length === 3)
				return '';
			let n = formatPhoneNumber(nummer.substring(3, nummer.length), index).Nummer;
			if (n === undefined || n === null || n.length === 0)
				return nummer;
			return n;
		}
		else
			return '';
	}

	function onKundeLoad(kunde) {
		console.log(kunde); 
		if (kunde.KundenArt === 1)
			setKundentyp("Firma");
		kunde.Aktenkundigkeit = new Date(kunde.Aktenkundigkeit);
		if (kunde.UID !== undefined && kunde.UID.length > 0)
			setUidValidationError("checked");
		if (kunde.FN !== undefined && kunde.FN.length > 0)
			setFnValidationError("checked");
		let _erstePersonMobil = splitTelNummerAndSetSelection(kunde.ErstePersonMobil, setSelectedVorwahlErstePersonMobil, Laendervorwahlen);
		let _erstePersonTelefon = splitTelNummerAndSetSelection(kunde.ErstePersonTelefon, setSelectedVorwahlErstePersonTelefon, Laendervorwahlen);
		let _zweitePersonMobil = splitTelNummerAndSetSelection(kunde.ZweitePersonMobil, setSelectedVorwahlZweitePersonMobil, Laendervorwahlen);
		let _zweitePersonTelefon = splitTelNummerAndSetSelection(kunde.ZweitePersonTelefon, setSelectedVorwahlZweitePersonTelefon, Laendervorwahlen);

		setKunde({...kunde,
			ErstePersonMobil: _erstePersonMobil,
			ErstePersonTelefon: _erstePersonTelefon,
			ZweitePersonMobil: _zweitePersonMobil,
			ZweitePersonTelefon: _zweitePersonTelefon,
			BstPlz: kunde.BstPlz === 0 ? null : kunde.BstPlz});
	}

	function checkZweitePerson() {
		let ZweitePersonStarted = false;
		if(kunde.ZweitePersonAnrede !== undefined && kunde.ZweitePersonAnrede?.length !== 0 && kunde.ZweitePersonAnrede !== null){
			ZweitePersonStarted = true;
		}
		else if(kunde.ZweitePersonTitel !== undefined && kunde.ZweitePersonTitel?.length !== 0 && kunde.ZweitePersonTitel !== null){
			ZweitePersonStarted = true;
		}
		else if(kunde.ZweitePersonVorname !== undefined && kunde.ZweitePersonVorname?.length !== 0 && kunde.ZweitePersonVorname !== null){
			ZweitePersonStarted = true;
		}
		else if(kunde.ZweitePersonNachname !== undefined && kunde.ZweitePersonNachname?.length !== 0 && kunde.ZweitePersonNachname !== null){
			ZweitePersonStarted = true; 
		}
		else if(kunde.ZweitePersonMobil !== undefined && kunde.ZweitePersonMobil?.length !== 0 && kunde.ZweitePersonMobil !== null){
			ZweitePersonStarted = true; 
		}
		else if(kunde.ZweitePersonEmail !== undefined && kunde.ZweitePersonEmail?.length !== 0 && kunde.ZweitePersonEmail !== null){
			ZweitePersonStarted = true; 
		}
		else if(kunde.ZweitePersonAnrede === undefined && kunde.ZweitePersonTitel === undefined && kunde.ZweitePersonVorname === undefined && kunde.ZweitePersonNachname === undefined &&
			kunde.ZweitePersonMobil === undefined && kunde.ZweitePersonEmail === undefined)
			{
				ZweitePersonStarted = false;   	
			}
		return ZweitePersonStarted; 
	}

	const onSpeichernClick = () => {
		if (kunde.Kommission === undefined || kunde.Kommission === null || kunde.Kommission?.length === 0)
		{
			toastRef.current.show({severity:'error', summary: 'Der Kunde konnte nicht gespeichert werden', detail:'Bitte Kommission ausfüllen', life: 3000});
			return;
		}
		if (kunde.ErstePersonAnrede === undefined || kunde.ErstePersonAnrede === null || kunde.ErstePersonAnrede?.length === 0)
		{
			toastRef.current.show({severity:'error', summary: 'Der Kunde konnte nicht gespeichert werden', detail:'Bitte Anrede ausfüllen', life: 3000});
			return;
		}
		if (kunde.ErstePersonVorname === undefined || kunde.ErstePersonVorname === null || kunde.ErstePersonVorname?.length === 0)
		{
			toastRef.current.show({severity:'error', summary: 'Der Kunde konnte nicht gespeichert werden', detail:'Bitte Vorname ausfüllen', life: 3000});
			return;
		}
		if (kunde.ErstePersonNachname === undefined || kunde.ErstePersonNachname === null || kunde.ErstePersonNachname?.length === 0)
		{
			toastRef.current.show({severity:'error', summary: 'Der Kunde konnte nicht gespeichert werden', detail:'Bitte Nachname ausfüllen', life: 3000});
			return;
		}
		if (!(erstePersonMobilValid && zweitePersonMobilValid && erstePersonTelefonValid && zweitePersonTelefonValid))
		{
			toastRef.current.show({severity:'error', summary: 'Der Kunde konnte nicht gespeichert werden', detail:'Bitte Telefonnummer überprüfen.', life: 3000});
			return;
		}
		if (erstePersonEmailValid === false || zweitePersonEmailValid === false)
		{
			toastRef.current.show({severity:'error', summary: 'Der Kunde konnte nicht gespeichert werden', detail:'Bitte Email Adressen überprüfen.', life: 3000});
			return;
		}
		if ((kunde.ZweitePersonAnrede === undefined || kunde.ZweitePersonAnrede === null || kunde.ZweitePersonAnrede?.length === 0) && checkZweitePerson())
		{
			toastRef.current.show({severity:'error', summary: 'Der Kunde konnte nicht gespeichert werden', detail:'Bitte Anrede bei 2. Person ausfüllen', life: 3000});
			return;
		}
		if ((kunde.ZweitePersonVorname === undefined || kunde.ZweitePersonVorname === null || kunde.ZweitePersonVorname?.length === 0) && checkZweitePerson())
		{
			toastRef.current.show({severity:'error', summary: 'Der Kunde konnte nicht gespeichert werden', detail:'Bitte Vorname bei 2. Person ausfüllen', life: 3000});
			return;
		}
		if ((kunde.ZweitePersonNachname === undefined || kunde.ZweitePersonNachname === null || kunde.ZweitePersonNachname?.length === 0) && checkZweitePerson())
		{
			toastRef.current.show({severity:'error', summary: 'Der Kunde konnte nicht gespeichert werden', detail:'Bitte Nachname bei 2. Person ausfüllen', life: 3000});
			return;
		}

		if ((kunde.BstAdresse?.length > 0 && (kunde.BstOrt?.length === 0 || kunde.BstPlz?.length === 0)) ||
			(kunde.BstOrt?.length > 0 && (kunde.BstAdresse?.length === 0 || kunde.BstPlz?.length === 0)) ||
			(kunde.BstPlz?.length > 0 && (kunde.BstAdresse?.length === 0 || kunde.BstOrt?.length === 0)))
			{
				toastRef.current.show({severity:'error', summary: 'Der Kunde konnte nicht gespeichert werden', detail:'Bitte alle Baustellen Felder ausfüllen.', life: 3000});
				return;
			}

		if (kunde.UID !== undefined && kunde.UID.length > 0 &&  uidValidationError !== "checked")
		{
			toastRef.current.show({severity:'error', summary: 'Der Kunde konnte nicht gespeichert werden', detail:'Die UID Nummer muss zuerst überpüft werden', life: 3000});
			return;
		}

		if (kunde.FN !== undefined && kunde.FN.length > 0 && fnValidationError !== "checked")
		{
			toastRef.current.show({severity:'error', summary: 'Der Kunde konnte nicht gespeichert werden', detail:'Die Firmenbuchnummer muss zuerst überpüft werden', life: 3000});
			return;
		}

		let ReAdresseError = 0;
		if (kunde.ReAdresse === null || kunde.ReAdresse === undefined || kunde.ReAdresse.length === 0)
			ReAdresseError++;
		if (kunde.ReLand === null || kunde.ReLand === undefined)
			ReAdresseError++;
		if (kunde.ReOrt === null || kunde.ReOrt === undefined || kunde.ReOrt.length === 0)
			ReAdresseError++;
		if (kunde.RePlz === null || kunde.RePlz === undefined || kunde.RePlz.length === 0)
			ReAdresseError++;

		console.log(ReAdresseError);
		if (ReAdresseError !== 0)
		{
			toastRef.current.show({severity:'error', summary: 'Der Kunde konnte nicht gespeichert werden', detail:'Bitte überprüfen Sie die Rechnungsadresse', life: 3000});
			return;
		}
		
		var _kunde = null;
		if (kundentyp === "Firma"){
			_kunde = {...kunde, KundenArt: 1};
			console.log("Firmenkunde");
		}
		else if (kunde.ZweitePersonVorname !== undefined && kunde.ZweitePersonVorname !== null && kunde.ZweitePersonVorname.length !== 0 && kunde.ZweitePersonNachname !== 0){
			_kunde = {...kunde, KundenArt: 2};
		}
		else{
			_kunde = kunde;
		}

		if (_kunde.ErstePersonMobil?.length > 0)
			_kunde.ErstePersonMobil = selectedVorwahlErstePersonMobil + " " + _kunde.ErstePersonMobil;
			
		if (_kunde.ErstePersonTelefon?.length > 0)
			_kunde.ErstePersonTelefon = selectedVorwahlErstePersonTelefon + " " + _kunde.ErstePersonTelefon;
		
		if (_kunde.ZweitePersonMobil?.length > 0)
			_kunde.ZweitePersonMobil = selectedVorwahlZweitePersonMobil + " " + _kunde.ZweitePersonMobil;
			
		if (_kunde.ZweitePersonTelefon?.length > 0)
			_kunde.ZweitePersonTelefon = selectedVorwahlZweitePersonTelefon + " " + _kunde.ZweitePersonTelefon;


		if(props?.art !== "Angebotskunde"){
			kundenService.create(_kunde).then(() => {
				toastRef.current.show({severity:'success', summary: 'Erfolgreich', detail:'Kunde gespeichert.', life: 2000});
				setTimeout(() => history.goBack(), 1000);
			}).catch((e) => {
				toastRef.current.show({severity:'error', summary: 'Der Kunde konnte nicht gespeichert werden', life: 3000});
			});
		}
		else if(props.art === "Angebotskunde"){
			console.log(_kunde); 
			angebotskundenService.create(_kunde).then(() => {
				toastRef.current.show({severity:'success', summary: 'Erfolgreich', detail:'Angebotskunde gespeichert.', life: 2000});
				setTimeout(() => history.goBack(), 1000);
			}).catch((e) => {
				toastRef.current.show({severity:'error', summary: 'Der Angebotskunde konnte nicht gespeichert werden', life: 3000});
			});
		}
	}

	useEffect(() => {
		if(props.art !== "Angebotskunde"){
			setGlobalState({...globalState, page: props.action === 'bearbeiten' ? 'Kunden > Bearbeiten' : 'Kunden > Anlegen'});
		}
		else{
			setGlobalState({...globalState, page: props.action === 'bearbeiten' ? 'Angebotskunde > Bearbeiten' : 'Kunden > Anlegen'});
		}
		let _derzeitigerBenutzer;
		if (sessionStorage.mitarbeiter !== undefined && sessionStorage.mitarbeiter !== null) {
			_derzeitigerBenutzer = JSON.parse(sessionStorage.mitarbeiter);
			setDerzeitigerBenutzer(_derzeitigerBenutzer);
		}
		else
			mitarbeiterService.getMitarbeiterMitBenutzername(sessionStorage.benutername).then(data => {
				setDerzeitigerBenutzer(data);
				_derzeitigerBenutzer = data;
			});
		if (props.typ === "Fa")
		{
			setKundentyp("Firma"); 
		}
		if (props.action === 'bearbeiten') {
			if(props?.art !== "Angebotskunde"){
				kundenService.getFromId(Number(id)).then(onKundeLoad);
			}
			else if(props.art === "Angebotskunde"){
				angebotskundenService.getFromId(Number(id)).then(onKundeLoad);
			}
		} else {
			kundenService.getNeueKundennummer().then(data => {
				setKunde({...kunde,
					Kundennummer: data.Kundennummer,
					Aktenkundigkeit: new Date(),
					Verkäufer: {MitarbeiterNr: _derzeitigerBenutzer.MitarbeiterNr, Name: _derzeitigerBenutzer.Name},
					ReLand: {name: "AT - Österreich", code: "AT"},
					BstLand: {name: "AT - Österreich", code: "AT"}
				});
			});
		}
		mitarbeiterService.getVerkaeufer().then(data => setVerkäufer(data));
		ansprechpersonenService.getAbteilungen().then(data => setAbteilungen(data));
	}, []);

	useEffect(() => {
		if (derzeitigerBenutzer === null || derzeitigerBenutzer === undefined)
		return;
		setKunde({...kunde,
			Verkäufer: {MitarbeiterNr: derzeitigerBenutzer.MitarbeiterNr, Name: derzeitigerBenutzer.Name}
			});
	}, [derzeitigerBenutzer])

	useEffect(() => {
		let _erstePersonEmailValid = erstePersonEmailValid;
		if (emailRegex.test(kunde.ErstePersonEmail)) {
			_erstePersonEmailValid = true;
			setErstePersonEmailValid(_erstePersonEmailValid);
			if (kunde.ZweitePersonEmail === undefined || kunde.ZweitePersonEmail === null || kunde.ZweitePersonEmail.length === 0)
				setZweitePersonEmailValid(true);
		} else {
			setErstePersonEmailValid(false);
		}
		
		if (emailRegex.test(kunde.ZweitePersonEmail)) {
			setZweitePersonEmailValid(true);
			if (kunde.ErstePersonEmail === undefined || kunde.ErstePersonEmail === null || kunde.ErstePersonEmail.length == 0)
				setErstePersonEmailValid(true);
		} else if (kunde.ZweitePersonEmail?.length > 0) {
			setZweitePersonEmailValid(false);
		}
		
		if (emailRegex.test(kunde.ReMail)) {
			setRemailValid(true);
		}
		else{
			setRemailValid(false);
		}
	}, [kunde.ZweitePersonEmail, kunde.ErstePersonEmail, kunde.ReMail])

	function formatMatch(_match) {
		if (_match === undefined || _match === null)
			return;
		if (_match.length < 1)
			return;
		
		let _return = '';
		let match = new Array();
		_match.forEach(e => {
			if (e !== undefined && e !== null && e.length > 0)
				match.push(e);
		});
		for (let i = 1; i < match.length - 1; i++) {
			_return += match[i] + ' ';
		}
		_return += match[match.length-1];
		_return = _return.substring(_match[1].length + 1, _return.length);
		return _return;
	}

	function formatPhoneNumber(phoneNumberString, selectedVorwahl) {
		if (phoneNumberString === undefined || phoneNumberString === null || phoneNumberString.length === 0)
			return { status: true, Nummer: ''}

		var cleaned = selectedVorwahl + phoneNumberString.replace(/[^0-9+]/g, '');
 
		if (cleaned[0] === '0' && cleaned[1] === '0' && cleaned.length >= 4)
			cleaned = '+' + cleaned.substring(2);

		if (cleaned.length === 0)
			return { status: false, Nummer: cleaned };
		if (cleaned[0] === '+')
		{
			var match = cleaned.match(/^(\+\d{2})(6\d{2})(\d{3})(\d{2})(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d*)$/);
			if (match === undefined || match === null)
			{
				match = cleaned.match(/^(\+\d{2})(\d{4})(\d{3})(\d{2})(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d*)$/);
				if (match === undefined || match === null)
					return { status: false, Nummer: cleaned.substring(selectedVorwahl.length, cleaned.length) };
			}
			return { status: true, Nummer: formatMatch(match)}
		}
		else if (cleaned.length > 2 && cleaned[0] == '0' && cleaned[1] === '0')
		{
			var match = cleaned.match(/^(00\d{2})(6\d{2})(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d*)$/);
			if (match === undefined || match === null)
			{
				match = cleaned.match(/^(00\d{2})(\d{4})(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d*)$/);
				if (match === undefined || match === null)
					return { status: false, Nummer: formatMatch(match) };
			}
			return { status: true, Nummer: formatMatch(match)}
		}
		else {
			return { status: false, Nummer: formatMatch(match) };
		}
	}

	
	useEffect(() => {
		let _erstePersonNummer = formatPhoneNumber(kunde.ErstePersonMobil, selectedVorwahlErstePersonMobil);
		if(_erstePersonNummer.status === true) {
			setErstePersonMobilValid(true);
			setKunde({...kunde, ErstePersonMobil: _erstePersonNummer.Nummer});
		}
		else{
			setErstePersonMobilValid(false); 
		}


		let _zweitePersonMobil = formatPhoneNumber(kunde.ZweitePersonMobil, selectedVorwahlZweitePersonMobil);
		if(_zweitePersonMobil.status === true) {
			setZweitePersonMobilValid(true);
			setKunde({...kunde, ZweitePersonMobil: _zweitePersonMobil.Nummer});
		}
		else{
			setZweitePersonMobilValid(false); 
		}

		let _erstePersonTelefon = formatPhoneNumber(kunde.ErstePersonTelefon, selectedVorwahlErstePersonTelefon);
		if(_erstePersonTelefon.status === true) {
			setErstePersonTelefonValid(true);
			setKunde({...kunde, ErstePersonTelefon: _erstePersonTelefon.Nummer});
		}
		else{
			setErstePersonTelefonValid(false); 
		}
	
		let _zweitePersonTelefon = formatPhoneNumber(kunde.ZweitePersonTelefon, selectedVorwahlZweitePersonTelefon);
		if(_zweitePersonTelefon.status === true) {
			setZweitePersonTelefonValid(true);
			setKunde({...kunde, ZweitePersonTelefon: _zweitePersonTelefon.Nummer});
		}
		else{
			setZweitePersonTelefonValid(false); 
		}
	}, [kunde.ZweitePersonMobil, kunde.ErstePersonMobil, kunde.ErstePersonTelefon, kunde.ZweitePersonTelefon])

	useEffect(() => {
		let _ansprechperson = {...ansprechperson};
		let _mobil = formatPhoneNumber(ansprechperson.Mobil, selectedVorwahlAnsprechPersonMobil);
		if(_mobil.status === true) {
			setAnsprechPersonMobilValid(true);
			_ansprechperson.Mobil = _mobil.Nummer;
		}
		else{
			setAnsprechPersonMobilValid(false); 
		}

		let _telefon = formatPhoneNumber(ansprechperson.Telefon, selectedVorwahlAnsprechPersonTelefon);
		if(_telefon.status === true) {
			setAnsprechPersonTelefonValid(true);
			_ansprechperson.Telefon = _telefon.Nummer;
		}
		else{
			setAnsprechPersonTelefonValid(false); 
		}
		setAnsprechperson(_ansprechperson);
	}, [ansprechperson.Telefon, ansprechperson.Mobil])

	useEffect(() => {
		if (emailRegex.test(ansprechperson.Email)) {
			setAnsprechPersonEmailValid(true);
		} else {
			setErstePersonEmailValid(false);
		}
	}, [ansprechperson.Email])

	const selectedVerkäufertemplate = (v) => {
		if (v){
			return <VerkäuferTemplate Verkäufer={v} ></VerkäuferTemplate>
		}
		return <div>Verkäufer wählen</div>
    }

	const verkäuferOptionTemplate = (v) => {
        return <VerkäuferTemplate Verkäufer={v} ></VerkäuferTemplate>
    }

	const onAddBstClick = () => {
		if (kunde.BstAdresse === undefined || kunde.BstAdresse === null ||
			kunde.BstVerkäufer?.Name === undefined || kunde.BstVerkäufer?.Name === null ||
			kunde.BstOrt === undefined || kunde.BstOrt === null ||
			kunde.BstPlz === undefined || kunde.BstPlz === null){
			toastRef.current.show({severity:"error", summary: "Felder wurden nicht ausgefüllt", detail:"Bitte füllen Sie alle Felder aus."})
			return;
		}			
		setDisplayBst(false);
		let newBst = [...kunde.Baustellen];
		console.log(newBst);
		newBst.push({Adresse: kunde.BstAdresse, Plz: kunde.BstPlz.toString(), Ort: kunde.BstOrt, VerkäuferName: kunde.BstVerkäufer?.Name,
		Kundennummer: kunde.Kundennummer, MitarbeiterNr: kunde.BstVerkäufer.MitarbeiterNr, ID: -1, Land: kunde.BstLand.name});
		setKunde({...kunde, Baustellen: newBst});
	}

	const onAnsprechPartnerEditClick = (data) => {
		console.log(data);
		data.Mobil = splitTelNummerAndSetSelection(data.Mobil, setSelectedVorwahlAnsprechPersonMobil, Laendervorwahlen);
		data.Telefon = splitTelNummerAndSetSelection(data.Telefon, setSelectedVorwahlAnsprechPersonTelefon, Laendervorwahlen);
		setAnsprechperson(data);
		setAnsprechPartnerDialogVisible(true);
	}

	
    const abteilungAutocomplete = (event) => {
        setTimeout(() => {
            let _filteredAbteilungen;
            if (!event.query.trim().length) {
                _filteredAbteilungen = [...abteilungen];
            }
            else {
                _filteredAbteilungen = abteilungen.filter((a) => {
                    return a.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredAbteilungen(_filteredAbteilungen);
        }, 250);
    }

	const footerBstEdit = () => {
		return(
		<div>
			<Button label="Speichern" icon="pi pi-check" onClick={() => {
				/*if (bsteditPlausible === false)
				{
					toastRef.current.show({severity:'error', summary: 'Die Baustelle konnte nicht bearbeitet werden', detail:'Bitte überprüfen Sie die Adresse.', life: 3000});
					return;	
				}
				*/
				setDisplayBstEdit(false);
				let bst = kunde.Baustellen.find(x => x.ID === kunde.BstID);
				if (bst === undefined)
					return;
				bst.Adresse = kunde.BstAdresse;
				bst.Ort = kunde.BstOrt;
				bst.Plz = '' + kunde.BstPlz + '';
				bst.Land = kunde.BstLand?.name;
				bst.MitarbeiterNr = kunde.BstVerkäufer?.MitarbeiterNr;
				bst.VerkäuferName = kunde.BstVerkäufer?.Name;
				clearBstFelder();
			}}/>
			<Button label="Abbrechen" icon="pi pi-times" onClick={() => setDisplayBstEdit(false)}/>
		</div>
		)
	}

	const clearBstFelder = () => {
		kunde.BstAdresse = null;
		kunde.BstOrt = null;
		kunde.BstOrt = null;
		kunde.BstVerkäufer = null;
	}

	const footer = () => {
		return(
		<div>
			<Button label="Speichern" icon="pi pi-check" onClick={onAddBstClick}/>
			<Button label="Abbrechen" icon="pi pi-times" onClick={() => setDisplayBst(false)}/>
		</div>
		);
	}
	
	const setAdress = (rowData) => {
		console.log("SetAdresse: " + rowData);
		setKunde({...kunde, RePlz: rowData.Plz, ReOrt: rowData.Ort, ReAdresse: rowData.Adresse, ReLand: rowData.Land})
	}

	const statusOptionTemplate = (option) => {
        return (
            <div className="country-item">
                <div style={{borderRadius: "50%", backgroundColor: conditioncolor(option)}} className={option === "Blacklist" ? "pi pi-times-circle" : 
			option === "Whitelist" ? "pi pi-check-circle" : "pi pi-circle-off"}></div>
                <span> {option}</span>
            </div>
        );
    }

	const selectedOptionTemplate = (option, props) => {
		if(option) {
			return (
				<div className="country-item">
                <div style={{borderRadius: "50%", backgroundColor: conditioncolor(option)}} className={option === "Blacklist" ? "pi pi-times-circle" : 
				option === "Whitelist" ? "pi pi-check-circle" : "pi pi-circle-off"}></div>
                <span> {option}</span>
            </div>
			);
		}
		return (
			<span>
				{props.placeholder}
			</span>
		);
	}

	const conditioncolor = (rowData) => {
		if(rowData === "Blacklist"){
			return "LightPink"; 
		}
		
		else if (rowData === "Whitelist"){
			return "LightGreen";
		}
		else{
			return "Azure";
		}
	}

	useEffect(() => {
		erstepersonmobilref.current.selectionStart = ErstePersonMobilSelectionStart;
		erstepersonmobilref.current.selectionEnd = ErstePersonMobilSelectionStart;
	}, [kunde.ErstePersonMobil])

	useEffect(() => {
		erstepersontelefonref.current.selectionStart = ErstePersonTelefonSelectionStart;
		erstepersontelefonref.current.selectionEnd = ErstePersonTelefonSelectionStart;
	}, [kunde.ErstePersonTelefon])

	useEffect(() => {
		zweitepersonmobilref.current.selectionStart = ZweitePersonMobilSelectionStart;
		zweitepersonmobilref.current.selectionEnd = ZweitePersonMobilSelectionStart;
	}, [kunde.ZweitePersonMobil])

	useEffect(() => {
		zweitepersontelefonref.current.selectionStart = ZweitePersonTelefonSelectionStart;
		zweitepersontelefonref.current.selectionEnd = ZweitePersonTelefonSelectionStart;
	}, [kunde.ZweitePersonTelefon])

	/*
	useEffect(() => {
		if (ansprechpersontelefonref !== null && ansprechpersontelefonref !== undefined)
		{
			ansprechpersontelefonref.current.selectionStart = AnsprechPersonTelefonSelectionStart;
			ansprechpersontelefonref.current.selectionEnd = AnsprechPersonTelefonSelectionStart;
		}
	}, [ansprechperson.Telefon])

	useEffect(() => {
		if (ansprechpersonmobilref !== null && ansprechpersonmobilref !== undefined)
		{
			ansprechpersonmobilref.current.selectionStart = AnsprechPersonMobilSelectionStart;
			ansprechpersonmobilref.current.selectionEnd = AnsprechPersonMobilSelectionStart;
		}
	}, [ansprechperson.Mobil])
	*/
	
	const emailRegex = /\S+@\S+\.\S+/;
	
	const onSetRePLZOrt = (e) => {
		setKunde({...kunde, RePlz: e.target.value.PLZ, ReOrt: e.target.value.Ort});
	}
	const onSetRePLZ = (e) => {
		setKunde({...kunde, RePlz: e.target.value});
	}
	const onSetOrt = (e) => {
		setKunde({...kunde, ReOrt: e.target.value});
	}
	const onSetBstPLZOrt = (e) => {
		setKunde({...kunde, BstPlz: e.target.value.PLZ, BstOrt: e.target.value.Ort});
	}
	const onSetBstPLZ = (e) => {
		setKunde({...kunde, BstPlz: e.target.value});
	}
	const onSetBstOrt = (e) => {
		setKunde({...kunde, BstOrt: e.target.value});
	}

	const onAnsprechPartnerAnlegenClick = (e) => {
		try {
			let ansprechpartner = [...kunde.Ansprechpartner];
			console.log(ansprechpartner);
			let _ansprechperson = {...ansprechperson};
			console.log(ansprechperson);
			if (_ansprechperson.Telefon !== undefined && _ansprechperson.Telefon !== null && ansprechperson.Telefon.length > 0)
				_ansprechperson.Telefon = selectedVorwahlAnsprechPersonTelefon + ' ' + _ansprechperson.Telefon;
			if (_ansprechperson.Mobil !== undefined && _ansprechperson.Mobil !== null && ansprechperson.Mobil.length > 0)	
				_ansprechperson.Mobil = selectedVorwahlAnsprechPersonMobil + ' ' + _ansprechperson.Mobil;;
			if (ansprechpartner === null || ansprechpartner === undefined)
			{
				ansprechpartner = new Array();
				ansprechpartner.push(_ansprechperson);
			}
			else
			{
				if (_ansprechperson.ID !== undefined && _ansprechperson.ID !== null) {
					ansprechpartner = ansprechpartner.map(p => p.ID !== _ansprechperson.ID ? p : _ansprechperson);
				}
				else
					ansprechpartner.push(_ansprechperson);
			}
			let _kunde = {...kunde, Ansprechpartner: ansprechpartner};
			console.log(_kunde);
			setKunde(_kunde);
			setAnsprechperson({});
		} catch (e) {
			console.log(e);
		}
		setAnsprechPartnerDialogVisible(false);
	}

	const KundeKonvertieren = () => {
		let _kunde = {...kunde};
		if (_kunde.KundenArt== 1)
		{
			_kunde.KundenArt = 0;
			setKundentyp("privat");
		}
		else if (_kunde.KundenArt == 0)
		{
			_kunde.KundenArt = 1;
			setKundentyp("Firma");
		}
		setKunde(_kunde);
	}

	return (
		<React.Fragment>
			<Toast ref={toastRef}/>
			<Dialog visible={AnsprechPartnerDialogVisible}
				onHide={_ => setAnsprechPartnerDialogVisible(false)}
				header="neuer Ansprechpartner"
				footer={
					<React.Fragment>
					<Button
						label="Speichern"
						onClick={() => onAnsprechPartnerAnlegenClick()} />
					<Button label="Abbrechen" onClick={() => setAnsprechPartnerDialogVisible(false)} />
					</React.Fragment>
				}>
				<div className='grid'>
					<div className='col-12'>
						<div className="field grid">
							<label htmlFor="ansprechpersonanrede" className="col-fixed" style={{width: labelWidth}}>Anrede</label>
							<div className="col">
								<Dropdown value={ansprechperson.Anrede} options={Anrede} id="ansprechpersonanrede" onChange={e => setAnsprechperson({...ansprechperson, Anrede: e.target.value})} showClear className={ansprechperson.Anrede === undefined ? "p-invalid" : ""}/>
							</div>
						</div>
						<div className="field grid">
							<label htmlFor="ansprechpersontitel" className="col-fixed" style={{width: labelWidth}}>Titel</label>
							<div className="col">
								<InputText value={ansprechperson.Titel} id="ansprechpersontitel" onChange={e => setAnsprechperson({...ansprechperson, Titel: e.target.value})}
								/>
							</div>
						</div>
						<div className="field grid">
							<label htmlFor="ansprechpersonvorname" className="col-fixed" style={{width: labelWidth}}>Vorname</label>
							<div className="col">
								<InputText value={ansprechperson.Vorname} id="ansprechpersonvorname"
								onChange={e => setAnsprechperson({...ansprechperson, Vorname: e.target.value})}
								className={ansprechperson.Vorname?.length === 0 || ansprechperson.Vorname === undefined ? 'p-invalid' : ''}
								/>
							</div>
						</div>
						<div className="field grid">
							<label htmlFor="ansprechpersonnachname" className="col-fixed" style={{width: labelWidth}}>Nachname</label>
							<div className="col">
								<InputText value={ansprechperson.Nachname} id="ansprechpersonnachname"
								onChange={e => setAnsprechperson({...ansprechperson, Nachname: e.target.value})}
								className={ansprechperson.Nachname?.length === 0 || ansprechperson.Nachname === undefined ? 'p-invalid' : ''}
								/>
							</div>
						</div>
						<div className="field grid">
							<label htmlFor="ansprechpersonmobil" className="col-fixed" style={{width: labelWidth}}>Mobil</label>
							<div className="col">
								<div className='flex'>
									<div className='flex-initial flex align-items-center justify-content-center'>
										<Dropdown value={selectedVorwahlAnsprechPersonMobil} style={{marginRight: "10px"}} options={Laendervorwahlen} onChange={e => setSelectedVorwahlAnsprechPersonMobil(e.value)}></Dropdown>
									</div>
									<div className='flex-initial flex align-items-center justify-content-center'>
										<InputText value={ansprechperson.Mobil} id="erstepersonmobil"
											ref={ansprechpersonmobilref}
											onChange={e => {
												let _nummer = formatPhoneNumber(e.target.value, selectedVorwahlAnsprechPersonMobil).Nummer;
												if (e.target.selectionStart === e.target.value.length && _nummer.length > (e.target.value.length))
													setAnsprechPersonMobilSeletionStart(e.target.selectionStart + (_nummer.length - e.target.value.length));
												else
													setAnsprechPersonMobilSeletionStart(e.target.selectionStart);
												setAnsprechperson({...ansprechperson, Mobil: _nummer});
											}}
											className={ansprechPersonMobilValid === false ? 'p-invalid' : ''}
										/>
										<span className='ml-1'>Bsp. +43 664 111 11 11</span>
									</div>
								</div>
							</div>
						</div>
						<div className="field grid">
							<label htmlFor="erstepersontel" className="col-fixed" style={{width: labelWidth}}>Telefon (Fax DW)</label>
							<div className="col">
								<div className='flex'>
									<div className='flex-initial flex align-items-center justify-content-center'>
										<Dropdown value={selectedVorwahlAnsprechPersonTelefon} style={{marginRight: "10px"}} options={Laendervorwahlen} onChange={e => setSelectedVorwahlAnsprechPersonTelefon(e.value)}></Dropdown>
									</div>
									<div className='flex-initial flex align-items-center justify-content-center'>
										<InputText value={ansprechperson.Telefon}
											ref={ansprechpersontelefonref}
											onChange={e => {
												let _nummer = formatPhoneNumber(e.target.value, selectedVorwahlAnsprechPersonTelefon).Nummer;
												if (e.target.selectionStart === e.target.value.length && _nummer.length > (e.target.value.length))
													setAnsprechPersonTelefonSelectionStart(e.target.selectionStart + (_nummer.length - e.target.value.length));
												else
													setAnsprechPersonTelefonSelectionStart(e.target.selectionStart);
												setAnsprechperson({...ansprechperson, Telefon: _nummer});
											}}
											className={ansprechPersonTelefonValid === false ? 'p-invalid' : ''}/>
										<InputText value={ansprechperson.Fax}
											onChange={e => setAnsprechperson({...ansprechperson, Fax: e.target.value})} style={{marginLeft: "10px", width: "40px"}}												
										/>
										<span className='ml-1'>Bsp. +43 2522 111 11 11 (20)</span>
									</div>
								</div>
							</div>
						</div>
						<div className="field grid">
							<label htmlFor="ansprechpersonabteilung" className="col-fixed" style={{width: labelWidth}}>Abteilung</label>
							<div className="col">
								<AutoComplete suggestions={filteredAbreilungen}
									completeMethod={abteilungAutocomplete}
									value={ansprechperson.Abteilung}
									id="ansprechpersonabteilung"
									onChange={e => setAnsprechperson({...ansprechperson, Abteilung: e.target.value})}
									/>
							</div>
						</div>
						<div className="field grid">
							<label htmlFor="ansprechpersonemail" className="col-fixed" style={{width: labelWidth}}>E-Mail</label>
							<div className="col">
								<InputText value={ansprechperson.Email}
									id="erstepersonemail"
									onChange={e => setAnsprechperson({...ansprechperson, Email: e.target.value})}
									className={ansprechPersonEmailValid === false ? 'p-invalid' : ''}
									/>
								<span className='ml-1'>Bsp. m.mustermann@gmx.at</span>
							</div>
						</div>
					</div>
				</div>
			</Dialog>
			<div className="p-component grid">
				<div className="col-12">
					<div className="field grid">
						<Button label="Speichern" icon="pi pi-save" style={{marginRight: '.5em'}} onClick={onSpeichernClick} />
						<Button label="Abbrechen" icon="pi pi-times" onClick={() => history.goBack()} style={{marginRight: '.5em'}} />
						<Button label={kunde.KundenArt == 1 ? "zu privat konvertieren" : "zu Firma konvertieren"} onClick={KundeKonvertieren}/>
					</div>
				</div>
				<div className="col-12">
					<div className="field grid">
						<label htmlFor="KundenNr" className="col-fixed" style={{width: labelWidth}}>Kunden-Nr.</label>
						<div className="col">
							<InputText id="KundenNr" value={kunde.Kundennummer} style={{width: "calc("+labelWidth+"/2.3)"}} disabled/>
						</div>
					</div>
					<div className="field grid">
						<label htmlFor="info" className="col-fixed" style={{width: labelWidth}}>Kommission</label>
						<div className="col">
							<InputText value={kunde.Kommission} id="info" onChange={e => setKunde({...kunde, Kommission: e.target.value})}
							className={(kunde.Kommission?.length === 0 || kunde.Kommission === undefined) ? 'p-invalid' : ''} style={{width: "calc("+labelWidth+"/0.45)"}}/>
						</div>
					</div>
					<div className="field grid">
						<label htmlFor="seller" className="col-fixed" style={{width: labelWidth}}>Verkäufer</label>
						<div className="col">
						<Dropdown value={kunde.Verkäufer} options={verkäufer} onChange={e => setKunde({...kunde, Verkäufer: e.value})}
							optionLabel="Name" filter showClear filterBy="Name" placeholder="Verkäufer wählen"
							valueTemplate={selectedVerkäufertemplate} id="seller" emptyMessage="keine Verkäufer gefunden"
							itemTemplate={verkäuferOptionTemplate} style={{width: "calc("+labelWidth+"/0.65)"}}/>
						</div>
					</div>
					
				</div>
				<div className="col-12">
					<Accordion activeIndex={0}>
						<AccordionTab header="Allgemein">
							<div className="grid">
								<div className='col-6'>
									{kundentyp !== 'Firma' && 
										<h1>1. Person</h1>
									}
									{kundentyp === 'Firma' &&
										<h1>zu Handen</h1>
									}
									<div className="field grid">
										<label htmlFor="erstepersonanrede" className="col-fixed" style={{width: labelWidth}}>Anrede</label>
										<div className="col">
											<Dropdown value={kunde.ErstePersonAnrede} options={Anrede} id="erstepersonanrede" onChange={e => setKunde({...kunde, ErstePersonAnrede: e.target.value})} showClear className={kunde.ErstePersonAnrede === undefined ? "p-invalid" : ""}/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="erstepersontitel" className="col-fixed" style={{width: labelWidth}}>Titel</label>
										<div className="col">
											<InputText value={kunde.ErstePersonTitel} id="erstepersontitel" onChange={e => setKunde({...kunde, ErstePersonTitel: e.target.value})}
											/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="erstepersonvorname" className="col-fixed" style={{width: labelWidth}}>Vorname</label>
										<div className="col">
											<InputText value={kunde.ErstePersonVorname} id="erstepersonvorname"
											onChange={e => setKunde({...kunde, ErstePersonVorname: e.target.value})}
											className={kunde.ErstePersonVorname?.length === 0 || kunde.ErstePersonVorname === undefined ? 'p-invalid' : ''}
											/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="erstepersonnachname" className="col-fixed" style={{width: labelWidth}}>Nachname</label>
										<div className="col">
											<InputText value={kunde.ErstePersonNachname} id="erstepersonnachname"
											onChange={e => setKunde({...kunde, ErstePersonNachname: e.target.value})}
											className={kunde.ErstePersonNachname?.length === 0 || kunde.ErstePersonNachname === undefined ? 'p-invalid' : ''}
											/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="erstepersonmobil" className="col-fixed" style={{width: labelWidth}}>Mobil</label>
										<div className="col">
											<div className='flex'>
												<div className='flex-initial flex align-items-center justify-content-center'>
													<Dropdown value={selectedVorwahlErstePersonMobil} style={{marginRight: "10px"}} options={Laendervorwahlen} onChange={e => setSelectedVorwahlErstePersonMobil(e.value)}></Dropdown>
												</div>
												<div className='flex-initial flex align-items-center justify-content-center'>
													<InputText value={kunde.ErstePersonMobil} id="erstepersonmobil"
														ref={erstepersonmobilref}
														onChange={e => {
															let _nummer = formatPhoneNumber(e.target.value, selectedVorwahlErstePersonMobil).Nummer;
															if (e.target.selectionStart === e.target.value.length && _nummer.length > (e.target.value.length))
																setErstePersonMobilSelectionStart(e.target.selectionStart + (_nummer.length - e.target.value.length));
															else
																setErstePersonMobilSelectionStart(e.target.selectionStart);
															setKunde({...kunde, ErstePersonMobil: _nummer});
														}}
														className={erstePersonMobilValid === false ? 'p-invalid' : ''}
													/>
													<span className='ml-1'>Bsp. +43 664 111 11 11</span>
												</div>
											</div>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="erstepersontel" className="col-fixed" style={{width: labelWidth}}>Telefon (Fax DW)</label>
										<div className="col">
											<div className='flex'>
												<div className='flex-initial flex align-items-center justify-content-center'>
													<Dropdown value={selectedVorwahlErstePersonTelefon} style={{marginRight: "10px"}} options={Laendervorwahlen} onChange={e => setSelectedVorwahlErstePersonTelefon(e.value)}></Dropdown>
												</div>
												<div className='flex-initial flex align-items-center justify-content-center'>
													<InputText value={kunde.ErstePersonTelefon}
														ref={erstepersontelefonref}
														onChange={e => {
															let _nummer = formatPhoneNumber(e.target.value, selectedVorwahlErstePersonTelefon).Nummer;
															if (e.target.selectionStart === e.target.value.length && _nummer.length > (e.target.value.length))
																setErstePersonTelefonSelectionStart(e.target.selectionStart + (_nummer.length - e.target.value.length));
															else
																setErstePersonTelefonSelectionStart(e.target.selectionStart);
															setKunde({...kunde, ErstePersonTelefon: _nummer});
														}}
														className={erstePersonTelefonValid === false ? 'p-invalid' : ''}/>
													<InputText value={kunde.ErstePersonFax}
														onChange={e => setKunde({...kunde, ErstePersonFax: e.target.value})} style={{marginLeft: "10px", width: "40px"}}												
													/>
													<span className='ml-1'>Bsp. +43 2522 111 11 11 (20)</span>
												</div>
											</div>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="erstepersonemail" className="col-fixed" style={{width: labelWidth}}>E-Mail</label>
										<div className="col">
											<InputText value={kunde.ErstePersonEmail}
												id="erstepersonemail"
												onChange={e => setKunde({...kunde, ErstePersonEmail: e.target.value})}
												className={erstePersonEmailValid === false ? 'p-invalid' : ''}
												/>
											<span className='ml-1'>Bsp. m.mustermann@gmx.at</span>
										</div>
									</div>
								</div>
								{kundentyp !== 'Firma' &&
								<div className='col-6'>
									<h1>2. Person</h1>
									<div className="field grid">
										<label htmlFor="zweitepersonanrede" className="col-fixed" style={{width: labelWidth}}>Anrede</label>
										<div className="col">
											<Dropdown value={kunde.ZweitePersonAnrede} options={Anrede} id="zweitepersonanrede" onChange={e => setKunde({...kunde, ZweitePersonAnrede: e.target.value})}
											className={(kunde.ZweitePersonAnrede === undefined || kunde.ZweitePersonAnrede?.length === 0) && checkZweitePerson() ? 'p-invalid' : ''}
											showClear/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="zweitepersontitel" className="col-fixed" style={{width: labelWidth}}>Titel</label>
										<div className="col">
											<InputText value={kunde.ZweitePersonTitel} id="zweitepersontitel" onChange={e => setKunde({...kunde, ZweitePersonTitel: e.target.value})}/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="zweitepersonvorname" className="col-fixed" style={{width: labelWidth}}>Vorname</label>
										<div className="col">
											<InputText value={kunde.ZweitePersonVorname} id="zweitepersonvorname" onChange={e => setKunde({...kunde, ZweitePersonVorname: e.target.value})}
											className={(kunde.ZweitePersonVorname === undefined || kunde.ZweitePersonVorname?.length === 0) && checkZweitePerson() ? 'p-invalid' : ''}/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="zweitepersonnachname" className="col-fixed" style={{width: labelWidth}}>Nachname</label>
										<div className="col">
											<InputText value={kunde.ZweitePersonNachname} id="zweitepersonnachname" onChange={e => setKunde({...kunde, ZweitePersonNachname: e.target.value})}
											className={(kunde.ZweitePersonNachname === undefined || kunde.ZweitePersonNachname?.length === 0) && checkZweitePerson() ? 'p-invalid' : ''}/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="zweitepersonmobil" className="col-fixed" style={{width: labelWidth}}>Mobil</label>
										<div className="col">
											<div className='flex'>
												<div className='flex-initial flex align-items-center justify-content-center'>
													<Dropdown value={selectedVorwahlZweitePersonMobil} style={{marginRight: "10px"}} options={["+43", "+42", "+49"]} onChange={e => setSelectedVorwahlZweitePersonMobil(e.value)}></Dropdown>
												</div>
												<div className='flex-initial flex align-items-center justify-content-center'>
													<InputText value={kunde.ZweitePersonMobil} id="zweitepersonmobil"
													ref={zweitepersonmobilref}
													onChange={e => {
														let _nummer = formatPhoneNumber(e.target.value, selectedVorwahlZweitePersonMobil).Nummer;
														if (e.target.selectionStart === e.target.value.length && _nummer.length > (e.target.value.length))
															setZweitePersonMobilSelectionStart(e.target.selectionStart + (_nummer.length - e.target.value.length));
														else
															setZweitePersonMobilSelectionStart(e.target.selectionStart);
														setKunde({...kunde, ZweitePersonMobil: _nummer});
													}}
													className={(kunde.ZweitePersonMobil === undefined || zweitePersonMobilValid === false) && checkZweitePerson() && (erstePersonMobilValid == false && erstePersonTelefonValid == false) ? 'p-invalid' : ''}
													/>
													<span className='ml-1'>Bsp. +43 664 111 11 11</span>
												</div>
											</div>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="zweitepersontel" className="col-fixed" style={{width: labelWidth}}>Telefon (Fax DW)</label>
										<div className="col">
											<Dropdown value={selectedVorwahlZweitePersonTelefon} style={{marginRight: "10px"}} options={["+43", "+42", "+49"]} onChange={e => setSelectedVorwahlZweitePersonTelefon(e.value)}></Dropdown>
											<InputText value={kunde.ZweitePersonTelefon} id="zweitepersontel"
												ref={zweitepersontelefonref}
												onChange={e => {
													let _nummer = formatPhoneNumber(e.target.value, selectedVorwahlZweitePersonTelefon).Nummer;
													if (e.target.selectionStart === e.target.value.length && _nummer.length > (e.target.value.length))
														setZweitePersonTelefonSelectionStart(e.target.selectionStart + (_nummer.length - e.target.value.length));
													else
														setZweitePersonTelefonSelectionStart(e.target.selectionStart);
													setKunde({...kunde, ZweitePersonTelefon: _nummer});
												}}												
											/>
											<InputText value={kunde.ZweitePersonFax} id="zweitepersonfax"
												onChange={e => setKunde({...kunde, ZweitePersonFax: e.target.value})} style={{marginLeft: "10px", width: "40px"}}
											/>
											<span className='ml-1'>Bsp. +43 2522 111 11 11 (20)</span>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="zweitepersonemail" className="col-fixed" style={{width: labelWidth}}>E-Mail</label>
										<div className="col">
											<InputText value={kunde.ZweitePersonEmail} id="zweitepersonemail" onChange={e => setKunde({...kunde, ZweitePersonEmail: e.target.value})}
												className={(kunde.ZweitePersonEmail === undefined || zweitePersonEmailValid === false) && checkZweitePerson() ? 'p-invalid' : ''}
											/>
											<span className='ml-1'>Bsp. m.mustermann@gmx.at</span>
										</div>
									</div>
								</div>}
								{kundentyp === 'Firma' &&
								<div className='col-6'>
									<div className="field grid">
										<label htmlFor="bezeichnung" className="col-fixed" style={{width: labelWidth}}>Firmenbezeichnung</label>
										<div className="col">
											<InputText value={kunde.Firmenbezeichnung} id="bezeichnung" onChange={e => setKunde({...kunde, Firmenbezeichnung: e.target.value})} className={kunde.Firmenbezeichnung === undefined ? "p-invalid" : ""}/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="uid" className="col-fixed" style={{width: labelWidth}}>UID Nummer</label>
										<div className="col">
											<InputText value={kunde.UID} id="fn" onChange={e => {
												setKunde({...kunde, UID: e.target.value});
												setUidValidationError(false);
											}}
											className={(uidValidationError === "error" ) ? "p-invalid" : ""}/>
											{(uidValidationError !== "checked") &&
											<Button label="Überprüfen" icon="pi pi-sync"
												loading={uidLoading}
												style={{marginLeft: "10px"}} onClick={() => {
												setUidLoading(true);
												uidservice.checkUID(kunde.UID).then(x => {
													setUidLoading(false);
													if (x.errorCode !== 0)
														setUidValidationError("error");
													else
														setUidValidationError("checked");
												});
											}}/>
											}
											{(uidValidationError === "checked") &&
											<i style={{color: "green", fontSize: "1.5em"}} className="ml-2 pi pi-check"></i>
											}
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="fn" className="col-fixed" style={{width: labelWidth}}>Firmenbuchnummer</label>
										<div className="col">
											<InputText value={kunde.FN} id="fn" onChange={e => setKunde({...kunde, FN: e.target.value})}
											className={(fnValidationError === "error" ) ? "p-invalid" : ""} />
											{(fnValidationError !== "checked") &&
											<Button label="Überprüfen" icon="pi pi-sync"
												loading={firmenbuchloading}
												style={{marginLeft: "10px"}} onClick={() => {
												setFirmenbuchloading(true);
												firmenbuchService.checkFirmenbuchnummer(kunde.FN).then(x => {
													setFirmenbuchloading(false);
													setFnValidationError("checked");
													setKunde({...kunde, FN: x.fnr});
												}).catch((e) => {setFirmenbuchloading(false); setFnValidationError("error")});
											}}/>
											}
											{(fnValidationError === "checked") &&
											<i style={{color: "green", fontSize: "1.5em"}} className="ml-2 pi pi-check"></i>
											}
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="fn" className="col-fixed" style={{width: labelWidth}}>ZVR Nummer</label>
										<div className="col">
											<InputText value={kunde.ZVR} id="fn" onChange={e => setKunde({...kunde, ZVR: e.target.value})}/>
										</div>
									</div>
								</div>
								}
							</div>
						</AccordionTab>
						{kundentyp === 'Firma' &&
						<AccordionTab header="Ansprechpartner">
							<Button onClick={() => {setAnsprechPartnerDialogVisible(true)}}>neuer Ansprechpartner</Button>
							<DataTable
								value={kunde.Ansprechpartner}
								emptyMessage="keine Ansprechpartner vorhanden"
								>
								<Column field="Vorname" header="Vorname" />
								<Column field="Nachname" header="Nachname" />
								<Column field="Telefon" header="Telefonnummer" />
								<Column field="Mobil" header="Mobil" />
								<Column field="Email" header="E-Mail" />
								<Column field="Aktionen" header="Aktionen"
									body={rowData => <Button onClick={_ => onAnsprechPartnerEditClick(rowData)} buttonClassName="p-button" label="bearbeiten" icon="pi pi-pencil"/>}
									style={{width: '10em', textAlign: 'center'}}
									/>
							</DataTable>
						</AccordionTab>
						}
						{props.art !== "Angebotskunde" && <AccordionTab header="Baustellen">
							{ (kunde.Baustellen === null || kunde.Baustellen === undefined || kunde.Baustellen.length === 0)  &&
								<React.Fragment>
								<div className="field grid">
									<label htmlFor="bstadresse" className="col-fixed" style={{width: labelWidth}}>Straße</label>
									<div className="col">
										<InputText value={kunde.BstAdresse} id="bstadresse" type="text" style={{width: 'calc(20em / 0.967)'}} onChange={e => setKunde({...kunde, BstAdresse: e.target.value})} className={kunde.BstAdresse === undefined ? "p-invalid" : ""}/>
									</div>
								</div>
								<div className="field grid">
									<label htmlFor="bstplz" className="col-fixed" style={{width: 'calc(' + labelWidth + ' / 4)'}}>PLZ</label>
									<label htmlFor="bstort" className="col-fixed" style={{width: 'calc((' + labelWidth + ' / 4) * 3)'}}>Ort</label>
									<div className="col">
										<PLZOrtDropdown setPlausible={setBstNeuPlausible}
										plzvalue={kunde.BstPlz} Land={kunde.BstLand} ortvalue={kunde.BstOrt} setPLZOrt={onSetBstPLZOrt}
										setPLZ={onSetBstPLZ} setOrt={onSetBstOrt}/>
									</div>
							</div>
							<div className="field grid">
								<label htmlFor="reland" className="col-fixed" style={{width: labelWidth}}>Land</label>
								<div className="col">
									<Laenderdropdown Land={kunde.BstLand} onChange={(e) => setKunde({...kunde, BstLand: e.target.value})}></Laenderdropdown>
								</div>
							</div>
								</React.Fragment>
							}
							{  !(kunde.Baustellen === null || kunde.Baustellen === undefined || kunde.Baustellen.length === 0)  &&
								<React.Fragment>
									<Button label="Baustelle hinzufügen" icon="pi pi-plus-circle" style={{marginBottom: "10px"}}  onClick={() => {setDisplayBst(true); setKunde({...kunde, BstAdresse: "", BstPlz: "", BstOrt: "", BstLand: {name: "AT - Österreich", code: "AT"}, BstVerkäufer: {MitarbeiterNr: derzeitigerBenutzer.MitarbeiterNr, Name: derzeitigerBenutzer.Name}})}}></Button>
										<Dialog header="Baustelle hinzufügen" visible={displayBst} style={{width:"50vw"}} footer={footer} onHide={() => setDisplayBst(false)}>
											<React.Fragment>
												<div className="field grid">
													<label htmlFor="bstadresse" className="col-fixed" style={{width: labelWidth}}>Straße</label>
													<div className="col">
														<InputText value={kunde.BstAdresse} id="bstadresse" type="text" style={{width: 'calc(20em / 0.967)'}} onChange={e => setKunde({...kunde, BstAdresse: e.target.value})} className={kunde.BstAdresse === undefined ? "p-invalid" : ""}/>
													</div>
												</div>
												<div className="field grid">
													<label htmlFor="bstplz" className="col-fixed" style={{width: 'calc(' + labelWidth + ' / 4)'}}>PLZ</label>
													<label htmlFor="bstort" className="col-fixed" style={{width: 'calc((' + labelWidth + ' / 4) * 3)'}}>Ort</label>
													<div className="col">
														<PLZOrtDropdown setPlausible={setAddBstPlausible}
															plzvalue={kunde.BstPlz} ortvalue={kunde.BstOrt} setPLZOrt={onSetBstPLZOrt}
															setPLZ={onSetBstPLZ} setOrt={onSetBstOrt} Land={kunde.BstLand}/>
													</div>
												</div>
												<div className="field grid">
													<label htmlFor="bstbearbeitenland" className="col-fixed" style={{width: labelWidth}}>Land</label>
													<div className="col">
														<Laenderdropdown Land={kunde.BstLand} onChange={(e) => setKunde({...kunde, BstLand: e.target.value})}></Laenderdropdown>
													</div>
												</div>
												{props.action === 'bearbeiten' &&
												<div className="field grid">
													<label htmlFor="seller" className="col-fixed" style={{width: labelWidth}}>Verkäufer</label>
													<div className="col">
													<Dropdown value={kunde.BstVerkäufer} options={verkäufer} onChange={e => {console.log(e.value); setKunde({...kunde, BstVerkäufer: e.value})}}
														optionLabel="Name" filter showClear filterBy="Name" placeholder="Verkäufer wählen"
														valueTemplate={selectedVerkäufertemplate} id="seller" emptyMessage="keine Verkäufer gefunden"
														itemTemplate={verkäuferOptionTemplate} style={{width: "calc("+labelWidth+"/0.65)"}}/>
													</div>
												</div>
												}
											</React.Fragment>
										</Dialog>
									<DataTable value={kunde.Baustellen} responsive className="p-datatable-striped">
										<Column field="Adresse" header="Straße" />
										<Column field="Plz" header="PLZ" style={{width: "200px"}} body={row => {
												return <React.Fragment>{flagTemplate(Laender.find(x => x.name === row.Land))}<div className='ml-2 inline-block'>{row.Plz}</div></React.Fragment>;}}/>
										<Column field="Ort" header="Ort"/>
										<Column field="VerkäuferName" header="Verkäufer" body={(rowData) => {
											return <VerkäuferTemplate Verkäufer={verkäufer.find(x => x.MitarbeiterNr == rowData.MitarbeiterNr)}></VerkäuferTemplate>
										}} />
										<Column field="Aktionen" header="Aktionen" body={rowData => <PopupMenu buttonClassName="p-button-secondary" label="Aktionen" icon="pi pi-chevron-down" model={[
							{ label: 'Bearbeiten', icon: 'pi pi-pencil', command: () => {
								setDisplayBstEdit(true);
								console.log(rowData);
								let _land = Laender.find(x => x.name === rowData.Land);
								setKunde({...kunde,
									BstAdresse: rowData.Adresse,
									BstPlz: rowData.Plz,
									BstOrt: rowData.Ort,
									BstLand: _land,
									Land: rowData.Land,
									BstVerkäufer: verkäufer.find(x => x.MitarbeiterNr == rowData.MitarbeiterNr),
									BstID: rowData.ID,
								})
							}},
							{ label: 'Löschen', icon: 'pi pi-trash', command: () => deleteBst(rowData) },
							{ label: "Als Baustellenadresse übernehmen", icon: "pi pi-copy", command: () => setAdress(rowData)}
						]}/>} style={{width: '10em', textAlign: 'center'}}/>
									</DataTable>
									<Dialog header="Baustelle bearbeiten" visible={displayBstEdit} style={{width:"50vw"}} footer={footerBstEdit} onHide={() => setDisplayBstEdit(false)}>
										<React.Fragment>
											<div className="field grid">
											<label htmlFor="bstadresse" className="col-fixed" style={{width: labelWidth}}>Straße</label>
												<div className="col">
													<InputText value={kunde.BstAdresse} id="bstadresse" type="text" style={{width: 'calc(20em / 0.967)'}} onChange={e => setKunde({...kunde, BstAdresse: e.target.value})}/>
												</div>
											</div>
											<div className="field grid">
												<label htmlFor="bstplz" className="col-fixed" style={{width: 'calc(' + labelWidth + ' / 4)'}}>PLZ</label>
												<label htmlFor="bstort" className="col-fixed" style={{width: 'calc((' + labelWidth + ' / 4) * 3)'}}>Ort</label>
												<div className="col">
													<PLZOrtDropdown
														setPlausible={setBsteditPlausible}
														plzvalue={kunde.BstPlz} ortvalue={kunde.BstOrt} setPLZOrt={onSetBstPLZOrt}
														Land={kunde.BstLand} setPLZ={onSetBstPLZ} setOrt={onSetBstOrt}></PLZOrtDropdown>
												</div>
											</div>
											<div className="field grid">
												<label htmlFor="reland" className="col-fixed" style={{width: labelWidth}}>Land</label>
												<div className="col">
													<Laenderdropdown Land={kunde.BstLand} onChange={(e) => setKunde({...kunde, BstLand: e.target.value})}></Laenderdropdown>
												</div>
											</div>
											{props.action === 'bearbeiten' &&
												<div className="field grid">
													<label htmlFor="seller" className="col-fixed" style={{width: labelWidth}}>Verkäufer</label>
													<div className="col">
													<Dropdown value={kunde.BstVerkäufer} options={verkäufer} onChange={e => setKunde({...kunde, BstVerkäufer: e.value})}
														optionLabel="Name" filter showClear filterBy="Name" placeholder="Verkäufer wählen"
														valueTemplate={selectedVerkäufertemplate} id="seller" emptyMessage="keine Verkäufer gefunden"
														itemTemplate={verkäuferOptionTemplate} style={{width: "calc("+labelWidth+"/0.65)"}}/>
													</div>
												</div>
											}
										</React.Fragment>
									</Dialog>
								</React.Fragment>
							}
						</AccordionTab>}
						<AccordionTab header="Rechnungsadresse">
							<div className="field grid">
								<label htmlFor="adresse" className="col-fixed" style={{width: labelWidth}}>Straße</label>
								<div className="col">
									<InputText value={kunde.ReAdresse} id="adresse" type="text" style={{width: 'calc(20em / 0.967)'}}
										onChange={e => setKunde({...kunde, ReAdresse: e.target.value})}
										className={kunde.ReAdresse === undefined || kunde.ReAdresse == null || kunde.ReAdresse === "" ? 'p-invalid' : ''}
									/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="plz" className="col-fixed" style={{width: 'calc(' + labelWidth + ' / 4)'}}>PLZ</label>
								<label htmlFor="ort" className="col-fixed" style={{width: 'calc((' + labelWidth + ' / 4) * 3)'}}>Ort</label>
								<div className="col">
									<PLZOrtDropdown setPlausible={setRePlausible}
									plzvalue={kunde.RePlz} Land={kunde.ReLand} ortvalue={kunde.ReOrt}
									setPLZOrt={onSetRePLZOrt} setPLZ={onSetRePLZ} setOrt={onSetOrt}></PLZOrtDropdown>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="reland" className="col-fixed" style={{width: labelWidth}}>Land</label>
								<div className="col">
									<Laenderdropdown Land={kunde.ReLand} Pflichtfeld={true} onChange={(e) => setKunde({...kunde, ReLand: e.target.value})}></Laenderdropdown>
						
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="repermail" className="col-fixed" style={{width: labelWidth}}>Rechnung per Mail</label>
								<div className="col">
									<Checkbox id="repermail" checked={kunde.RePerMail} onChange={(e) => {
										if(e.checked === true)
											setKunde({...kunde, ReMail: kunde.ErstePersonEmail, RePerMail: e.checked});
										else
										{
											let _kunde = {...kunde, ReMail: "", RePerMail: e.checked}
											setKunde(_kunde);
										}
									}}/>
								</div>
							</div>
							<div className='field grid'>
								<label htmlFor="remail" className="col-fixed" style={{width: labelWidth}}>Rechnung E-Mail</label>
								<div className="col">
									<InputText value={kunde.ReMail} disabled={!kunde.RePerMail} id="remail" type="text" style={{width: 'calc(20em / 0.967)'}}
									onChange={e => {
										setKunde({...kunde, ReMail: e.target.value})}
									}
									className={kunde.RePerMail === true && kunde.ReMail === "" ? 'p-invalid' : ''}/>
								</div>
							</div>
							{((kunde.Baustellen !== null && kunde.Baustellen !== undefined && kunde.Baustellen.length === 1) ||
								(kunde.BstAdresse !== undefined && kunde.BstAdresse !== null && kunde.BstAdresse.length > 0))  &&
								<div className="field grid">
									<Button style={{marginLeft: '10.5em'}}
										onClick={() => {
											if (kunde.Baustellen !== undefined && kunde.Baustellen != null && kunde.Baustellen.length > 0)
											{
												setKunde({...kunde,
													RePlz: kunde.Baustellen[0].Plz,
													ReOrt: kunde.Baustellen[0].Ort,
													ReAdresse: kunde.Baustellen[0].Adresse,
													ReLand: kunde.Baustellen[0].Land
												})
											} else {
												setKunde({...kunde,
													RePlz: kunde.BstPlz,
													ReOrt: kunde.BstOrt,
													ReAdresse: kunde.BstAdresse,
													ReLand: kunde.BstLand
												})
											}}} icon="pi pi-copy" label="Baustellenadresse übernehmen">
									</Button>
								</div>
							}
						</AccordionTab>
						<AccordionTab header="Sonstiges">
						<div className="grid">
								<div className='col-12'>
									<div className="field grid">
										<label htmlFor="aktenkundigkeit" className="col-fixed" style={{width: labelWidth}}>Aktenkundigkeit</label>
										<div className="col">
											<Calendar inputId="aktenkundigkeit" value={kunde.Aktenkundigkeit} showIcon={true} locale="de" onChange={e => setKunde({...kunde, Aktenkundigkeit: e.target.value})}/>
										</div>
									</div>
										<div className="field grid">
										<label htmlFor="info" className="col-fixed" style={{width: labelWidth}}>Info</label>
										<div className="col">
											<InputTextarea value={kunde.Info} rows={10} cols={80} id="info" onChange={e => setKunde({...kunde, Info: e.target.value})} autoResize/>
										</div>
									</div>
									<div className='field grid'>
										<label htmlFor="status" className='col-fixed' style={{width: labelWidth}}>Status</label>
										<div className="col">
											<Dropdown options={["Neutral", "Blacklist", "Whitelist"]} value={kunde.BlacklistStatus === undefined ? setKunde({...kunde, BlacklistStatus: "Neutral"}) : kunde.BlacklistStatus} valueTemplate={selectedOptionTemplate} itemTemplate={statusOptionTemplate} onChange={e => setKunde({...kunde, BlacklistStatus: e.target.value})}/>
										</div>
									</div>
								</div>
						</div>
					    </AccordionTab>
						{ CONFIG.KundenWeiterAktiviert && <AccordionTab header="Weiteres">
							<div className="field grid">
								<label htmlFor="vk" className="col-fixed" style={{width: labelWidth}}>VK</label>
								<div className="col">
									<Dropdown value={kunde.VK} options={[
										{label: 'VK1', value: 1},
										{label: 'VK2', value: 2},
										{label: 'VK3', value: 3}
									]} onChange={e => setKunde({...kunde, VK: e.value})}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="barcode" className="col-fixed" style={{width: labelWidth}}>Barcode</label>
								<div className="col">
									<InputText value={kunde.Barcode} id="barcode" onChange={e => setKunde({...kunde, Barcode: e.target.value})}/>
								</div>
							</div>
						</AccordionTab>
						}
					</Accordion>
				</div>
			</div>
		</React.Fragment>
	);
}
