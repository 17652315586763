import React from 'react';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import VerkäuferTemplate from '../Verkäufer';

export default function VerkäuferColumn(props) {

	const onVerkäuferChange = (e) => {
		props.setSelectedVerkäufer(e.target.value);
		let _namen = new Array();
		let objects = e.target.value;
		try {
			objects.forEach((e) =>{
				_namen.push(e.Name);
			});
		} catch (e)
		{
			console.log(e);
		}

		props.datatableref.current.filter(_namen, "Verkäufer.Name", "in"); 
	}

	return(
		<Column header="Verkäufer" field="Verkäufer.Name" style={{width: "200px"}} filter sortable filterMatchMode="contains"
		filterElement={
			<MultiSelect value={props.selectedVerkäufer} options={props.Verkäufer} onChange={onVerkäuferChange} filter
					selectedItemTemplate={(rowData) => { return <VerkäuferTemplate Verkäufer={rowData} ></VerkäuferTemplate>}}
					placeholder={"Alle"} itemTemplate={(rowData) => { return <VerkäuferTemplate Verkäufer={rowData} ></VerkäuferTemplate>}} />
		}
		showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
		body={(rowData) => <VerkäuferTemplate Verkäufer={rowData.Verkäufer} ></VerkäuferTemplate>}
		filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
		/>
	)
}