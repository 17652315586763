import React, {useEffect, useRef, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton';
import { DataTable } from 'primereact/datatable';
import { Laender } from '../Laenderdropdown';
import KommissionColumn from '../Kunden/Kommission';
import AdresseColumn from '../Kunden/Adresse';
import LandColumn from '../Kunden/Land';
import PLZColumn from '../Kunden/PLZ';
import OrtColumn from '../Kunden/Ort';
import EmailColumn from '../Kunden/Email';
import MobilColumn from '../Kunden/Mobil';
import KundentypColumn from '../Kunden/Kundentyp';
import VerkäuferColumn from '../Kunden/Verkäufer';
import TelefonColumn from '../Kunden/Telefon';
import FaxDWColumn from '../Kunden/FaxDW';
import VornameColumn from '../Kunden/Vorname';
import NachnameColumn from '../Kunden/Nachname';
import FirmenbezeichnungColumn from '../Kunden/Firmenbezeichnung';
import KundennummerColumn from '../Kunden/Kundennummer';
import { KundenService } from '../../service/KundenService';
import VerkäuferTemplate from '../Verkäufer';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import AngebotKundeAnzeigen from './AngebotKundeAnzeigen';
import { AngebotskundenService } from '../../service/AngebotskundenService';
import { Toast } from 'primereact/toast';

export default function AngebotKundenSuche(props)
{
    const kundenService = new KundenService();
	const angebotskundenService = new AngebotskundenService();
    const KundenTypen = [{KundenTyp: "Kunden", Value: 0},
                         {KundenTyp: "Angebotskunden", Value: 1}];

	const KundenArt = [{KundenArt: "Privat", Value: 0},
					   {KundenArt: "Firma", Value: 1}]

	const KundenTyp = ["Privat", "Firma"]; 

	const labelWidth = '15em';
	const labelWidthInputText = '290px';
	const toastRef = useRef(null);

	const columns = [
		{field: "Adresse", visible: true },
		{field: "Land", visible: true},
		{field: "PLZ", visible: true },
		{field: "Ort", visible: true },
		{field: "E-Mail", visible: true},
		{field: "Mobil", visible: true},
		{field: "Kundentyp", visible: true},
		{field: "Verkäufer", visible: true},
		{field: "Telefon", visible: true},
		{field: "Fax-DW", visible: true},
		{field: "Vorname", visible: true},
		{field: "Nachname", visible: true},
		{field: "Firmenname", visible: true},
		{field: "Kundennummer", visible: true}
	]

	const [selectedColumns, setSelectedColumns] = useState(columns);

	const [neuerKundenTyp, setNeuerKundenTyp] = useState("Privat"); 
	const [erstePersonMobilValid, setErstePersonMobilValid] = useState(false);
	const [erstePersonMailValid, setErstePersonMailValid] = useState(false);

	const [neuerKundeDialogVisible, setNeuerKundeDialogVisible] = useState(false);
	const [neuerAngebotKundeDialogVisible, setNeuerAngebotKundeDialogVisible] = useState(false);
	const [einstellungenVisible, setEinstellungenVisble] = useState(false);
    const [kundenTyp, setKundenTyp] = useState(KundenTypen[0]);
    const [kunden, setKunden] = useState([]);
	const [kunde, setKunde] = useState(null);
	const [angebot, setAngebot] = useState({});
	const [plzsuche, setPlzsuche] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [selectedKunden, setSelectedKunden] = useState([]);
    const [expandedRows, setExpandedRows] = useState(false);
    const [groupByVerkäufer, setGroupByVerkäufer] = useState(false);
	const [origKunden, setOrigKunden] = useState([]);

	const [selectedAdresse, setSelectedAdresse] = useState([]);
	const [selectedPLZ, setSelectedPLZ] = useState([]);
	const [selectedKommission, setSelectedKommission] = useState([]);
	const [selectedOrt, setSelectedOrt] = useState([]);
	const [selectedMail, setSelectedMail] = useState([]);
	const [selectedMobil, setSelectedMobil] = useState([]);
	const [selectedVerkäufer, setSelectedVerkäufer] = useState([]);
	const [selectedKundenTyp, setSelectedKundenTyp] = useState([]);
	const [selectedTelefon, setSelectedTelefon] = useState([]);
	const [selectedBlacklistStatus, setSelectedBlacklistStatus] = useState([]);
	const [selectedLand, setSelectedLand] = useState([]);
	const [selectedFaxDW, setSelectedFaxDW] = useState([]); 
	const [selectedVorname, setSelectedVorname] = useState([]);
	const [selectedNachname, setSelectedNachname] = useState([]);
	const [selectedFirmenname, setSelectedFirmenname] = useState([]);
	const [selectedKundennummer, setSelectedKundennummer] = useState([]); 

    const [Adressen, setAdressen] = useState([]); 
	const [Kommission, setKommission] = useState([]); 
	const [PLZ, setPLZ] = useState([]);
	const [Ort, setOrt] = useState([]);
	const [Mail, setMail] = useState([]); 
	const [Mobil, setMobil] = useState([]);
	const [Verkäufer, setVerkäufer] = useState([]);
	const [Telefon, setTelefon] = useState([]);
	const [FaxDW, setFaxDW] = useState([]);
	const [Firmenbezeichnung, setFirmenbezeichnung] = useState([]);
	const [Vorname, setVorname] = useState([]);
	const [Nachname, setNachname] = useState([]);
	const [Kundennummer, setKundennummer] = useState([]);

    const datatableref = useRef(null);
	const history = useHistory();

	const createKunde = () => history.push('/kunde/neu');

	const createFAKunde = () => history.push('/kunde/FANeu');

    function onlyUnique(value, index, self){
		return self.indexOf(value) === index;
	}

    useEffect(() => {
        setKundenTyp(KundenTypen[0]);
		if(kundenTyp.KundenTyp === "Kunden"){
			kundenService.getAll().then(onKundeLoad);
			console.log("KUnden"); 
		}
		else if(kundenTyp.KundenTyp === "AngebotKunden"){
			console.log("Test");
			angebotskundenService.getAll().then(onKundeLoad);
		}

    }, [])

	useEffect(() => {
		if(kundenTyp.KundenTyp === "Kunden"){
			kundenService.getAll().then(onKundeLoad);
		}
		else if(kundenTyp.KundenTyp === "AngebotKunden"){
			angebotskundenService.getAll().then(onKundeLoad);
		}
    }, [kundenTyp])

	function onKundeLoad(data)
	{
		{
			setOrigKunden(data);
			setKunden(data);
			let adressen = [];
			let PLZ = []; 
			let Kommission = [];
			let Ort = [];
			let Mail = [];
			let Mobil = []; 
			let Verkäufer = []; 
			let Telefon = [];
			let FaxDW = [];
			let Vorname = []; 
			let Nachname = [];
			let Firmenname = []; 
			let Kundennummer = []; 

			data.forEach(element => {
				adressen.push(element.ReAdresse);
				PLZ.push(element.RePlz);
				Kundennummer.push(element.Kundennummer); 
				if (element.ErstePersonVorname?.length > 0){
					Vorname.push(element.ErstePersonVorname); 
				} 
				if (element.ErstePersonNachname?.length > 0){
					Nachname.push(element.ErstePersonNachname); 
				} 
				if (element.Firmenbezeichnung?.length > 0){
					Firmenname.push(element.Firmenbezeichnung); 
				} 
				if (element.ErstePersonFax?.length > 0){
					FaxDW.push(element.ErstePersonFax); 
				} 
				if (element.Kommission?.length > 0) {
					Kommission.push(element.Kommission);
				}
				if (element.ReOrt?.length > 0) {
					Ort.push(element.ReOrt);
				}
				if (element.ErstePersonEmail?.length > 0) {
					Mail.push(element.ErstePersonEmail);
				}
				if (element.ErstePersonMobil?.length > 0) {
					Mobil.push(element.ErstePersonMobil);
				}
				if (element.Verkäufer != null) {
					Verkäufer.push(element.Verkäufer); 
				}
				if (element.ErstePersonTelefon?.length > 0){
					Telefon.push(element.ErstePersonTelefon);
				}
				
			});

			let _Vorname = [];
			Vorname.filter(onlyUnique).forEach(e => {
				_Vorname.push({Vorname: e});
			})
			setVorname(_Vorname); 

			let _Nachname = []; 
			Nachname.filter(onlyUnique).forEach(e => {
				_Nachname.push({Nachname: e});
			})
			setNachname(_Nachname); 

			let _Firmenbezeichnung = []; 
			Firmenname.filter(onlyUnique).forEach(e => {
				_Firmenbezeichnung.push({Firmenname: e});
			})
			setFirmenbezeichnung(_Firmenbezeichnung); 

			let _Kundennummer = [];
			Kundennummer.filter(onlyUnique).forEach(e => {
				_Kundennummer.push({Kundennummer: e}); 
			})
			setKundennummer(_Kundennummer); 

			let _FaxDW = [];
			FaxDW.filter(onlyUnique).forEach(e => {
				_FaxDW.push({FaxDW: e});
			});
			setFaxDW(_FaxDW); 

			let _Telefon = [];
			Telefon.filter(onlyUnique).forEach(e => {
				_Telefon.push({Telefon: e});
			}); 
			setTelefon(_Telefon); 

			let _Verkäufer = []; 
			Verkäufer.forEach(e => {
				if (_Verkäufer.find(x => x.Name === e.Name) === undefined)
					_Verkäufer.push(e);
			});
			setVerkäufer(_Verkäufer);

			let _Mobil = [];
			Mobil.filter(onlyUnique).forEach(e => {
				_Mobil.push({ErstePersonMobil: e});
			});
			setMobil(_Mobil);

			let _Mail = [];
			Mail.filter(onlyUnique).forEach(e => {
				_Mail.push({ErstePersonEmail: e});
			});
			setMail(_Mail);

			let _Kommission = [];
			Kommission.filter(onlyUnique).forEach(e => {
				_Kommission.push({Kommission: e});
			});
			setKommission(_Kommission);

			let _adressen = [];
			adressen.filter(onlyUnique).forEach(e => {
				_adressen.push({ReAdresse: e});
			});
			setAdressen(_adressen);

			let _PLZ = [];
			PLZ.filter(onlyUnique).forEach(e => {
				_PLZ.push({RePlz: e});
			});
			setPLZ(_PLZ);

			let _Ort = [];
			Ort.filter(onlyUnique).forEach(e => {
				_Ort.push({ReOrt: e});
			});
			setOrt(_Ort);

			let _kunde = {...kunde, KundenArt: 0};
							console.log(_kunde); 
							setKunde(_kunde);
		}
	}

	const onFilterResetClick = () => {
		let filters = datatableref.current.getFilters()
		for (const filter in filters){
			datatableref.current.filters = filters[filter].value = '';
		}
		setSelectedKommission([]);
		setSelectedAdresse([]);
		setSelectedLand([]);
		setSelectedPLZ([]);
		setSelectedOrt([]);
		setSelectedMail([]);
		setSelectedMobil([]);
		setSelectedKundenTyp([]);
		setSelectedVerkäufer([]);
		setSelectedTelefon([]);
		setSelectedFaxDW([]);
		setSelectedVorname([]); 
		setSelectedNachname([]);
		setSelectedFirmenname([]);
		setSelectedKundennummer([]); 
		datatableref.current.reset();
	}

	const renderHeader = () => {
        return (
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText autoFocus type="search" onInput={(e) => setGlobalFilterValue(e.target.value)} placeholder="Globale Suche" />
                </span>
				<span style={{float: "right"}}>
					<Button icon= "pi pi-filter-slash" label="Filter zurücksetzen" onClick={onFilterResetClick}></Button>
				</span>
            </div>
        )
    }

	const Emailbody = (row) => {
		
		let _mail = "mailto:" + row.ErstePersonEmail;
		return <a style={selectedKunden === row ? {textDecoration: "underline", color: "white"} : {textDecoration: "underline", color: "blue"}} href={_mail}>{row.ErstePersonEmail}</a>
	}

	const Mobilbody = (row) => {{ 
		let tellcall = "tel:"+row.ErstePersonMobil;
		return <a style={selectedKunden === row ? {textDecoration: "underline", color: "white"} : {textDecoration: "underline", color: "blue"}} href={tellcall}>{row.ErstePersonMobil}</a>}}

	const Telefonbody = (row) => {
		let tellcall = "tel:"+row.ErstePersonTelefon; 
		return <a style={selectedKunden === row ? {textDecoration: "underline", color: "white"} : {textDecoration: "underline", color: "blue"}} href={tellcall}>{row.ErstePersonTelefon}</a>}

	const neuerKundenHeader = 
		<React.Fragment>
				<i className='pi pi-plus'></i>
				<span className='p-pr-5'> Neuer Kunde</span>
		</React.Fragment>
	
    return (
		<React.Fragment>
        <Dialog visible={props.visible} onHide={e => props.setVisible(false)} position="right" style={{marginRight: "50px", width: '82vw'}}
        header={
			<React.Fragment>
				<i className='pi pi-search'/>
				<span className='p-pr-5'> Kundensuche</span>
			</React.Fragment>
        }
        footer= {
            <div className='grid col-12'>
                <Button label='Abbrechen' icon="pi pi-times" onClick={() => props.setVisible(false)} />
                <Button label='OK' icon="pi pi-check" onClick={() => {
					props.setKunde(selectedKunden);
                    props.setVisible(false);
                }}/>
            </div>
        }
        >
            <div className='grid'>
                <div className='col-6'>
                    <SelectButton value={kundenTyp.Value}
						className='inline-block'
                        options={KundenTypen} optionLabel="KundenTyp" optionValue="Value"
                        onChange={(e) => {
							console.log(kundenTyp);
							console.log(e.target.value);
							if(e.target.value === 0)
								setKundenTyp({KundenTyp: "Kunden", Value: 0});
							else if(e.target.value === 1)
								setKundenTyp({KundenTyp: "AngebotKunden", Value: 1})
							
						}}></SelectButton>

					<Button className='inline-block' label='Neuer Angebotskunde' style={{textAlign: 'left', marginLeft: "10px"}} icon="pi pi-plus"
						onClick={() => {
							let _kunde = {...kunde, KundenArt: 0};
							console.log(_kunde); 
							setKunde(_kunde);
							setNeuerAngebotKundeDialogVisible(true);
							props.setVisible(false);
						 }} />
					{/*kundenTyp.Value === 0 && <Button className='inline-block' label='Neuer Kunde' style={{textAlign: 'left', marginLeft: "10px"}} icon="pi pi-plus"
						onClick={() => {
							setNeuerKundeDialogVisible(true); 
							props.setVisible(false);
						 }} />*/}
                </div>
				<div className="col-6" style={{textAlign: 'right'}}>
					<Button label="Einstellungen" icon="pi pi-cog" onClick={e => setEinstellungenVisble(true)}/>
				</div>
            </div>
            <div className='grid'>
                <div className='col-12'>
                    <DataTable header={renderHeader} value={kunden} ref={datatableref} responsive
                        filterDisplay="row"
                        className="p-datatable-striped tableCursorPointer"
                        currentPageReportTemplate="({first} - {last} von {totalRecords})"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowHover paginator rows={20} rowsPerPageOptions={[20,30,50,100]} scrollable="true" scrollHeight="650px" scrollDirection="both"
                        globalFilter={globalFilterValue} selectionMode="single"  selection={selectedKunden} onSelectionChange={e => { setSelectedKunden(e.value)}}
                        onRowClick={(e) => { setSelectedKunden(e.data) }}
                        globalFilterFields={["global", "ReAdresse", "RePlz", "ReOrt", "ErstePersonVorname", "ErstePersonNachname"]}
                        groupRowsBy="Verkäufer.Name" expandableRowGroups={groupByVerkäufer} expandedRows={expandedRows}
                        sortOrder={1} sortMode="single" sortField={(groupByVerkäufer) ? "Verkäufer.Name" : "Kundennummer"}
                        rowGroupHeaderTemplate={(rowData) => <VerkäuferTemplate Verkäufer={rowData.Verkäufer} ></VerkäuferTemplate>}
                        onRowToggle={(e) => setExpandedRows(e.data)} rowGroupMode={(groupByVerkäufer) ? "subheader" : ""}
                        dataKey="Kundennummer"
                    >
                        {
							KommissionColumn({
								selectedKommission: selectedKommission,
								Kommission: Kommission,
								setSelectedKommission: setSelectedKommission,
								datatableref: datatableref
							})
                        }

						{selectedColumns.find((item) => item.field === "Adresse").visible === true &&
							AdresseColumn({
								selectedAdresse: selectedAdresse, 
								Adressen: Adressen, 
								setSelectedAdresse:setSelectedAdresse, 
								datatableref:datatableref
							})
						}
						
						{selectedColumns.find((item) => item.field === "Land")?.visible === true &&
							LandColumn({
								selectedLand: selectedLand, 
								Laender: Laender,
								setSelectedLand: setSelectedLand,
								datatableref: datatableref})
						}

						{selectedColumns.find((item) => item.field === "PLZ")?.visible === true &&
							PLZColumn({
								selectedPLZ: selectedPLZ,
								PLZ: PLZ,
								setSelectedPLZ: setSelectedPLZ,
								datatableref: datatableref
							})
						}

						{selectedColumns.find((item) => item.field === "Ort")?.visible === true &&
							OrtColumn({
								selectedOrt: selectedOrt,
								Ort: Ort,
								setSelectedOrt: setSelectedOrt,
								datatableref: datatableref
							})
						}

						{selectedColumns.find((item) => item.field === "E-Mail")?.visible === true &&
							EmailColumn({
								selectedMail: selectedMail,
								Mail: Mail,
								setSelectedMail: setSelectedMail,
								datatableref: datatableref,
								body: Emailbody
							})
						}

						{selectedColumns.find((item) => item.field === "Mobil")?.visible === true &&
							MobilColumn({
								selectedMobil: selectedMobil,
								Mobil: Mobil,
								setSelectedMobil: setSelectedMobil,
								datatableref: datatableref,
								body: Mobilbody
							})
						}

						{selectedColumns.find((item) => item.field === "Kundentyp")?.visible === true &&
							KundentypColumn({
								selectedKundenTyp: selectedKundenTyp,
								setSelectedKundenTyp: setSelectedKundenTyp,
								datatableref: datatableref
							})
						}

						{selectedColumns.find((item) => item.field === "Verkäufer")?.visible === true &&
							VerkäuferColumn({
								selectedVerkäufer: selectedVerkäufer,
								Verkäufer: Verkäufer,
								setSelectedVerkäufer: setSelectedVerkäufer,
								datatableref: datatableref
							})
						}

						{selectedColumns.find((item) => item.field === "Telefon")?.visible === true &&
							TelefonColumn({
								selectedTelefon: selectedTelefon,
								Telefon: Telefon,
								setSelectedTelefon: setSelectedTelefon,
								datatableref: datatableref,
								body: Telefonbody
							})
						}

						{selectedColumns.find((item) => item.field === "Fax-DW")?.visible === true &&
							FaxDWColumn({
								selectedFaxDW: selectedFaxDW,
								FaxDW: FaxDW,
								setSelectedFaxDW: setSelectedFaxDW,
								datatableref: datatableref
							})
						}
						
						{selectedColumns.find((item) => item.field === "Vorname")?.visible === true &&
							VornameColumn({
								selectedVorname: selectedVorname,
								Vorname: Vorname,
								setSelectedVorname: setSelectedVorname,
								datatableref: datatableref
							})
						}

						{selectedColumns.find((item) => item.field === "Nachname")?.visible === true &&
							NachnameColumn({
								selectedNachname: selectedNachname,
								Nachname: Nachname,
								setSelectedNachname: setSelectedNachname,
								datatableref: datatableref
							})
						}

						{selectedColumns.find((item) => item.field === "Firmenname")?.visible === true &&
							FirmenbezeichnungColumn({
								selectedFirmenname: selectedFirmenname,
								Firmenbezeichnung: Firmenbezeichnung,
								setSelectedFirmenname: setSelectedFirmenname,
								datatableref: datatableref
							})
						}

						{selectedColumns.find((item) => item.field === "Kundennummer")?.visible === true &&
						 	KundennummerColumn({
								selectedKundennummer: selectedKundennummer,
								Kundennummer: Kundennummer,
								setSelectedKundennummer: setSelectedKundennummer,
								datatableref: datatableref
							}) 
						}
                    </DataTable>
                </div>
            </div>
        </Dialog>
		<Toast ref={toastRef}/>
		<Dialog visible={neuerAngebotKundeDialogVisible} onHide={e => setNeuerAngebotKundeDialogVisible(false)} style={{ width: '35vw' }}
				header={
					<React.Fragment>
						<i className='pi pi-plus'></i>
						<span className='p-pr-5'> Neuer Angebotskunde</span>
					</React.Fragment>
				}
				footer= {
					<div className='grid col-12'>
						<Button label='Abbrechen' icon="pi pi-times" onClick={e => setNeuerAngebotKundeDialogVisible(false)} />
						<Button label='OK' icon="pi pi-check" onClick={e => {
							let _kunde = {...kunde, KundenTyp: 1};
							console.log(_kunde);
							_kunde.Kommission = _kunde.ErstePersonVorname + " " + _kunde.ErstePersonNachname
							_kunde.MitarbeiterNr = -1;
							props.setKunde(_kunde);
							console.log(kunde.KundenArt);
							console.log(kunde);
							if(kunde.KundenArt === 1 && (kunde.Firmenbezeichnung === "" || kunde.Firmenbezeichnung === undefined || kunde.Firmenbezeichnung === null)){
								toastRef.current.show({severity:'error', summary: 'Der Kunde konnte nicht gespeichert werden', detail:'Bitte Firmenbezeichnung ausfüllen', life: 3000})
								return; 
							}
							if(kunde.ErstePersonNachname === "" || kunde.ErstePersonNachname === undefined || kunde.ErstePersonNachname === null){
								toastRef.current.show({severity:'error', summary: 'Der Kunde konnte nicht gespeichert werden', detail:'Bitte Nachname ausfüllen', life: 3000})
								return; 
							}
							if(erstePersonMobilValid === false || kunde.ErstePersonMobil === "" || kunde.ErstePersonMobil === undefined || kunde.ErstePersonMobil === null){
								toastRef.current.show({severity:'error', summary: 'Der Kunde konnte nicht gespeichert werden', detail:'Bitte Telefonnummer ausfüllen', life: 3000})
								return; 
							}
							if(erstePersonMailValid === false || kunde.ErstePersonEmail === "" || kunde.ErstePersonEmail === undefined || kunde.ErstePersonEmail === null){
								toastRef.current.show({severity:'error', summary: 'Der Kunde konnte nicht gespeichert werden', detail:'Bitte E-Mail ausfüllen', life: 3000})
								return; 
							}
							if(kunde.RePlz === "" || kunde.RePlz === undefined || kunde.RePlz === null){
								toastRef.current.show({severity:'error', summary: 'Der Kunde konnte nicht gespeichert werden', detail:'Bitte PLZ ausfüllen', life: 3000})
								return; 
							}
							if(kunde.ReOrt === "" || kunde.ReOrt === undefined || kunde.ReOrt === null){
								toastRef.current.show({severity:'error', summary: 'Der Kunde konnte nicht gespeichert werden', detail:'Bitte Ort ausfüllen', life: 3000})
								return; 
							}
							toastRef.current.show({severity:'success', summary: 'Erfolgreich', detail:'Kunde gespeichert.', life: 2000});
							setNeuerAngebotKundeDialogVisible(false);
						}}/>
					</div>
				}
			>

			<SelectButton value={kunde?.KundenArt} style={{marginBottom: "20px"}}
				options={KundenArt} optionLabel="KundenArt" optionValue="Value"
				onChange={(e) => {
					let _kunde = {...kunde, KundenArt: e.target.value};
					console.log(e.target.value); 
					setKunde(_kunde);
					}}>
			</SelectButton>
			<AngebotKundeAnzeigen  erstePersonMailValid={erstePersonMailValid} setErstePersonMailValid={setErstePersonMailValid} erstePersonMobilValid={erstePersonMobilValid} setErstePersonMobilValid={setErstePersonMobilValid} kunde={kunde} setKunde={setKunde} labelWidth={labelWidth} labelWidthInputText={labelWidthInputText} setPlzsuche={setPlzsuche} />
		</Dialog>

		<Dialog header={neuerKundenHeader} visible={neuerKundeDialogVisible} style={{width: "20vw"}} 
		footer={
			<div>
				<Button label="Weiter" icon="pi pi-check" 
					onClick={()=>{
						if(neuerKundenTyp === "Privat"){
							createKunde(); 
						}
						else if(neuerKundenTyp === "Firma"){
							createFAKunde(); 
						}
						setNeuerKundeDialogVisible(false);
						}}>
				</Button>
			</div>} 
		onHide={()=>setNeuerKundeDialogVisible(false)}>
				<span style={{fontWeight:"bold"}}>Welcher Kundentyp soll angelegt werden?</span>
				<SelectButton value={neuerKundenTyp} style={{marginTop: "10px"}} options={KundenTyp} onChange={(e) => {
					setNeuerKundenTyp(e.target.value); 
				}}></SelectButton>
		</Dialog>
		
		<Dialog header="Einstellungen" visible={einstellungenVisible} style={{ width: '15vw' }}
				footer={
					<div>
						<Button label="Schließen" icon="pi pi-check" onClick={e => {setEinstellungenVisble(false);}} />
					</div>
				}
				onHide={e => {
					setEinstellungenVisble(false);
					}}>
					<div className="grid">
						{columns?.map((col) => (
							<div className="col-12">
								<Checkbox key={col.field} value={col} checked={selectedColumns.find((item) => item.field === col.field).visible} className="mr-3"
								onChange={e => {
									let _columns = [...columns];
									_columns.forEach(x => {
										x.visible = selectedColumns.find((_x) => _x.field === x.field)?.visible
									});
									let column = _columns.find((item) => item.field === col.field);
									if (column !== undefined && column !== null)
										column.visible = e.checked;
									setSelectedColumns(_columns);
								}}/>
							{col.field}
							</div>
						))}
					</div>
			</Dialog>
	</React.Fragment>	
    );
}