import React, { useRef, useState, useEffect } from 'react';
import { PLZOrtDropdown } from "../PLZOrtDropdown";
import { checkPhoneNumber } from '../checkPhoneNumber';
import { UIDService } from '../../service/UIDService';
import { FirmenbuchService } from '../../service/FirmenbuchService';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Laenderdropdown } from '../Laenderdropdown';
import { Dropdown } from 'primereact/dropdown';

import Kunde from '../Kunde';

export default function AngebotKundeAnzeigen(props) {
	const uidservice = new UIDService();
	const firmenbuchService = new FirmenbuchService();
	
	const erstepersonmobilref = useRef(null);

	const [ErstePersonMobilValid, setErstePersonMobilValid] = useState(false);
	const [erstePersonMailValid, setErstePersonMailValid] = useState(false);
	const [uidValidationError, setUidValidationError] = useState("notchecked");
	const [uidLoading, setUidLoading] = useState(false);
	const [fnValidationError, setFnValidationError] = useState("notchecked");
	const [firmenbuchloading, setFirmenbuchloading] = useState(false);
	const [ErstePersonMobilSelectionStart, setErstePersonMobilSelectionStart] = useState();
	const [selectedVorwahlErstePersonMobil, setSelectedVorwahlErstePersonMobil] = useState("+43");
	const [erstePersonMobil, setErstePersonMobil] = useState("");

	useEffect(() => {
		console.log(props);
		if (props.kunde?.ReLand === undefined || props.kunde?.ReLand === null)
			props.setKunde({...Kunde, ReLand: {name: "AT - Österreich", code: "AT"},
							KundenArt: 0})
	}, [])

	const onSetRePLZOrt = (e) => {
		props?.setKunde({...props?.kunde, RePlz: e.target.value.PLZ, ReOrt: e.target.value.Ort});
	}
	const onSetRePLZ = (e) => {
		props?.setKunde({...props?.kunde, RePlz: e.target.value});
	}
	const onSetOrt = (e) => {
		props?.setKunde({...props?.kunde, ReOrt: e.target.value});
	}

	useEffect(() => {
		let _number = checkPhoneNumber(props?.kunde?.ErstePersonMobil); 
		if (_number.Nummer != undefined){
			props?.setKunde({...props?.kunde, ErstePersonMobil: _number.Nummer});
		}
		props.setErstePersonMobilValid(_number.status);
	}, [props?.kunde?.ErstePersonMobil])

	useEffect(() => {
		if(emailRegex.test(props?.kunde?.ErstePersonEmail)){
			props.setErstePersonMailValid(true);
		}
		else{
			props.setErstePersonMailValid(false); 
		}

	}, [props?.kunde?.ErstePersonEmail])


	useEffect(() => {
		erstepersonmobilref.current.selectionStart = ErstePersonMobilSelectionStart;
		erstepersonmobilref.current.selectionEnd = ErstePersonMobilSelectionStart;
	}, [props?.kunde?.ErstePersonMobil])

	function formatMatch(_match) {
		if (_match === undefined || _match === null)
			return;
		if (_match.length < 1)
			return;
		
		let _return = '';
		let match = new Array();
		_match.forEach(e => {
			if (e !== undefined && e !== null && e.length > 0)
				match.push(e);
		});
		for (let i = 1; i < match.length - 1; i++) {
			_return += match[i] + ' ';
		}
		_return += match[match.length-1];
		_return = _return.substring(_match[1].length + 1, _return.length);
		return _return;
	}

	function formatPhoneNumber(phoneNumberString, selectedVorwahl) {
		if (phoneNumberString === undefined || phoneNumberString === null || phoneNumberString.length === 0)
			return { status: true, Nummer: ''}
		var cleaned = selectedVorwahl + phoneNumberString.replace(/[^0-9+]/g, '');
 
		if (cleaned[0] === '0' && cleaned[1] === '0' && cleaned.length >= 4)
			cleaned = '+' + cleaned.substring(2);

		if (cleaned.length === 0)
			return { status: false, Nummer: cleaned };
		if (cleaned[0] === '+')
		{
			var match = cleaned.match(/^(\+\d{2})(6\d{2})(\d{3})(\d{2})(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d*)$/);
			if (match === undefined || match === null)
			{
				match = cleaned.match(/^(\+\d{2})(\d{4})(\d{3})(\d{2})(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d*)$/);
				if (match === undefined || match === null)
					return { status: false, Nummer: cleaned.substring(selectedVorwahl.length, cleaned.length) };
			}
			return { status: true, Nummer: formatMatch(match)}
		}
		else if (cleaned.length > 2 && cleaned[0] == '0' && cleaned[1] === '0')
		{
			var match = cleaned.match(/^(00\d{2})(6\d{2})(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d*)$/);
			if (match === undefined || match === null)
			{
				match = cleaned.match(/^(00\d{2})(\d{4})(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d{2})?(\d*)$/);
				if (match === undefined || match === null)
					return { status: false, Nummer: formatMatch(match) };
			}
			return { status: true, Nummer: formatMatch(match)}
		}
		else {
			return { status: false, Nummer: formatMatch(match) };
		}
	}
	
	const Laendervorwahlen = ["+43", "+42", "+49"];
	const emailRegex = /\S+@\S+\.\S+/;

	return (
		<div className='grid'>
			<div className='col-12'>
				{(props?.kunde?.KundenArt === 1) &&
				<React.Fragment>
					<div className="field grid">
						<label htmlFor="firmenbezeichnung" className="col-fixed" style={{width: props?.labelWidth}}>Firmenbezeichnung</label>
						<div className="col">
							<InputText value={props?.kunde?.Firmenbezeichnung} style={{width: props.labelWidthInputText}} id="firmenbezeichnung" onChange={e => props?.setKunde({...props?.kunde, Firmenbezeichnung: e.target.value})}
							className={props?.kunde?.Firmenbezeichnung === undefined || props?.kunde?.Firmenbezeichnung === null || props?.kunde?.Firmenbezeichnung === "" ? "p-invalid" : ""}/>
						</div>
					</div>
					<div className="field grid">
						<label htmlFor="uid" className="col-fixed" style={{width: props?.labelWidth}}>UID Nummer</label>
						<div className="col">
							<InputText value={props?.kunde.uid} id="fn" style={{width: props.labelWidthInputText}}
							onChange={e => {
								props?.setKunde({...props?.kunde, uid: e.target.value});
								setUidValidationError(false);
							}}
							className={(uidValidationError === "error" ) ? "p-invalid" : ""}/>
							{(uidValidationError !== "checked") &&
							<Button label="Überprüfen" icon="pi pi-sync"
								loading={uidLoading}
								style={{marginLeft: "10px"}} onClick={() => {
								setUidLoading(true);
								uidservice.checkUID(props.kunde?.uid).then(x => {
									setUidLoading(false);
									if (x.errorCode !== 0)
										setUidValidationError("error");
									else
										setUidValidationError("checked");
								});
							}}/>
							}
							{(uidValidationError === "checked") &&
							<i style={{color: "green", fontSize: "1.5em"}} class="pi pi-check"></i>
							}
						</div>
					</div>
					<div className="field grid">
						<label htmlFor="fn" className="col-fixed" style={{width: props?.labelWidth}}>Firmenbuchnummer</label>
						<div className="col">
							<InputText value={props.kunde.FN} id="fn" style={{width: props.labelWidthInputText}}
							onChange={e => props.setKunde({...props.kunde, FN: e.target.value})}
							className={(fnValidationError === "error" ) ? "p-invalid" : ""} />
							{(fnValidationError !== "checked") &&
							<Button label="Überprüfen" icon="pi pi-sync"
								loading={firmenbuchloading}
								style={{marginLeft: "10px"}} onClick={() => {
								setFirmenbuchloading(true);
								firmenbuchService.checkFirmenbuchnummer(props.kunde.FN).then(x => {
									setFirmenbuchloading(false);
									setFnValidationError("checked");
									props.setKunde({...props.kunde, FN: x.fnr});
								}).catch((e) => {setFirmenbuchloading(false); setFnValidationError("error")});
							}}/>
							}
							{(fnValidationError === "checked") &&
							<i style={{color: "green", fontSize: "1.5em"}} class="pi pi-check"></i>
							}
						</div>
					</div>
				</React.Fragment>
				}
				<div className="field grid">
					<label htmlFor="titel" className="col-fixed" style={{width: props?.labelWidth}}>Titel</label>
					<div className="col">
						<InputText value={props?.kunde?.ErstePersonTitel} id="titel" style={{width: props.labelWidthInputText}} onChange={e => props?.setKunde({...props?.kunde, ErstePersonTitel: e.target.value})}/>
					</div>
				</div>
				<div className="field grid">
					<label htmlFor="vorname" className="col-fixed" style={{width: props?.labelWidth}}>Vorname</label>
					<div className="col">
						<InputText autoFocus value={props?.kunde?.ErstePersonVorname} id="vorname" style={{width: props.labelWidthInputText}} onChange={e => props?.setKunde({...props?.kunde, ErstePersonVorname: e.target.value})}/>
					</div>
				</div>
				<div className="field grid">
					<label htmlFor="nachname" className="col-fixed" style={{width: props?.labelWidth}}>Nachname</label>
					<div className="col">
						<InputText value={props?.kunde?.ErstePersonNachname} id="nachname" style={{width: props.labelWidthInputText}} onChange={e => props?.setKunde({...props?.kunde, ErstePersonNachname: e.target.value})}
						className={props?.kunde?.ErstePersonNachname === undefined || props?.kunde?.ErstePersonNachname === null || props?.kunde?.ErstePersonNachname === "" ? "p-invalid" : ""}/>
					</div>
				</div>
				<div className="field grid">
					<label htmlfor="telefon" className="col-fixed" style={{width: props?.labelWidth}}>Telefon</label>
						<div className="col">
							<div className='flex'>
								<div className='flex-initial flex align-items-center justify-content-center'>
									<Dropdown value={selectedVorwahlErstePersonMobil} style={{marginRight: "10px"}} options={Laendervorwahlen} onChange={e => {
										setSelectedVorwahlErstePersonMobil(e.value)}}></Dropdown>
								</div>
								<div className='flex-initial flex align-items-center justify-content-center'>
									<InputText value={erstePersonMobil} id="telefon" style={{width: "210px"}}
									ref={erstepersonmobilref}
									onChange={e => {
										let _nummer = formatPhoneNumber(e.target.value, selectedVorwahlErstePersonMobil).Nummer;
										if (e.target.selectionStart === e.target.value.length && _nummer.length > (e.target.value.length))
											setErstePersonMobilSelectionStart(e.target.selectionStart + (_nummer.length - e.target.value.length));
										else
											setErstePersonMobilSelectionStart(e.target.selectionStart);
										setErstePersonMobil(e.target.value);
										props?.setKunde({...props?.kunde, ErstePersonMobil: selectedVorwahlErstePersonMobil + " " + _nummer});
									}}
									className={props.erstePersonMobilValid === false || props?.kunde?.ErstePersonMobil === "" || props?.kunde?.ErstePersonMobil === undefined || props?.kunde?.ErstePersonMobil === null ? "p-invalid" : ""}/>
								</div>
							</div>
						</div> 
				</div>
				<div className="field grid">
					<label htmlFor="mail" className="col-fixed" style={{width: props?.labelWidth}}>E-Mail</label>
					<div className="col">
						<InputText value={props?.kunde?.ErstePersonEmail} id="mail" style={{width: props.labelWidthInputText}} onChange={e => props?.setKunde({...props?.kunde, ErstePersonEmail: e.target.value})} className={
						props.erstePersonMailValid === false || props?.kunde.ErstePersonEmail === ""|| props?.kunde.ErstePersonEmail === null || props?.kunde.ErstePersonEmail === undefined ? "p-invalid" : ""}/>
					</div>
				</div>
				<div className="field grid">
					<label htmlFor="adresse" className="col-fixed" style={{width: props?.labelWidth}}>Straße</label>
					<div className="col">
						<InputText value={props?.kunde?.ReAdresse} id="adresse" style={{width: props.labelWidthInputText}} onChange={e => props?.setKunde({...props?.kunde, ReAdresse: e.target.value})}/>
					</div>
				</div>
				<div className="field grid">
					<label htmlFor="plz" className="col-fixed" style={{width: 'calc(' + props?.labelWidth + ' / 4)'}}>PLZ</label>
					<label htmlFor="ort" className="col-fixed" style={{width: 'calc((' + props?.labelWidth + ' / 4) * 3)'}}>Ort</label>
					<div className="col">
						<PLZOrtDropdown plzvalue={props?.kunde?.RePlz} Land={props?.kunde?.ReLand} ortvalue={props?.kunde?.ReOrt} setPLZOrt={onSetRePLZOrt} setPLZ={onSetRePLZ} setOrt={onSetOrt}></PLZOrtDropdown>
					</div>
				</div>
				<div className="field grid">
					<label htmlFor="reland" className="col-fixed" style={{width: props?.labelWidth}}>Land</label>
					<div className="col">
						<Laenderdropdown Land={props?.kunde?.ReLand} onChange={(e) => props?.setKunde({...props?.kunde, ReLand: e.target.value})}></Laenderdropdown>
					</div>
				</div>
			</div>
		</div>
	);
}