import React, { useState, useEffect, useRef, useContext } from 'react';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { LieferantenService } from '../service/LieferantenService';
import { GlobalState } from './GlobalState';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { ExportService } from '../service/ExportService';
import { Toast } from 'primereact/toast'
import { Dropdown } from 'primereact/dropdown';

function Lieferanten() {
	const history = useHistory();
	const dt = useRef(null);
	const toastRef = useRef(null);
	const lieferantenService = new LieferantenService();
	const exportService = new ExportService();

	const [lieferanten, setLieferanten] = useState([]);
	const [selectedLieferantElement, setSelectedLieferantElement] = useState(null);
	const [globalFilter, setGlobalFilter] = useState(null);
	const [filteredLieferanten, setFilteredLieferanten] = useState([]);
	const [globalState, setGlobalState] = useContext(GlobalState);
	const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
	const [selectedLieferant, setSelectedLieferant] = useState({});
	const [otherLieferanten, setOtherLieferanten] = useState([]);
	const [selectedOtherLieferant, setSelectedOtherLieferant] = useState();

	const onNewLieferantClick = () => history.push('/lieferant/neu');

	const onRowClicked = e => {
		if (selectedLieferantElement !== e.originalEvent.target.parentElement) {
			let className = e.originalEvent.target.parentElement.className;
			if (selectedLieferantElement !== undefined && selectedLieferantElement !== null)
				selectedLieferantElement.className = selectedLieferantElement.className.replace(' p-highlight', '');
			setSelectedLieferantElement(e.originalEvent.target.parentElement);
				e.originalEvent.target.parentElement.className = className + " p-highlight";
		}
	}

	const onEditClick = rowData => history.push("/lieferant/bearbeiten/"+rowData.ID);

	const onDeleteClick = rowData => {
		setSelectedLieferant(rowData);
		setOtherLieferanten(lieferanten.filter(l => l.ID !== rowData.ID));
		setSelectedOtherLieferant()
		setDeleteDialogVisible(true);
	};

	const onDeleteConfirmed = async () => {
		setDeleteDialogVisible(false);
		await lieferantenService.delete(selectedLieferant.ID, selectedOtherLieferant.ID);
		lieferantenService.getAll().then(data => setLieferanten(data));
	};

	useEffect(() => {
		setGlobalState({...globalState, page: 'Lieferanten'});
		lieferantenService.getAll().then(data => setLieferanten(data));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const renderHeader = () => {
		return (
			<div>
				<div className="grid">
					<InputText className="col" type="search" onInput={e => setGlobalFilter(e.target.value)} placeholder="Globale Suche" />
				</div>
			</div>
		);
	};

	const actionBodyTemplate = rowData => {
		return (
			<React.Fragment>
				<Button tooltipOptions={{position: 'bottom'}} tooltip="Lieferant bearbeiten" type="button" icon="pi pi-pencil" className="p-button-secondary p-mr-1" onClick={() => onEditClick(rowData)}></Button>
				<Button tooltipOptions={{position: 'bottom'}} tooltip="Lieferant löschen" type="button" icon="pi pi-trash" className="p-button-danger p-mr-1" onClick={() => onDeleteClick(rowData)}></Button>
			</React.Fragment>
		);
	};

	const header = renderHeader();

	return (
		<React.Fragment>
			<Toast ref={toastRef}/>
			<div className="grid">
				<div className="col-12">
					<Button label="neuer Lieferant" icon="pi pi-plus-circle" onClick={onNewLieferantClick} />
				</div>
				<div className="col-12">
					<DataTable ref={dt} value={lieferanten} onValueChange={filteredData => setFilteredLieferanten(filteredData)} header={header} responsive className="p-datatable-produkte p-datatable-striped" dataKey="id" rowHover globalFilter={globalFilter} paginator rows={20} emptyMessage="keine Lieferanten gefunden" currentPageReportTemplate='({first} von {totalRecords})' paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[20,30,40]} onRowClick={onRowClicked} scrollable scrollHeight="60vh">
						<Column field="ID" sortable filter header="ID" bodyStyle={{display: "none"}} headerStyle={{display:"none"}} filterHeaderStyle={{display:"none"}}/>
						<Column field="Name" filter filterPlaceholder="Name eingeben" sortable header="Name" filterMatchMode="contains"/>
						<Column field="EMail" filter filterPlaceholder="E-Mail eingeben" sortable header="E-Mail" filterMatchMode="contains"/>
						<Column field="TelNr" filter filterPlaceholder="Telefonnummer eingeben" sortable header="Telefonnummer" filterMatchMode="contains"/>
						<Column field="Handy1" filter filterPlaceholder="Handynummer eingeben" sortable header="Handynummer" filterMatchMode="contains"/>
						<Column field="Adresse" filter filterPlaceholder="Adresse wählen" sortable header="Adresse" filterMatchMode="contains"/>
						<Column field="Ort" filter filterPlaceholder="Ort wählen" sortable header="Ort" filterMatchMode="contains"/>
						<Column body={actionBodyTemplate} headerStyle={{width: '10em', textAlign: 'center'}} bodyStyle={{textAlign: 'center', overflow: 'visible'}}/>
					</DataTable>
				</div>
			</div>
			<Dialog visible={deleteDialogVisible} onHide={() => setDeleteDialogVisible(false)} header={'Lieferant "' + selectedLieferant.Name + '" Löschen'} footer={<Button label="Bestätigen" icon="pi pi-trash" disabled={selectedOtherLieferant === undefined || selectedOtherLieferant === null} onClick={onDeleteConfirmed}/>}>
				Bitte geben Sie an an welchen anderen Lieferant<br/>
				die Produkte übertragen werden sollen.<br/>
				<br/>
				<Dropdown value={selectedOtherLieferant} options={otherLieferanten} optionLabel="Name" placeholder="Lieferant" onChange={e => setSelectedOtherLieferant(e.target.value)}/>
			</Dialog>
		</React.Fragment>
	);
}
export default Lieferanten;