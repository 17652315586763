import React, { useRef, useState, useEffect, useContext } from 'react';
import CONFIG from 'config';

export default function MitarbeiterBildTemplate(props) {
	console.log(props);
	return (
        <React.Fragment>
            <img
                onError={(e) => e.target.src = '/assets/placeholder.png'}
                src={CONFIG.ServerUrl + "/Mitarbeiter/" + props?.Verkäufer + "/Bild"}
                width={32} style={{ verticalAlign: 'middle', borderRadius: "50%" }} />
            <span className="ml-2 image-text">{props.Verkäufer?.Name}</span>
        </React.Fragment>
    );
}