import React, {useContext, useEffect, useState} from 'react';
import { GlobalState } from "./components/GlobalState";
import classNames from 'classnames';
import {AppTopbar} from './AppTopbar';
import AppMenu from './AppMenu';
import {AppProfile} from './AppProfile';
import {Route, Switch, useHistory} from 'react-router-dom';
import Dashboard from './components/Dashboard';
import 'primereact/resources/themes/nova/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './layout/layout.scss';
import './App.scss';
import Login from './components/Login';
import { AuthService } from './service/AuthService';
import CONFIG from 'config';
import { unregister } from './serviceWorker';
import Kunden from './components/Kunden';
import Kunde from './components/Kunde';
import Mitarbeiter from './components/Mitarbeiter';
import MitarbeiterList from './components/MitarbeiterList';
import Angebote from './components/Angebote';
import Angebot from './components/Angebot/Angebot';
import Verkäufe from './components/Verkäufe';
import Verkauf from './components/Verkauf';
import Services from './components/Services';
import Lieferanten from './components/Lieferanten';
import Lieferant from './components/Lieferant';
import Kassabuch from './components/Kassabuch';
import Wareneingangsbuch from './components/Wareneingangsbuch';
import Anzahlungen from './components/Anzahlungen';
import Kontenplan from './components/Kontenplan';
import Service from './components/Service';
import Warenausgangsbuch from './components/Warenausgangsbuch';
import Einstellungen from './components/Einstellungen'
import Aufträge from './components/Aufträge';

function App(_) {
	const [globalState, setGlobalState] = useContext(GlobalState);
	const [layoutMode, setLayoutMode] = useState('static');
	const [layoutColorMode, setLayoutColorMode] = useState('dark');
	const [staticMenuInactive, setStaticMenuInactive] = useState(false);
	const [overlayMenuActive, setOverlayMenuActive] = useState(false);
	const [mobileMenuActive, setMobileMenuActive] = useState(false);
	const [navigationPfad, setNavigationPfad] = useState('Home');
	const [repaint, setRepaint] = useState();
	const [roles, setRoles] = useState([]);
	const [menu, setMenu] = useState([]);

	const authService = new AuthService();

	const history = useHistory();
	
	useEffect(() => {
		if (mobileMenuActive)
			addClass(document.body, 'body-overflow-hidden');
		else
			removeClass(document.body, 'body-overflow-hidden');

		unregister();
	}, []);

	const onToggleMenu = (event) => {
		if (isDesktop()) {
			if (layoutMode === 'overlay') {
				setOverlayMenuActive(!overlayMenuActive);
			}
			else if (layoutMode === 'static') {
				setStaticMenuInactive(!staticMenuInactive);
			}
		}
		else {
			setMobileMenuActive(!mobileMenuActive);
		}
	   
		event.preventDefault();
	}

	const onMenuItemClick = (event) => {
		if(!event.item.items) {
			setOverlayMenuActive(false);
			setMobileMenuActive(false);
		}
	}

	function onEnter() {
		setNavigationPfad();
	}

	useEffect(() => {
		setMenu(createMenu());
		setRepaint({});
	}, [roles]);


	function createMenu() {
		return ([
			{ label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' },
			{ label: 'Angebote', icon: 'pi pi-fw pi-tag', to: '/angebote' },
			{ label: 'Verkäufe', icon: 'pi pi-fw pi-euro', to: '/verkäufe' },
			{ label: 'Aufträge', icon: 'pi pi-fw pi-book', to: '/aufträge', items: [
				{ label: 'Auftragsbestätigungen', icon: 'pi pi-fw pi-book', to: '/auftragsbestätigungen' },
			]},
			{ label: 'Stammdaten', icon: 'pi pi-fw pi-book', to: '/stammdaten', items: [
				{ label: 'Mitarbeiter', icon: 'pi pi-fw pi-users', to: "/mitarbeiter" },
				{ label: 'Kunden', icon: 'pi pi-fw pi-users', to: '/kunden' },
				{ label: 'Angebotskunden', icon: 'pi pi-fw pi-users', to: '/angebotskunden' },
				{ label: 'Lieferanten', icon: 'pi pi-fw pi-users', to: '/lieferanten'},
				{ label: 'Kontenplan', icon: 'pi pi-fw pi-book', to: '/kontenplan'},
			]},
			{ label: 'Buchhaltung', icon: 'pi pi-fw pi-book', to: '/buchhaltung', items: [
				{ label: 'Kassabuch', icon: 'pi pi-fw pi-book', to: '/kassabuch' },
				{ label: 'Wareneingan', icon: 'pi pi-fw pi-book', to: '/eingangsbuch' },
				{ label: 'Ausgangsbuch', icon: 'pi pi-fw pi-book', to: '/ausgangsbuch' },
				{ label: 'Anzahlungen', icon: 'pi pi-fw pi-book', to: '/anzahlungen'}
			]},
			{ label: 'Rechnungen', icon: 'pi pi-fw pi-book', items: [
				{ label: 'Mahnungen', icon: 'pi pi-fw pi-book', to: '/mahnungen' },
				{ label: 'OP Liste', icon: 'pi pi-fw pi-book', to: '/opliste'},
				{ label: 'Zubehörrechnung', icon: 'pi pi-fw pi-book', to: "/zubehörrechnung"}
			]},
			{ label: 'Reklamationen', icon: 'pi pi-fw pi-book', to: '/service' },
			{ label: 'Montage', icon: 'pi pi-fw pi-book', to: '/montage'},
			{ label: 'Einstellungen', icon: 'pi pi-fw pi-cog', to: '/einstellungen' }
		]);
	}


	function addClass(element, className) {
		if (element.classList)
			element.classList.add(className);
		else
			element.className += ' ' + className;
	}

	function removeClass(element, className) {
		if (element.classList)
			element.classList.remove(className);
		else
			element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
	}

	function isDesktop() {
		return window.innerWidth > 1024;
	}

	const logo = layoutColorMode === 'dark' ? 'assets/layout/images/logo-white.svg': 'assets/layout/images/logo.svg';

	const wrapperClass = classNames('layout-wrapper', {
		'layout-overlay': layoutMode === 'overlay',
		'layout-static': layoutMode === 'static',
		'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
		'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
		'layout-mobile-sidebar-active': mobileMenuActive
	});

	const sidebarClassName = classNames("layout-sidebar", {
		'layout-sidebar-dark': layoutColorMode === 'dark',
		'layout-sidebar-light': layoutColorMode === 'light'
	});

	return (
		<React.Fragment>
			<div className={wrapperClass}>
				{(!authService.isLogedIn()) &&
				<div class="p-p-5">
					<Switch>
						<Route path="/">
							<Login onLogin={() => setRepaint({})}/>
						</Route>
					</Switch>
				</div>
				}
				{(authService.isLogedIn()) &&
					<React.Fragment>
						<AppTopbar onToggleMenu={onToggleMenu} reload={() => setRepaint({})}/>

						<div className={sidebarClassName}>
							{CONFIG.LogoSidebar !== undefined && CONFIG.LogoSidebar !== null && <img className="sidebarTopLogo" alt="Logo" src={CONFIG.LogoSidebar}/>}
							<AppProfile />
							<AppMenu model={menu} onMenuItemClick={onMenuItemClick} />
							<div className="sidebarFiller"></div>
							<div className="sidebarBottom">
								<img className="sidebarBottomLogo" alt="Logo" src="/assets/logo3.svg"/>
								<div className="sidebarHotline p-component" style={{fontSize: '1em'}}>
									Bei Fragen wenden Sie sich bitte an die mysoft - Hotline unter <a href="tel:+43255485421100">+43 2554/85 421-100</a>
								</div>
							</div>
						</div>
						<div className="layout-main">
							<Switch>
								<Route path="/kunde/neu">
									<Kunde action="neu"/>
								</Route>
								<Route path="/kunde/FAneu">
									<Kunde action="neu" typ="Fa"/>
								</Route>
								<Route path="/kunde/bearbeiten/:id">
									<Kunde action="bearbeiten"/>
								</Route>
								<Route path="/kunden">
									<Kunden art="Stammkunden"/>
								</Route>
								<Route path="/angebotkunde/bearbeiten/:id">
									<Kunde action="bearbeiten" art="Angebotskunde"/>
								</Route>
								<Route path="/angebotskunden">
									<Kunden art="Angebotskunden"/>
								</Route>

								<Route path="/mitarbeiter/neu">
									<Mitarbeiter action="neu"/>
								</Route>
								<Route path="/mitarbeiter/bearbeiten/:mitarbeiterNr">
									<Mitarbeiter action="bearbeiten"/>
								</Route>
								<Route path="/mitarbeiter">
									<MitarbeiterList />
								</Route>

								<Route path="/angebot/neu">
									<Angebot action="neu" />
								</Route>
								<Route path="/angebot/bearbeiten/:id">
									<Angebot action="bearbeiten"/>
								</Route>
								<Route path="/angebote/Status/:Status">
									<Angebote/>
								</Route>
								<Route path="/angebote/:Kundennummer">
									<Angebote/>
								</Route>
								<Route path="/angebote">
									<Angebote />
								</Route>

								<Route path="/verkauf/neu/:id">
									<Verkauf action="neu" />
								</Route>
								<Route path="/verkauf/bearbeiten/:id">
									<Verkauf action="bearbeiten"/>
								</Route>
								<Route path="/verkauf/neu">
									<Verkauf action="neu" />
								</Route>
								<Route path="/verkäufe/:Kundennummer" children={<Verkäufe/>}></Route>
								<Route path="/verkäufe/:id" children={<Verkäufe/>}/>
								<Route path="/verkäufe" children={<Verkäufe/>}/>
								<Route path="/aufträge" children={<Aufträge/>}/>
								<Route path="/service/neu" children={<Service action="neu"/>}/>
								<Route path="/service" children={<Services/>} />
								<Route path="/service/bearbeiten/:id" children={<Service action="bearbeiten" />}/>
								<Route path="/lieferanten" children={<Lieferanten />}/>
								<Route path="/lieferant/neu" children={<Lieferant action="neu"/>}/>
								<Route path="/lieferant/bearbeiten/:id" children={<Lieferant action="bearbeiten"/>}/>
								<Route path="/kassabuch" children={<Kassabuch />}/>
								<Route path="/wareneingangsbuch" children={<Wareneingangsbuch />}/>
								<Route path="/warenausgangsbuch" children={<Warenausgangsbuch />} />
								<Route path="/anzahlungen/" children={<Anzahlungen/>} />
								<Route path="/kontenplan/" children={<Kontenplan/>}/>
								<Route path="/einstellungen" children={<Einstellungen />} />
								<Route path="/" children={<Dashboard />} />
							</Switch>
						</div>
					</React.Fragment>
				}
				<div className="layout-mask"></div>
			</div>
		</React.Fragment>
	);
}

export default App;
