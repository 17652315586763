import React from 'react';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';

export default function BaufirmaColumn(props) {
	
	const onBaufirmaChange = (e) => {
		props.setSelectedBaufirma(e.target.value);
		props.datatableref.current.filter(e.target.value, "Baufirma", "in");
	}

	return (
		<Column header="Baufirma" field="Baufirma" style={{width: "200px"}} filter sortable filterMatchMode="contains"
						filterElement={
							<MultiSelect className="column-filter" value={props.selectedBaufirma} options={props.Baufirma} optionLabel="Baufirma" optionValue="Baufirma"
								onChange={onBaufirmaChange}
								placeholder="Alle" filter/>
							}
							showFilterMenuOptions={false} showFilterOperator={false} showFilterMatchModes={false}
							filterClear={<div></div>} filterApply={<div></div>} showFilterMenu={false}
							/>
	)
}