import axios from 'axios';
import { GetAxiosConfig } from './AuthService';
import CONFIG from 'config';
import { GetToken } from './AuthService';
import { fetchSimple } from "../helper/fetchSimple";

export class MitarbeiterService {
	async getAll() {
		return fetchSimple(CONFIG.ServerUrl + "/Mitarbeiter", await GetToken(CONFIG.ServerUrl)).then(res => res.json());
	}

	async create(mitarbeiter) {
		return fetchSimple(CONFIG.ServerUrl + "/Mitarbeiter/Create", await GetToken(CONFIG.ServerUrl), mitarbeiter).then(res => res);
	}

	async getMitarbeiter(id) {
		return fetchSimple(CONFIG.ServerUrl + "/Mitarbeiter/" + id, await GetToken(CONFIG.ServerUrl)).then(res => res.json());
	}

	async getMitarbeiterMitBenutzername(benutzername) {
		return fetchSimple(CONFIG.ServerUrl + "/Mitarbeiter/Benutzername/" + benutzername, await GetToken(CONFIG.ServerUrl)).then(res => res.json());
	}

	async getVerkaeufer() {
		return fetchSimple(CONFIG.ServerUrl + "/Mitarbeiter/Verkaeufer", await GetToken(CONFIG.ServerUrl)).then(res => res.json());
	}

	async mitarbeiterLoeschen(id) {
		return axios.get(CONFIG.ServerUrl + '/Mitarbeiter/Loeschen/' + id, await GetAxiosConfig()).then(res => res.data);
	}

	async mitarbeiterUpdate(mitarbeiter) {
		return fetchSimple(CONFIG.ServerUrl + "/Mitarbeiter/Update", await GetToken(CONFIG.ServerUrl), mitarbeiter).then(res => res);
	}

	async updateEinstellungen(mitarbeiter) {
		return fetchSimple(CONFIG.ServerUrl + "/Mitarbeiter/Update/Einstellungen", await GetToken(CONFIG.ServerUrl), mitarbeiter).then(res => res);
	}

	async getImage(id) {
		return fetchSimple(CONFIG.ServerUrl + "/Mitarbeiter/" + id + "/Bild", await GetToken(CONFIG.ServerUrl)).then(res => res.blob());
	}
}