import axios from 'axios';
import { GetAxiosConfig } from './AuthService';
import CONFIG from 'config';

export class VerkäufeService {
    async getAll() {
        return axios.get(CONFIG.ServerUrl + '/Verkäufe/', await GetAxiosConfig()).then(res => res.data);
    }
    
    async get(id) {
        return axios.get(CONFIG.ServerUrl + '/Verkauf/' + id, await GetAxiosConfig()).then(res => res.data);
    }

    async neu(verkauf) {
        return axios.post(CONFIG.ServerUrl + '/Verkauf/create', verkauf, await GetAxiosConfig());
    }

    async update(verkauf) {
        return axios.post(CONFIG.ServerUrl + '/Verkauf/update', verkauf, await GetAxiosConfig());
    }
    async delete(id) {
        return axios.get(CONFIG.ServerUrl + '/Verkauf/Delete/' + id, await GetAxiosConfig());
    }
}